/*
-----------------------------------------------------------------------------
Table of Contents
-----------------------------------------------------------------------------
 01. Colors
   01.01 Base colors
   01.02 Shades of grey
   01.03 Goals
   01.04 Other
 02. Typography
   02.01 Font family
   02.02 Font size
   02.03 Font weight
 03. Dimensions
 04. Media queries
-----------------------------------------------------------------------------
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(../node_modules/bootstrap-icons/font/bootstrap-icons.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #74A3E1;
  --secondary: #FFFFFF;
  --success: #00AB84;
  --info: #74A3E1;
  --warning: #E1CD00;
  --danger: #E74C3C;
  --light: #FFFFFF;
  --dark: #000000;
  --ccm-red: #E74C3C;
  --ccm-gray: #C4C4C4;
  --ccm-oslo: #8D89A5;
  --ccm-navy: #2980B9;
  --ccm-blue: #74A3E1;
  --ccm-green: #00AB84;
  --ccm-yellow: #E1CD00;
  --ccm-orange: #E67E22;
  --ccm-bismark: #4A6784;
  --ccm-bali-hai: #8095B1;
  --ccm-bluewood: #2C3E50;
  --ccm-lipstick: #C6017D;
  --ccm-light-gray: #F2F2F2;
  --ccm-bright-red: #FF0000;
  --ccm-inactive: #929292;
  --ccm-boulder: #767676;
  --ccm-darker-yellow: #BBAA03;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #74A3E1;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #3579d3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8e5f7; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b7cfef; }

.table-hover .table-primary:hover {
  background-color: #c3d7f3; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c3d7f3; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e7dd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ad3bf; }

.table-hover .table-success:hover {
  background-color: #a5e1d4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a5e1d4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d8e5f7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b7cfef; }

.table-hover .table-info:hover {
  background-color: #c3d7f3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c3d7f3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7f1b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #efe57a; }

.table-hover .table-warning:hover {
  background-color: #f4eca1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f4eca1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8cdc8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f3a29a; }

.table-hover .table-danger:hover {
  background-color: #f5b8b1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5b8b1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8b8b8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-dark:hover {
  background-color: #ababab; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #ababab; }

.table-ccm-red,
.table-ccm-red > th,
.table-ccm-red > td {
  background-color: #f8cdc8; }

.table-ccm-red th,
.table-ccm-red td,
.table-ccm-red thead th,
.table-ccm-red tbody + tbody {
  border-color: #f3a29a; }

.table-hover .table-ccm-red:hover {
  background-color: #f5b8b1; }
  .table-hover .table-ccm-red:hover > td,
  .table-hover .table-ccm-red:hover > th {
    background-color: #f5b8b1; }

.table-ccm-gray,
.table-ccm-gray > th,
.table-ccm-gray > td {
  background-color: #eeeeee; }

.table-ccm-gray th,
.table-ccm-gray td,
.table-ccm-gray thead th,
.table-ccm-gray tbody + tbody {
  border-color: #e0e0e0; }

.table-hover .table-ccm-gray:hover {
  background-color: #e1e1e1; }
  .table-hover .table-ccm-gray:hover > td,
  .table-hover .table-ccm-gray:hover > th {
    background-color: #e1e1e1; }

.table-ccm-oslo,
.table-ccm-oslo > th,
.table-ccm-oslo > td {
  background-color: #dfdee6; }

.table-ccm-oslo th,
.table-ccm-oslo td,
.table-ccm-oslo thead th,
.table-ccm-oslo tbody + tbody {
  border-color: #c4c2d0; }

.table-hover .table-ccm-oslo:hover {
  background-color: #d1cfdb; }
  .table-hover .table-ccm-oslo:hover > td,
  .table-hover .table-ccm-oslo:hover > th {
    background-color: #d1cfdb; }

.table-ccm-navy,
.table-ccm-navy > th,
.table-ccm-navy > td {
  background-color: #c3dbeb; }

.table-ccm-navy th,
.table-ccm-navy td,
.table-ccm-navy thead th,
.table-ccm-navy tbody + tbody {
  border-color: #90bddb; }

.table-hover .table-ccm-navy:hover {
  background-color: #b0d0e5; }
  .table-hover .table-ccm-navy:hover > td,
  .table-hover .table-ccm-navy:hover > th {
    background-color: #b0d0e5; }

.table-ccm-blue,
.table-ccm-blue > th,
.table-ccm-blue > td {
  background-color: #d8e5f7; }

.table-ccm-blue th,
.table-ccm-blue td,
.table-ccm-blue thead th,
.table-ccm-blue tbody + tbody {
  border-color: #b7cfef; }

.table-hover .table-ccm-blue:hover {
  background-color: #c3d7f3; }
  .table-hover .table-ccm-blue:hover > td,
  .table-hover .table-ccm-blue:hover > th {
    background-color: #c3d7f3; }

.table-ccm-green,
.table-ccm-green > th,
.table-ccm-green > td {
  background-color: #b8e7dd; }

.table-ccm-green th,
.table-ccm-green td,
.table-ccm-green thead th,
.table-ccm-green tbody + tbody {
  border-color: #7ad3bf; }

.table-hover .table-ccm-green:hover {
  background-color: #a5e1d4; }
  .table-hover .table-ccm-green:hover > td,
  .table-hover .table-ccm-green:hover > th {
    background-color: #a5e1d4; }

.table-ccm-yellow,
.table-ccm-yellow > th,
.table-ccm-yellow > td {
  background-color: #f7f1b8; }

.table-ccm-yellow th,
.table-ccm-yellow td,
.table-ccm-yellow thead th,
.table-ccm-yellow tbody + tbody {
  border-color: #efe57a; }

.table-hover .table-ccm-yellow:hover {
  background-color: #f4eca1; }
  .table-hover .table-ccm-yellow:hover > td,
  .table-hover .table-ccm-yellow:hover > th {
    background-color: #f4eca1; }

.table-ccm-orange,
.table-ccm-orange > th,
.table-ccm-orange > td {
  background-color: #f8dbc1; }

.table-ccm-orange th,
.table-ccm-orange td,
.table-ccm-orange thead th,
.table-ccm-orange tbody + tbody {
  border-color: #f2bc8c; }

.table-hover .table-ccm-orange:hover {
  background-color: #f5ceaa; }
  .table-hover .table-ccm-orange:hover > td,
  .table-hover .table-ccm-orange:hover > th {
    background-color: #f5ceaa; }

.table-ccm-bismark,
.table-ccm-bismark > th,
.table-ccm-bismark > td {
  background-color: #ccd4dd; }

.table-ccm-bismark th,
.table-ccm-bismark td,
.table-ccm-bismark thead th,
.table-ccm-bismark tbody + tbody {
  border-color: #a1b0bf; }

.table-hover .table-ccm-bismark:hover {
  background-color: #bdc7d3; }
  .table-hover .table-ccm-bismark:hover > td,
  .table-hover .table-ccm-bismark:hover > th {
    background-color: #bdc7d3; }

.table-ccm-bali-hai,
.table-ccm-bali-hai > th,
.table-ccm-bali-hai > td {
  background-color: #dbe1e9; }

.table-ccm-bali-hai th,
.table-ccm-bali-hai td,
.table-ccm-bali-hai thead th,
.table-ccm-bali-hai tbody + tbody {
  border-color: #bdc8d6; }

.table-hover .table-ccm-bali-hai:hover {
  background-color: #cbd4df; }
  .table-hover .table-ccm-bali-hai:hover > td,
  .table-hover .table-ccm-bali-hai:hover > th {
    background-color: #cbd4df; }

.table-ccm-bluewood,
.table-ccm-bluewood > th,
.table-ccm-bluewood > td {
  background-color: #c4c9ce; }

.table-ccm-bluewood th,
.table-ccm-bluewood td,
.table-ccm-bluewood thead th,
.table-ccm-bluewood tbody + tbody {
  border-color: #919ba4; }

.table-hover .table-ccm-bluewood:hover {
  background-color: #b6bcc2; }
  .table-hover .table-ccm-bluewood:hover > td,
  .table-hover .table-ccm-bluewood:hover > th {
    background-color: #b6bcc2; }

.table-ccm-lipstick,
.table-ccm-lipstick > th,
.table-ccm-lipstick > td {
  background-color: #efb8db; }

.table-ccm-lipstick th,
.table-ccm-lipstick td,
.table-ccm-lipstick thead th,
.table-ccm-lipstick tbody + tbody {
  border-color: #e17bbb; }

.table-hover .table-ccm-lipstick:hover {
  background-color: #eaa3d0; }
  .table-hover .table-ccm-lipstick:hover > td,
  .table-hover .table-ccm-lipstick:hover > th {
    background-color: #eaa3d0; }

.table-ccm-light-gray,
.table-ccm-light-gray > th,
.table-ccm-light-gray > td {
  background-color: #fbfbfb; }

.table-ccm-light-gray th,
.table-ccm-light-gray td,
.table-ccm-light-gray thead th,
.table-ccm-light-gray tbody + tbody {
  border-color: #f8f8f8; }

.table-hover .table-ccm-light-gray:hover {
  background-color: #eeeeee; }
  .table-hover .table-ccm-light-gray:hover > td,
  .table-hover .table-ccm-light-gray:hover > th {
    background-color: #eeeeee; }

.table-ccm-bright-red,
.table-ccm-bright-red > th,
.table-ccm-bright-red > td {
  background-color: #ffb8b8; }

.table-ccm-bright-red th,
.table-ccm-bright-red td,
.table-ccm-bright-red thead th,
.table-ccm-bright-red tbody + tbody {
  border-color: #ff7a7a; }

.table-hover .table-ccm-bright-red:hover {
  background-color: #ff9f9f; }
  .table-hover .table-ccm-bright-red:hover > td,
  .table-hover .table-ccm-bright-red:hover > th {
    background-color: #ff9f9f; }

.table-ccm-inactive,
.table-ccm-inactive > th,
.table-ccm-inactive > td {
  background-color: #e0e0e0; }

.table-ccm-inactive th,
.table-ccm-inactive td,
.table-ccm-inactive thead th,
.table-ccm-inactive tbody + tbody {
  border-color: #c6c6c6; }

.table-hover .table-ccm-inactive:hover {
  background-color: lightgray; }
  .table-hover .table-ccm-inactive:hover > td,
  .table-hover .table-ccm-inactive:hover > th {
    background-color: lightgray; }

.table-ccm-boulder,
.table-ccm-boulder > th,
.table-ccm-boulder > td {
  background-color: #d9d9d9; }

.table-ccm-boulder th,
.table-ccm-boulder td,
.table-ccm-boulder thead th,
.table-ccm-boulder tbody + tbody {
  border-color: #b8b8b8; }

.table-hover .table-ccm-boulder:hover {
  background-color: #cccccc; }
  .table-hover .table-ccm-boulder:hover > td,
  .table-hover .table-ccm-boulder:hover > th {
    background-color: #cccccc; }

.table-ccm-darker-yellow,
.table-ccm-darker-yellow > th,
.table-ccm-darker-yellow > td {
  background-color: #ece7b8; }

.table-ccm-darker-yellow th,
.table-ccm-darker-yellow td,
.table-ccm-darker-yellow thead th,
.table-ccm-darker-yellow tbody + tbody {
  border-color: #dcd37c; }

.table-hover .table-ccm-darker-yellow:hover {
  background-color: #e7e0a4; }
  .table-hover .table-ccm-darker-yellow:hover > td,
  .table-hover .table-ccm-darker-yellow:hover > th {
    background-color: #e7e0a4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #dde8f8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00AB84; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 171, 132, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00AB84;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300AB84' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00AB84;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00AB84;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300AB84' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00AB84;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00AB84; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00AB84; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00AB84; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00deab;
  background-color: #00deab; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00AB84; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00AB84; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00AB84;
  box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E74C3C; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(231, 76, 60, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E74C3C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E74C3C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E74C3C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E74C3C;
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E74C3C;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E74C3C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E74C3C' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E74C3C;
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E74C3C; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E74C3C; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E74C3C; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ed7669;
  background-color: #ed7669; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E74C3C; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E74C3C; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E74C3C;
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #74A3E1;
  border-color: #74A3E1; }
  .btn-primary:hover {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8;
    box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4a87d8;
    border-color: #4080d6; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-success {
  color: #fff;
  background-color: #00AB84;
  border-color: #00AB84; }
  .btn-success:hover {
    color: #fff;
    background-color: #008566;
    border-color: #00785d; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #008566;
    border-color: #00785d;
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 150, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00785d;
    border-color: #006b53; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 184, 150, 0.5); }

.btn-info {
  color: #212529;
  background-color: #74A3E1;
  border-color: #74A3E1; }
  .btn-info:hover {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8;
    box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #4a87d8;
    border-color: #4080d6; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #E1CD00;
  border-color: #E1CD00; }
  .btn-warning:hover {
    color: #212529;
    background-color: #bbaa00;
    border-color: #ae9f00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #bbaa00;
    border-color: #ae9f00;
    box-shadow: 0 0 0 0.2rem rgba(196, 180, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ae9f00;
    border-color: #a19300; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 180, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C; }
  .btn-danger:hover {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a;
    box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d62c1a;
    border-color: #ca2a19; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5); }

.btn-light {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }
  .btn-dark:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-ccm-red {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C; }
  .btn-ccm-red:hover {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a; }
  .btn-ccm-red:focus, .btn-ccm-red.focus {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a;
    box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5); }
  .btn-ccm-red.disabled, .btn-ccm-red:disabled {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
  .btn-ccm-red:not(:disabled):not(.disabled):active, .btn-ccm-red:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-red.dropdown-toggle {
    color: #fff;
    background-color: #d62c1a;
    border-color: #ca2a19; }
    .btn-ccm-red:not(:disabled):not(.disabled):active:focus, .btn-ccm-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5); }

.btn-ccm-gray {
  color: #212529;
  background-color: #C4C4C4;
  border-color: #C4C4C4; }
  .btn-ccm-gray:hover {
    color: #212529;
    background-color: #b1b1b1;
    border-color: #ababab; }
  .btn-ccm-gray:focus, .btn-ccm-gray.focus {
    color: #212529;
    background-color: #b1b1b1;
    border-color: #ababab;
    box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5); }
  .btn-ccm-gray.disabled, .btn-ccm-gray:disabled {
    color: #212529;
    background-color: #C4C4C4;
    border-color: #C4C4C4; }
  .btn-ccm-gray:not(:disabled):not(.disabled):active, .btn-ccm-gray:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-gray.dropdown-toggle {
    color: #212529;
    background-color: #ababab;
    border-color: #a4a4a4; }
    .btn-ccm-gray:not(:disabled):not(.disabled):active:focus, .btn-ccm-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5); }

.btn-ccm-oslo {
  color: #fff;
  background-color: #8D89A5;
  border-color: #8D89A5; }
  .btn-ccm-oslo:hover {
    color: #fff;
    background-color: #787394;
    border-color: #716d8e; }
  .btn-ccm-oslo:focus, .btn-ccm-oslo.focus {
    color: #fff;
    background-color: #787394;
    border-color: #716d8e;
    box-shadow: 0 0 0 0.2rem rgba(158, 155, 179, 0.5); }
  .btn-ccm-oslo.disabled, .btn-ccm-oslo:disabled {
    color: #fff;
    background-color: #8D89A5;
    border-color: #8D89A5; }
  .btn-ccm-oslo:not(:disabled):not(.disabled):active, .btn-ccm-oslo:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-oslo.dropdown-toggle {
    color: #fff;
    background-color: #716d8e;
    border-color: #6c6787; }
    .btn-ccm-oslo:not(:disabled):not(.disabled):active:focus, .btn-ccm-oslo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-oslo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(158, 155, 179, 0.5); }

.btn-ccm-navy {
  color: #fff;
  background-color: #2980B9;
  border-color: #2980B9; }
  .btn-ccm-navy:hover {
    color: #fff;
    background-color: #226a9a;
    border-color: #20638f; }
  .btn-ccm-navy:focus, .btn-ccm-navy.focus {
    color: #fff;
    background-color: #226a9a;
    border-color: #20638f;
    box-shadow: 0 0 0 0.2rem rgba(73, 147, 196, 0.5); }
  .btn-ccm-navy.disabled, .btn-ccm-navy:disabled {
    color: #fff;
    background-color: #2980B9;
    border-color: #2980B9; }
  .btn-ccm-navy:not(:disabled):not(.disabled):active, .btn-ccm-navy:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-navy.dropdown-toggle {
    color: #fff;
    background-color: #20638f;
    border-color: #1d5c85; }
    .btn-ccm-navy:not(:disabled):not(.disabled):active:focus, .btn-ccm-navy:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-navy.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 147, 196, 0.5); }

.btn-ccm-blue {
  color: #212529;
  background-color: #74A3E1;
  border-color: #74A3E1; }
  .btn-ccm-blue:hover {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8; }
  .btn-ccm-blue:focus, .btn-ccm-blue.focus {
    color: #fff;
    background-color: #558eda;
    border-color: #4a87d8;
    box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }
  .btn-ccm-blue.disabled, .btn-ccm-blue:disabled {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-ccm-blue:not(:disabled):not(.disabled):active, .btn-ccm-blue:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-blue.dropdown-toggle {
    color: #fff;
    background-color: #4a87d8;
    border-color: #4080d6; }
    .btn-ccm-blue:not(:disabled):not(.disabled):active:focus, .btn-ccm-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 144, 197, 0.5); }

.btn-ccm-green {
  color: #fff;
  background-color: #00AB84;
  border-color: #00AB84; }
  .btn-ccm-green:hover {
    color: #fff;
    background-color: #008566;
    border-color: #00785d; }
  .btn-ccm-green:focus, .btn-ccm-green.focus {
    color: #fff;
    background-color: #008566;
    border-color: #00785d;
    box-shadow: 0 0 0 0.2rem rgba(38, 184, 150, 0.5); }
  .btn-ccm-green.disabled, .btn-ccm-green:disabled {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
  .btn-ccm-green:not(:disabled):not(.disabled):active, .btn-ccm-green:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-green.dropdown-toggle {
    color: #fff;
    background-color: #00785d;
    border-color: #006b53; }
    .btn-ccm-green:not(:disabled):not(.disabled):active:focus, .btn-ccm-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 184, 150, 0.5); }

.btn-ccm-yellow {
  color: #212529;
  background-color: #E1CD00;
  border-color: #E1CD00; }
  .btn-ccm-yellow:hover {
    color: #212529;
    background-color: #bbaa00;
    border-color: #ae9f00; }
  .btn-ccm-yellow:focus, .btn-ccm-yellow.focus {
    color: #212529;
    background-color: #bbaa00;
    border-color: #ae9f00;
    box-shadow: 0 0 0 0.2rem rgba(196, 180, 6, 0.5); }
  .btn-ccm-yellow.disabled, .btn-ccm-yellow:disabled {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
  .btn-ccm-yellow:not(:disabled):not(.disabled):active, .btn-ccm-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-yellow.dropdown-toggle {
    color: #fff;
    background-color: #ae9f00;
    border-color: #a19300; }
    .btn-ccm-yellow:not(:disabled):not(.disabled):active:focus, .btn-ccm-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 180, 6, 0.5); }

.btn-ccm-orange {
  color: #fff;
  background-color: #E67E22;
  border-color: #E67E22; }
  .btn-ccm-orange:hover {
    color: #fff;
    background-color: #cb6b17;
    border-color: #bf6516; }
  .btn-ccm-orange:focus, .btn-ccm-orange.focus {
    color: #fff;
    background-color: #cb6b17;
    border-color: #bf6516;
    box-shadow: 0 0 0 0.2rem rgba(234, 145, 67, 0.5); }
  .btn-ccm-orange.disabled, .btn-ccm-orange:disabled {
    color: #fff;
    background-color: #E67E22;
    border-color: #E67E22; }
  .btn-ccm-orange:not(:disabled):not(.disabled):active, .btn-ccm-orange:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-orange.dropdown-toggle {
    color: #fff;
    background-color: #bf6516;
    border-color: #b45f14; }
    .btn-ccm-orange:not(:disabled):not(.disabled):active:focus, .btn-ccm-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 145, 67, 0.5); }

.btn-ccm-bismark {
  color: #fff;
  background-color: #4A6784;
  border-color: #4A6784; }
  .btn-ccm-bismark:hover {
    color: #fff;
    background-color: #3c546b;
    border-color: #384e63; }
  .btn-ccm-bismark:focus, .btn-ccm-bismark.focus {
    color: #fff;
    background-color: #3c546b;
    border-color: #384e63;
    box-shadow: 0 0 0 0.2rem rgba(101, 126, 150, 0.5); }
  .btn-ccm-bismark.disabled, .btn-ccm-bismark:disabled {
    color: #fff;
    background-color: #4A6784;
    border-color: #4A6784; }
  .btn-ccm-bismark:not(:disabled):not(.disabled):active, .btn-ccm-bismark:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-bismark.dropdown-toggle {
    color: #fff;
    background-color: #384e63;
    border-color: #33475b; }
    .btn-ccm-bismark:not(:disabled):not(.disabled):active:focus, .btn-ccm-bismark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-bismark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(101, 126, 150, 0.5); }

.btn-ccm-bali-hai {
  color: #fff;
  background-color: #8095B1;
  border-color: #8095B1; }
  .btn-ccm-bali-hai:hover {
    color: #fff;
    background-color: #6881a2;
    border-color: #617b9d; }
  .btn-ccm-bali-hai:focus, .btn-ccm-bali-hai.focus {
    color: #fff;
    background-color: #6881a2;
    border-color: #617b9d;
    box-shadow: 0 0 0 0.2rem rgba(147, 165, 189, 0.5); }
  .btn-ccm-bali-hai.disabled, .btn-ccm-bali-hai:disabled {
    color: #fff;
    background-color: #8095B1;
    border-color: #8095B1; }
  .btn-ccm-bali-hai:not(:disabled):not(.disabled):active, .btn-ccm-bali-hai:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-bali-hai.dropdown-toggle {
    color: #fff;
    background-color: #617b9d;
    border-color: #5c7595; }
    .btn-ccm-bali-hai:not(:disabled):not(.disabled):active:focus, .btn-ccm-bali-hai:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-bali-hai.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 165, 189, 0.5); }

.btn-ccm-bluewood {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50; }
  .btn-ccm-bluewood:hover {
    color: #fff;
    background-color: #1e2b37;
    border-color: #1a252f; }
  .btn-ccm-bluewood:focus, .btn-ccm-bluewood.focus {
    color: #fff;
    background-color: #1e2b37;
    border-color: #1a252f;
    box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5); }
  .btn-ccm-bluewood.disabled, .btn-ccm-bluewood:disabled {
    color: #fff;
    background-color: #2C3E50;
    border-color: #2C3E50; }
  .btn-ccm-bluewood:not(:disabled):not(.disabled):active, .btn-ccm-bluewood:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-bluewood.dropdown-toggle {
    color: #fff;
    background-color: #1a252f;
    border-color: #151e27; }
    .btn-ccm-bluewood:not(:disabled):not(.disabled):active:focus, .btn-ccm-bluewood:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-bluewood.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5); }

.btn-ccm-lipstick {
  color: #fff;
  background-color: #C6017D;
  border-color: #C6017D; }
  .btn-ccm-lipstick:hover {
    color: #fff;
    background-color: #a00165;
    border-color: #93015d; }
  .btn-ccm-lipstick:focus, .btn-ccm-lipstick.focus {
    color: #fff;
    background-color: #a00165;
    border-color: #93015d;
    box-shadow: 0 0 0 0.2rem rgba(207, 39, 145, 0.5); }
  .btn-ccm-lipstick.disabled, .btn-ccm-lipstick:disabled {
    color: #fff;
    background-color: #C6017D;
    border-color: #C6017D; }
  .btn-ccm-lipstick:not(:disabled):not(.disabled):active, .btn-ccm-lipstick:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-lipstick.dropdown-toggle {
    color: #fff;
    background-color: #93015d;
    border-color: #870155; }
    .btn-ccm-lipstick:not(:disabled):not(.disabled):active:focus, .btn-ccm-lipstick:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-lipstick.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 39, 145, 0.5); }

.btn-ccm-light-gray {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #F2F2F2; }
  .btn-ccm-light-gray:hover {
    color: #212529;
    background-color: #dfdfdf;
    border-color: #d9d9d9; }
  .btn-ccm-light-gray:focus, .btn-ccm-light-gray.focus {
    color: #212529;
    background-color: #dfdfdf;
    border-color: #d9d9d9;
    box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5); }
  .btn-ccm-light-gray.disabled, .btn-ccm-light-gray:disabled {
    color: #212529;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
  .btn-ccm-light-gray:not(:disabled):not(.disabled):active, .btn-ccm-light-gray:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-light-gray.dropdown-toggle {
    color: #212529;
    background-color: #d9d9d9;
    border-color: #d2d2d2; }
    .btn-ccm-light-gray:not(:disabled):not(.disabled):active:focus, .btn-ccm-light-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-light-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5); }

.btn-ccm-bright-red {
  color: #fff;
  background-color: #FF0000;
  border-color: #FF0000; }
  .btn-ccm-bright-red:hover {
    color: #fff;
    background-color: #d90000;
    border-color: #cc0000; }
  .btn-ccm-bright-red:focus, .btn-ccm-bright-red.focus {
    color: #fff;
    background-color: #d90000;
    border-color: #cc0000;
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5); }
  .btn-ccm-bright-red.disabled, .btn-ccm-bright-red:disabled {
    color: #fff;
    background-color: #FF0000;
    border-color: #FF0000; }
  .btn-ccm-bright-red:not(:disabled):not(.disabled):active, .btn-ccm-bright-red:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-bright-red.dropdown-toggle {
    color: #fff;
    background-color: #cc0000;
    border-color: #bf0000; }
    .btn-ccm-bright-red:not(:disabled):not(.disabled):active:focus, .btn-ccm-bright-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-bright-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5); }

.btn-ccm-inactive {
  color: #fff;
  background-color: #929292;
  border-color: #929292; }
  .btn-ccm-inactive:hover {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #797979; }
  .btn-ccm-inactive:focus, .btn-ccm-inactive.focus {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #797979;
    box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5); }
  .btn-ccm-inactive.disabled, .btn-ccm-inactive:disabled {
    color: #fff;
    background-color: #929292;
    border-color: #929292; }
  .btn-ccm-inactive:not(:disabled):not(.disabled):active, .btn-ccm-inactive:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-inactive.dropdown-toggle {
    color: #fff;
    background-color: #797979;
    border-color: #727272; }
    .btn-ccm-inactive:not(:disabled):not(.disabled):active:focus, .btn-ccm-inactive:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-inactive.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5); }

.btn-ccm-boulder {
  color: #fff;
  background-color: #767676;
  border-color: #767676; }
  .btn-ccm-boulder:hover {
    color: #fff;
    background-color: #636363;
    border-color: #5d5d5d; }
  .btn-ccm-boulder:focus, .btn-ccm-boulder.focus {
    color: #fff;
    background-color: #636363;
    border-color: #5d5d5d;
    box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }
  .btn-ccm-boulder.disabled, .btn-ccm-boulder:disabled {
    color: #fff;
    background-color: #767676;
    border-color: #767676; }
  .btn-ccm-boulder:not(:disabled):not(.disabled):active, .btn-ccm-boulder:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-boulder.dropdown-toggle {
    color: #fff;
    background-color: #5d5d5d;
    border-color: #565656; }
    .btn-ccm-boulder:not(:disabled):not(.disabled):active:focus, .btn-ccm-boulder:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-boulder.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }

.btn-ccm-darker-yellow {
  color: #212529;
  background-color: #BBAA03;
  border-color: #BBAA03; }
  .btn-ccm-darker-yellow:hover {
    color: #fff;
    background-color: #958802;
    border-color: #897c02; }
  .btn-ccm-darker-yellow:focus, .btn-ccm-darker-yellow.focus {
    color: #fff;
    background-color: #958802;
    border-color: #897c02;
    box-shadow: 0 0 0 0.2rem rgba(164, 150, 9, 0.5); }
  .btn-ccm-darker-yellow.disabled, .btn-ccm-darker-yellow:disabled {
    color: #212529;
    background-color: #BBAA03;
    border-color: #BBAA03; }
  .btn-ccm-darker-yellow:not(:disabled):not(.disabled):active, .btn-ccm-darker-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-ccm-darker-yellow.dropdown-toggle {
    color: #fff;
    background-color: #897c02;
    border-color: #7c7102; }
    .btn-ccm-darker-yellow:not(:disabled):not(.disabled):active:focus, .btn-ccm-darker-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ccm-darker-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(164, 150, 9, 0.5); }

.btn-outline-primary {
  color: #74A3E1;
  border-color: #74A3E1; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #74A3E1;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.btn-outline-secondary {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-success {
  color: #00AB84;
  border-color: #00AB84; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00AB84;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }

.btn-outline-info {
  color: #74A3E1;
  border-color: #74A3E1; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #74A3E1;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.btn-outline-warning {
  color: #E1CD00;
  border-color: #E1CD00; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #E1CD00;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }

.btn-outline-danger {
  color: #E74C3C;
  border-color: #E74C3C; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #E74C3C;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }

.btn-outline-light {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #000000;
  border-color: #000000; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #000000;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-ccm-red {
  color: #E74C3C;
  border-color: #E74C3C; }
  .btn-outline-ccm-red:hover {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
  .btn-outline-ccm-red:focus, .btn-outline-ccm-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }
  .btn-outline-ccm-red.disabled, .btn-outline-ccm-red:disabled {
    color: #E74C3C;
    background-color: transparent; }
  .btn-outline-ccm-red:not(:disabled):not(.disabled):active, .btn-outline-ccm-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-red.dropdown-toggle {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C; }
    .btn-outline-ccm-red:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }

.btn-outline-ccm-gray {
  color: #C4C4C4;
  border-color: #C4C4C4; }
  .btn-outline-ccm-gray:hover {
    color: #212529;
    background-color: #C4C4C4;
    border-color: #C4C4C4; }
  .btn-outline-ccm-gray:focus, .btn-outline-ccm-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5); }
  .btn-outline-ccm-gray.disabled, .btn-outline-ccm-gray:disabled {
    color: #C4C4C4;
    background-color: transparent; }
  .btn-outline-ccm-gray:not(:disabled):not(.disabled):active, .btn-outline-ccm-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-gray.dropdown-toggle {
    color: #212529;
    background-color: #C4C4C4;
    border-color: #C4C4C4; }
    .btn-outline-ccm-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5); }

.btn-outline-ccm-oslo {
  color: #8D89A5;
  border-color: #8D89A5; }
  .btn-outline-ccm-oslo:hover {
    color: #fff;
    background-color: #8D89A5;
    border-color: #8D89A5; }
  .btn-outline-ccm-oslo:focus, .btn-outline-ccm-oslo.focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 137, 165, 0.5); }
  .btn-outline-ccm-oslo.disabled, .btn-outline-ccm-oslo:disabled {
    color: #8D89A5;
    background-color: transparent; }
  .btn-outline-ccm-oslo:not(:disabled):not(.disabled):active, .btn-outline-ccm-oslo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-oslo.dropdown-toggle {
    color: #fff;
    background-color: #8D89A5;
    border-color: #8D89A5; }
    .btn-outline-ccm-oslo:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-oslo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-oslo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(141, 137, 165, 0.5); }

.btn-outline-ccm-navy {
  color: #2980B9;
  border-color: #2980B9; }
  .btn-outline-ccm-navy:hover {
    color: #fff;
    background-color: #2980B9;
    border-color: #2980B9; }
  .btn-outline-ccm-navy:focus, .btn-outline-ccm-navy.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 128, 185, 0.5); }
  .btn-outline-ccm-navy.disabled, .btn-outline-ccm-navy:disabled {
    color: #2980B9;
    background-color: transparent; }
  .btn-outline-ccm-navy:not(:disabled):not(.disabled):active, .btn-outline-ccm-navy:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-navy.dropdown-toggle {
    color: #fff;
    background-color: #2980B9;
    border-color: #2980B9; }
    .btn-outline-ccm-navy:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-navy:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-navy.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 128, 185, 0.5); }

.btn-outline-ccm-blue {
  color: #74A3E1;
  border-color: #74A3E1; }
  .btn-outline-ccm-blue:hover {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .btn-outline-ccm-blue:focus, .btn-outline-ccm-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }
  .btn-outline-ccm-blue.disabled, .btn-outline-ccm-blue:disabled {
    color: #74A3E1;
    background-color: transparent; }
  .btn-outline-ccm-blue:not(:disabled):not(.disabled):active, .btn-outline-ccm-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-blue.dropdown-toggle {
    color: #212529;
    background-color: #74A3E1;
    border-color: #74A3E1; }
    .btn-outline-ccm-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.btn-outline-ccm-green {
  color: #00AB84;
  border-color: #00AB84; }
  .btn-outline-ccm-green:hover {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
  .btn-outline-ccm-green:focus, .btn-outline-ccm-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }
  .btn-outline-ccm-green.disabled, .btn-outline-ccm-green:disabled {
    color: #00AB84;
    background-color: transparent; }
  .btn-outline-ccm-green:not(:disabled):not(.disabled):active, .btn-outline-ccm-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-green.dropdown-toggle {
    color: #fff;
    background-color: #00AB84;
    border-color: #00AB84; }
    .btn-outline-ccm-green:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }

.btn-outline-ccm-yellow {
  color: #E1CD00;
  border-color: #E1CD00; }
  .btn-outline-ccm-yellow:hover {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
  .btn-outline-ccm-yellow:focus, .btn-outline-ccm-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }
  .btn-outline-ccm-yellow.disabled, .btn-outline-ccm-yellow:disabled {
    color: #E1CD00;
    background-color: transparent; }
  .btn-outline-ccm-yellow:not(:disabled):not(.disabled):active, .btn-outline-ccm-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-yellow.dropdown-toggle {
    color: #212529;
    background-color: #E1CD00;
    border-color: #E1CD00; }
    .btn-outline-ccm-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }

.btn-outline-ccm-orange {
  color: #E67E22;
  border-color: #E67E22; }
  .btn-outline-ccm-orange:hover {
    color: #fff;
    background-color: #E67E22;
    border-color: #E67E22; }
  .btn-outline-ccm-orange:focus, .btn-outline-ccm-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 126, 34, 0.5); }
  .btn-outline-ccm-orange.disabled, .btn-outline-ccm-orange:disabled {
    color: #E67E22;
    background-color: transparent; }
  .btn-outline-ccm-orange:not(:disabled):not(.disabled):active, .btn-outline-ccm-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-orange.dropdown-toggle {
    color: #fff;
    background-color: #E67E22;
    border-color: #E67E22; }
    .btn-outline-ccm-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 126, 34, 0.5); }

.btn-outline-ccm-bismark {
  color: #4A6784;
  border-color: #4A6784; }
  .btn-outline-ccm-bismark:hover {
    color: #fff;
    background-color: #4A6784;
    border-color: #4A6784; }
  .btn-outline-ccm-bismark:focus, .btn-outline-ccm-bismark.focus {
    box-shadow: 0 0 0 0.2rem rgba(74, 103, 132, 0.5); }
  .btn-outline-ccm-bismark.disabled, .btn-outline-ccm-bismark:disabled {
    color: #4A6784;
    background-color: transparent; }
  .btn-outline-ccm-bismark:not(:disabled):not(.disabled):active, .btn-outline-ccm-bismark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-bismark.dropdown-toggle {
    color: #fff;
    background-color: #4A6784;
    border-color: #4A6784; }
    .btn-outline-ccm-bismark:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-bismark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-bismark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 103, 132, 0.5); }

.btn-outline-ccm-bali-hai {
  color: #8095B1;
  border-color: #8095B1; }
  .btn-outline-ccm-bali-hai:hover {
    color: #fff;
    background-color: #8095B1;
    border-color: #8095B1; }
  .btn-outline-ccm-bali-hai:focus, .btn-outline-ccm-bali-hai.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 149, 177, 0.5); }
  .btn-outline-ccm-bali-hai.disabled, .btn-outline-ccm-bali-hai:disabled {
    color: #8095B1;
    background-color: transparent; }
  .btn-outline-ccm-bali-hai:not(:disabled):not(.disabled):active, .btn-outline-ccm-bali-hai:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-bali-hai.dropdown-toggle {
    color: #fff;
    background-color: #8095B1;
    border-color: #8095B1; }
    .btn-outline-ccm-bali-hai:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-bali-hai:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-bali-hai.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 149, 177, 0.5); }

.btn-outline-ccm-bluewood {
  color: #2C3E50;
  border-color: #2C3E50; }
  .btn-outline-ccm-bluewood:hover {
    color: #fff;
    background-color: #2C3E50;
    border-color: #2C3E50; }
  .btn-outline-ccm-bluewood:focus, .btn-outline-ccm-bluewood.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5); }
  .btn-outline-ccm-bluewood.disabled, .btn-outline-ccm-bluewood:disabled {
    color: #2C3E50;
    background-color: transparent; }
  .btn-outline-ccm-bluewood:not(:disabled):not(.disabled):active, .btn-outline-ccm-bluewood:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-bluewood.dropdown-toggle {
    color: #fff;
    background-color: #2C3E50;
    border-color: #2C3E50; }
    .btn-outline-ccm-bluewood:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-bluewood:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-bluewood.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5); }

.btn-outline-ccm-lipstick {
  color: #C6017D;
  border-color: #C6017D; }
  .btn-outline-ccm-lipstick:hover {
    color: #fff;
    background-color: #C6017D;
    border-color: #C6017D; }
  .btn-outline-ccm-lipstick:focus, .btn-outline-ccm-lipstick.focus {
    box-shadow: 0 0 0 0.2rem rgba(198, 1, 125, 0.5); }
  .btn-outline-ccm-lipstick.disabled, .btn-outline-ccm-lipstick:disabled {
    color: #C6017D;
    background-color: transparent; }
  .btn-outline-ccm-lipstick:not(:disabled):not(.disabled):active, .btn-outline-ccm-lipstick:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-lipstick.dropdown-toggle {
    color: #fff;
    background-color: #C6017D;
    border-color: #C6017D; }
    .btn-outline-ccm-lipstick:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-lipstick:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-lipstick.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(198, 1, 125, 0.5); }

.btn-outline-ccm-light-gray {
  color: #F2F2F2;
  border-color: #F2F2F2; }
  .btn-outline-ccm-light-gray:hover {
    color: #212529;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
  .btn-outline-ccm-light-gray:focus, .btn-outline-ccm-light-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }
  .btn-outline-ccm-light-gray.disabled, .btn-outline-ccm-light-gray:disabled {
    color: #F2F2F2;
    background-color: transparent; }
  .btn-outline-ccm-light-gray:not(:disabled):not(.disabled):active, .btn-outline-ccm-light-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-light-gray.dropdown-toggle {
    color: #212529;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
    .btn-outline-ccm-light-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-light-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-light-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }

.btn-outline-ccm-bright-red {
  color: #FF0000;
  border-color: #FF0000; }
  .btn-outline-ccm-bright-red:hover {
    color: #fff;
    background-color: #FF0000;
    border-color: #FF0000; }
  .btn-outline-ccm-bright-red:focus, .btn-outline-ccm-bright-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5); }
  .btn-outline-ccm-bright-red.disabled, .btn-outline-ccm-bright-red:disabled {
    color: #FF0000;
    background-color: transparent; }
  .btn-outline-ccm-bright-red:not(:disabled):not(.disabled):active, .btn-outline-ccm-bright-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-bright-red.dropdown-toggle {
    color: #fff;
    background-color: #FF0000;
    border-color: #FF0000; }
    .btn-outline-ccm-bright-red:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-bright-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-bright-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5); }

.btn-outline-ccm-inactive {
  color: #929292;
  border-color: #929292; }
  .btn-outline-ccm-inactive:hover {
    color: #fff;
    background-color: #929292;
    border-color: #929292; }
  .btn-outline-ccm-inactive:focus, .btn-outline-ccm-inactive.focus {
    box-shadow: 0 0 0 0.2rem rgba(146, 146, 146, 0.5); }
  .btn-outline-ccm-inactive.disabled, .btn-outline-ccm-inactive:disabled {
    color: #929292;
    background-color: transparent; }
  .btn-outline-ccm-inactive:not(:disabled):not(.disabled):active, .btn-outline-ccm-inactive:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-inactive.dropdown-toggle {
    color: #fff;
    background-color: #929292;
    border-color: #929292; }
    .btn-outline-ccm-inactive:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-inactive:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-inactive.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(146, 146, 146, 0.5); }

.btn-outline-ccm-boulder {
  color: #767676;
  border-color: #767676; }
  .btn-outline-ccm-boulder:hover {
    color: #fff;
    background-color: #767676;
    border-color: #767676; }
  .btn-outline-ccm-boulder:focus, .btn-outline-ccm-boulder.focus {
    box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5); }
  .btn-outline-ccm-boulder.disabled, .btn-outline-ccm-boulder:disabled {
    color: #767676;
    background-color: transparent; }
  .btn-outline-ccm-boulder:not(:disabled):not(.disabled):active, .btn-outline-ccm-boulder:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-boulder.dropdown-toggle {
    color: #fff;
    background-color: #767676;
    border-color: #767676; }
    .btn-outline-ccm-boulder:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-boulder:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-boulder.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5); }

.btn-outline-ccm-darker-yellow {
  color: #BBAA03;
  border-color: #BBAA03; }
  .btn-outline-ccm-darker-yellow:hover {
    color: #212529;
    background-color: #BBAA03;
    border-color: #BBAA03; }
  .btn-outline-ccm-darker-yellow:focus, .btn-outline-ccm-darker-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 170, 3, 0.5); }
  .btn-outline-ccm-darker-yellow.disabled, .btn-outline-ccm-darker-yellow:disabled {
    color: #BBAA03;
    background-color: transparent; }
  .btn-outline-ccm-darker-yellow:not(:disabled):not(.disabled):active, .btn-outline-ccm-darker-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ccm-darker-yellow.dropdown-toggle {
    color: #212529;
    background-color: #BBAA03;
    border-color: #BBAA03; }
    .btn-outline-ccm-darker-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-ccm-darker-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ccm-darker-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(187, 170, 3, 0.5); }

.btn-link {
  font-weight: 400;
  color: #74A3E1;
  text-decoration: none; }
  .btn-link:hover {
    color: #3579d3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #74A3E1; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #74A3E1;
    background-color: #74A3E1; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dde8f8; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #74A3E1;
  background-color: #74A3E1; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(116, 163, 225, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(116, 163, 225, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(116, 163, 225, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(116, 163, 225, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #dde8f8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #dde8f8;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #74A3E1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #74A3E1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #74A3E1;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #74A3E1; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #74A3E1;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #3579d3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #74A3E1;
  border-color: #74A3E1; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #74A3E1; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #4a87d8; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #FFFFFF; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-success {
  color: #fff;
  background-color: #00AB84; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #00785d; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }

.badge-info {
  color: #212529;
  background-color: #74A3E1; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #4a87d8; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #E1CD00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ae9f00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #E74C3C; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #d62c1a; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }

.badge-light {
  color: #212529;
  background-color: #FFFFFF; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #000000; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-ccm-red {
  color: #fff;
  background-color: #E74C3C; }
  a.badge-ccm-red:hover, a.badge-ccm-red:focus {
    color: #fff;
    background-color: #d62c1a; }
  a.badge-ccm-red:focus, a.badge-ccm-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5); }

.badge-ccm-gray {
  color: #212529;
  background-color: #C4C4C4; }
  a.badge-ccm-gray:hover, a.badge-ccm-gray:focus {
    color: #212529;
    background-color: #ababab; }
  a.badge-ccm-gray:focus, a.badge-ccm-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5); }

.badge-ccm-oslo {
  color: #fff;
  background-color: #8D89A5; }
  a.badge-ccm-oslo:hover, a.badge-ccm-oslo:focus {
    color: #fff;
    background-color: #716d8e; }
  a.badge-ccm-oslo:focus, a.badge-ccm-oslo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(141, 137, 165, 0.5); }

.badge-ccm-navy {
  color: #fff;
  background-color: #2980B9; }
  a.badge-ccm-navy:hover, a.badge-ccm-navy:focus {
    color: #fff;
    background-color: #20638f; }
  a.badge-ccm-navy:focus, a.badge-ccm-navy.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(41, 128, 185, 0.5); }

.badge-ccm-blue {
  color: #212529;
  background-color: #74A3E1; }
  a.badge-ccm-blue:hover, a.badge-ccm-blue:focus {
    color: #212529;
    background-color: #4a87d8; }
  a.badge-ccm-blue:focus, a.badge-ccm-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(116, 163, 225, 0.5); }

.badge-ccm-green {
  color: #fff;
  background-color: #00AB84; }
  a.badge-ccm-green:hover, a.badge-ccm-green:focus {
    color: #fff;
    background-color: #00785d; }
  a.badge-ccm-green:focus, a.badge-ccm-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 171, 132, 0.5); }

.badge-ccm-yellow {
  color: #212529;
  background-color: #E1CD00; }
  a.badge-ccm-yellow:hover, a.badge-ccm-yellow:focus {
    color: #212529;
    background-color: #ae9f00; }
  a.badge-ccm-yellow:focus, a.badge-ccm-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(225, 205, 0, 0.5); }

.badge-ccm-orange {
  color: #fff;
  background-color: #E67E22; }
  a.badge-ccm-orange:hover, a.badge-ccm-orange:focus {
    color: #fff;
    background-color: #bf6516; }
  a.badge-ccm-orange:focus, a.badge-ccm-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(230, 126, 34, 0.5); }

.badge-ccm-bismark {
  color: #fff;
  background-color: #4A6784; }
  a.badge-ccm-bismark:hover, a.badge-ccm-bismark:focus {
    color: #fff;
    background-color: #384e63; }
  a.badge-ccm-bismark:focus, a.badge-ccm-bismark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(74, 103, 132, 0.5); }

.badge-ccm-bali-hai {
  color: #fff;
  background-color: #8095B1; }
  a.badge-ccm-bali-hai:hover, a.badge-ccm-bali-hai:focus {
    color: #fff;
    background-color: #617b9d; }
  a.badge-ccm-bali-hai:focus, a.badge-ccm-bali-hai.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 149, 177, 0.5); }

.badge-ccm-bluewood {
  color: #fff;
  background-color: #2C3E50; }
  a.badge-ccm-bluewood:hover, a.badge-ccm-bluewood:focus {
    color: #fff;
    background-color: #1a252f; }
  a.badge-ccm-bluewood:focus, a.badge-ccm-bluewood.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5); }

.badge-ccm-lipstick {
  color: #fff;
  background-color: #C6017D; }
  a.badge-ccm-lipstick:hover, a.badge-ccm-lipstick:focus {
    color: #fff;
    background-color: #93015d; }
  a.badge-ccm-lipstick:focus, a.badge-ccm-lipstick.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(198, 1, 125, 0.5); }

.badge-ccm-light-gray {
  color: #212529;
  background-color: #F2F2F2; }
  a.badge-ccm-light-gray:hover, a.badge-ccm-light-gray:focus {
    color: #212529;
    background-color: #d9d9d9; }
  a.badge-ccm-light-gray:focus, a.badge-ccm-light-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }

.badge-ccm-bright-red {
  color: #fff;
  background-color: #FF0000; }
  a.badge-ccm-bright-red:hover, a.badge-ccm-bright-red:focus {
    color: #fff;
    background-color: #cc0000; }
  a.badge-ccm-bright-red:focus, a.badge-ccm-bright-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5); }

.badge-ccm-inactive {
  color: #fff;
  background-color: #929292; }
  a.badge-ccm-inactive:hover, a.badge-ccm-inactive:focus {
    color: #fff;
    background-color: #797979; }
  a.badge-ccm-inactive:focus, a.badge-ccm-inactive.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(146, 146, 146, 0.5); }

.badge-ccm-boulder {
  color: #fff;
  background-color: #767676; }
  a.badge-ccm-boulder:hover, a.badge-ccm-boulder:focus {
    color: #fff;
    background-color: #5d5d5d; }
  a.badge-ccm-boulder:focus, a.badge-ccm-boulder.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5); }

.badge-ccm-darker-yellow {
  color: #212529;
  background-color: #BBAA03; }
  a.badge-ccm-darker-yellow:hover, a.badge-ccm-darker-yellow:focus {
    color: #212529;
    background-color: #897c02; }
  a.badge-ccm-darker-yellow:focus, a.badge-ccm-darker-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(187, 170, 3, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #3c5575;
  background-color: #e3edf9;
  border-color: #d8e5f7; }
  .alert-primary hr {
    border-top-color: #c3d7f3; }
  .alert-primary .alert-link {
    color: #2b3d53; }

.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #6c6c6c; }

.alert-success {
  color: #005945;
  background-color: #cceee6;
  border-color: #b8e7dd; }
  .alert-success hr {
    border-top-color: #a5e1d4; }
  .alert-success .alert-link {
    color: #00261d; }

.alert-info {
  color: #3c5575;
  background-color: #e3edf9;
  border-color: #d8e5f7; }
  .alert-info hr {
    border-top-color: #c3d7f3; }
  .alert-info .alert-link {
    color: #2b3d53; }

.alert-warning {
  color: #756b00;
  background-color: #f9f5cc;
  border-color: #f7f1b8; }
  .alert-warning hr {
    border-top-color: #f4eca1; }
  .alert-warning .alert-link {
    color: #423c00; }

.alert-danger {
  color: #78281f;
  background-color: #fadbd8;
  border-color: #f8cdc8; }
  .alert-danger hr {
    border-top-color: #f5b8b1; }
  .alert-danger .alert-link {
    color: #4f1a15; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-dark hr {
    border-top-color: #ababab; }
  .alert-dark .alert-link {
    color: black; }

.alert-ccm-red {
  color: #78281f;
  background-color: #fadbd8;
  border-color: #f8cdc8; }
  .alert-ccm-red hr {
    border-top-color: #f5b8b1; }
  .alert-ccm-red .alert-link {
    color: #4f1a15; }

.alert-ccm-gray {
  color: #666666;
  background-color: #f3f3f3;
  border-color: #eeeeee; }
  .alert-ccm-gray hr {
    border-top-color: #e1e1e1; }
  .alert-ccm-gray .alert-link {
    color: #4d4d4d; }

.alert-ccm-oslo {
  color: #494756;
  background-color: #e8e7ed;
  border-color: #dfdee6; }
  .alert-ccm-oslo hr {
    border-top-color: #d1cfdb; }
  .alert-ccm-oslo .alert-link {
    color: #31303a; }

.alert-ccm-navy {
  color: #154360;
  background-color: #d4e6f1;
  border-color: #c3dbeb; }
  .alert-ccm-navy hr {
    border-top-color: #b0d0e5; }
  .alert-ccm-navy .alert-link {
    color: #0c2636; }

.alert-ccm-blue {
  color: #3c5575;
  background-color: #e3edf9;
  border-color: #d8e5f7; }
  .alert-ccm-blue hr {
    border-top-color: #c3d7f3; }
  .alert-ccm-blue .alert-link {
    color: #2b3d53; }

.alert-ccm-green {
  color: #005945;
  background-color: #cceee6;
  border-color: #b8e7dd; }
  .alert-ccm-green hr {
    border-top-color: #a5e1d4; }
  .alert-ccm-green .alert-link {
    color: #00261d; }

.alert-ccm-yellow {
  color: #756b00;
  background-color: #f9f5cc;
  border-color: #f7f1b8; }
  .alert-ccm-yellow hr {
    border-top-color: #f4eca1; }
  .alert-ccm-yellow .alert-link {
    color: #423c00; }

.alert-ccm-orange {
  color: #784212;
  background-color: #fae5d3;
  border-color: #f8dbc1; }
  .alert-ccm-orange hr {
    border-top-color: #f5ceaa; }
  .alert-ccm-orange .alert-link {
    color: #4c2a0b; }

.alert-ccm-bismark {
  color: #263645;
  background-color: #dbe1e6;
  border-color: #ccd4dd; }
  .alert-ccm-bismark hr {
    border-top-color: #bdc7d3; }
  .alert-ccm-bismark .alert-link {
    color: #141c24; }

.alert-ccm-bali-hai {
  color: #434d5c;
  background-color: #e6eaef;
  border-color: #dbe1e9; }
  .alert-ccm-bali-hai hr {
    border-top-color: #cbd4df; }
  .alert-ccm-bali-hai .alert-link {
    color: #2e343e; }

.alert-ccm-bluewood {
  color: #17202a;
  background-color: #d5d8dc;
  border-color: #c4c9ce; }
  .alert-ccm-bluewood hr {
    border-top-color: #b6bcc2; }
  .alert-ccm-bluewood .alert-link {
    color: #050709; }

.alert-ccm-lipstick {
  color: #670141;
  background-color: #f4cce5;
  border-color: #efb8db; }
  .alert-ccm-lipstick hr {
    border-top-color: #eaa3d0; }
  .alert-ccm-lipstick .alert-link {
    color: #340121; }

.alert-ccm-light-gray {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-ccm-light-gray hr {
    border-top-color: #eeeeee; }
  .alert-ccm-light-gray .alert-link {
    color: #656565; }

.alert-ccm-bright-red {
  color: #850000;
  background-color: #ffcccc;
  border-color: #ffb8b8; }
  .alert-ccm-bright-red hr {
    border-top-color: #ff9f9f; }
  .alert-ccm-bright-red .alert-link {
    color: #520000; }

.alert-ccm-inactive {
  color: #4c4c4c;
  background-color: #e9e9e9;
  border-color: #e0e0e0; }
  .alert-ccm-inactive hr {
    border-top-color: lightgray; }
  .alert-ccm-inactive .alert-link {
    color: #333333; }

.alert-ccm-boulder {
  color: #3d3d3d;
  background-color: #e4e4e4;
  border-color: #d9d9d9; }
  .alert-ccm-boulder hr {
    border-top-color: #cccccc; }
  .alert-ccm-boulder .alert-link {
    color: #242424; }

.alert-ccm-darker-yellow {
  color: #615802;
  background-color: #f1eecd;
  border-color: #ece7b8; }
  .alert-ccm-darker-yellow hr {
    border-top-color: #e7e0a4; }
  .alert-ccm-darker-yellow .alert-link {
    color: #2f2b01; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #74A3E1;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #74A3E1;
    border-color: #74A3E1; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #3c5575;
  background-color: #d8e5f7; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3c5575;
    background-color: #c3d7f3; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3c5575;
    border-color: #3c5575; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-success {
  color: #005945;
  background-color: #b8e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #005945;
    background-color: #a5e1d4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #005945;
    border-color: #005945; }

.list-group-item-info {
  color: #3c5575;
  background-color: #d8e5f7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3c5575;
    background-color: #c3d7f3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3c5575;
    border-color: #3c5575; }

.list-group-item-warning {
  color: #756b00;
  background-color: #f7f1b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #756b00;
    background-color: #f4eca1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #756b00;
    border-color: #756b00; }

.list-group-item-danger {
  color: #78281f;
  background-color: #f8cdc8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #78281f;
    background-color: #f5b8b1; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #78281f;
    border-color: #78281f; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-ccm-red {
  color: #78281f;
  background-color: #f8cdc8; }
  .list-group-item-ccm-red.list-group-item-action:hover, .list-group-item-ccm-red.list-group-item-action:focus {
    color: #78281f;
    background-color: #f5b8b1; }
  .list-group-item-ccm-red.list-group-item-action.active {
    color: #fff;
    background-color: #78281f;
    border-color: #78281f; }

.list-group-item-ccm-gray {
  color: #666666;
  background-color: #eeeeee; }
  .list-group-item-ccm-gray.list-group-item-action:hover, .list-group-item-ccm-gray.list-group-item-action:focus {
    color: #666666;
    background-color: #e1e1e1; }
  .list-group-item-ccm-gray.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-ccm-oslo {
  color: #494756;
  background-color: #dfdee6; }
  .list-group-item-ccm-oslo.list-group-item-action:hover, .list-group-item-ccm-oslo.list-group-item-action:focus {
    color: #494756;
    background-color: #d1cfdb; }
  .list-group-item-ccm-oslo.list-group-item-action.active {
    color: #fff;
    background-color: #494756;
    border-color: #494756; }

.list-group-item-ccm-navy {
  color: #154360;
  background-color: #c3dbeb; }
  .list-group-item-ccm-navy.list-group-item-action:hover, .list-group-item-ccm-navy.list-group-item-action:focus {
    color: #154360;
    background-color: #b0d0e5; }
  .list-group-item-ccm-navy.list-group-item-action.active {
    color: #fff;
    background-color: #154360;
    border-color: #154360; }

.list-group-item-ccm-blue {
  color: #3c5575;
  background-color: #d8e5f7; }
  .list-group-item-ccm-blue.list-group-item-action:hover, .list-group-item-ccm-blue.list-group-item-action:focus {
    color: #3c5575;
    background-color: #c3d7f3; }
  .list-group-item-ccm-blue.list-group-item-action.active {
    color: #fff;
    background-color: #3c5575;
    border-color: #3c5575; }

.list-group-item-ccm-green {
  color: #005945;
  background-color: #b8e7dd; }
  .list-group-item-ccm-green.list-group-item-action:hover, .list-group-item-ccm-green.list-group-item-action:focus {
    color: #005945;
    background-color: #a5e1d4; }
  .list-group-item-ccm-green.list-group-item-action.active {
    color: #fff;
    background-color: #005945;
    border-color: #005945; }

.list-group-item-ccm-yellow {
  color: #756b00;
  background-color: #f7f1b8; }
  .list-group-item-ccm-yellow.list-group-item-action:hover, .list-group-item-ccm-yellow.list-group-item-action:focus {
    color: #756b00;
    background-color: #f4eca1; }
  .list-group-item-ccm-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #756b00;
    border-color: #756b00; }

.list-group-item-ccm-orange {
  color: #784212;
  background-color: #f8dbc1; }
  .list-group-item-ccm-orange.list-group-item-action:hover, .list-group-item-ccm-orange.list-group-item-action:focus {
    color: #784212;
    background-color: #f5ceaa; }
  .list-group-item-ccm-orange.list-group-item-action.active {
    color: #fff;
    background-color: #784212;
    border-color: #784212; }

.list-group-item-ccm-bismark {
  color: #263645;
  background-color: #ccd4dd; }
  .list-group-item-ccm-bismark.list-group-item-action:hover, .list-group-item-ccm-bismark.list-group-item-action:focus {
    color: #263645;
    background-color: #bdc7d3; }
  .list-group-item-ccm-bismark.list-group-item-action.active {
    color: #fff;
    background-color: #263645;
    border-color: #263645; }

.list-group-item-ccm-bali-hai {
  color: #434d5c;
  background-color: #dbe1e9; }
  .list-group-item-ccm-bali-hai.list-group-item-action:hover, .list-group-item-ccm-bali-hai.list-group-item-action:focus {
    color: #434d5c;
    background-color: #cbd4df; }
  .list-group-item-ccm-bali-hai.list-group-item-action.active {
    color: #fff;
    background-color: #434d5c;
    border-color: #434d5c; }

.list-group-item-ccm-bluewood {
  color: #17202a;
  background-color: #c4c9ce; }
  .list-group-item-ccm-bluewood.list-group-item-action:hover, .list-group-item-ccm-bluewood.list-group-item-action:focus {
    color: #17202a;
    background-color: #b6bcc2; }
  .list-group-item-ccm-bluewood.list-group-item-action.active {
    color: #fff;
    background-color: #17202a;
    border-color: #17202a; }

.list-group-item-ccm-lipstick {
  color: #670141;
  background-color: #efb8db; }
  .list-group-item-ccm-lipstick.list-group-item-action:hover, .list-group-item-ccm-lipstick.list-group-item-action:focus {
    color: #670141;
    background-color: #eaa3d0; }
  .list-group-item-ccm-lipstick.list-group-item-action.active {
    color: #fff;
    background-color: #670141;
    border-color: #670141; }

.list-group-item-ccm-light-gray {
  color: #7e7e7e;
  background-color: #fbfbfb; }
  .list-group-item-ccm-light-gray.list-group-item-action:hover, .list-group-item-ccm-light-gray.list-group-item-action:focus {
    color: #7e7e7e;
    background-color: #eeeeee; }
  .list-group-item-ccm-light-gray.list-group-item-action.active {
    color: #fff;
    background-color: #7e7e7e;
    border-color: #7e7e7e; }

.list-group-item-ccm-bright-red {
  color: #850000;
  background-color: #ffb8b8; }
  .list-group-item-ccm-bright-red.list-group-item-action:hover, .list-group-item-ccm-bright-red.list-group-item-action:focus {
    color: #850000;
    background-color: #ff9f9f; }
  .list-group-item-ccm-bright-red.list-group-item-action.active {
    color: #fff;
    background-color: #850000;
    border-color: #850000; }

.list-group-item-ccm-inactive {
  color: #4c4c4c;
  background-color: #e0e0e0; }
  .list-group-item-ccm-inactive.list-group-item-action:hover, .list-group-item-ccm-inactive.list-group-item-action:focus {
    color: #4c4c4c;
    background-color: lightgray; }
  .list-group-item-ccm-inactive.list-group-item-action.active {
    color: #fff;
    background-color: #4c4c4c;
    border-color: #4c4c4c; }

.list-group-item-ccm-boulder {
  color: #3d3d3d;
  background-color: #d9d9d9; }
  .list-group-item-ccm-boulder.list-group-item-action:hover, .list-group-item-ccm-boulder.list-group-item-action:focus {
    color: #3d3d3d;
    background-color: #cccccc; }
  .list-group-item-ccm-boulder.list-group-item-action.active {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }

.list-group-item-ccm-darker-yellow {
  color: #615802;
  background-color: #ece7b8; }
  .list-group-item-ccm-darker-yellow.list-group-item-action:hover, .list-group-item-ccm-darker-yellow.list-group-item-action:focus {
    color: #615802;
    background-color: #e7e0a4; }
  .list-group-item-ccm-darker-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #615802;
    border-color: #615802; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #74A3E1 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4a87d8 !important; }

.bg-secondary {
  background-color: #FFFFFF !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

.bg-success {
  background-color: #00AB84 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00785d !important; }

.bg-info {
  background-color: #74A3E1 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #4a87d8 !important; }

.bg-warning {
  background-color: #E1CD00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ae9f00 !important; }

.bg-danger {
  background-color: #E74C3C !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62c1a !important; }

.bg-light {
  background-color: #FFFFFF !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #000000 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-ccm-red {
  background-color: #E74C3C !important; }

a.bg-ccm-red:hover, a.bg-ccm-red:focus,
button.bg-ccm-red:hover,
button.bg-ccm-red:focus {
  background-color: #d62c1a !important; }

.bg-ccm-gray {
  background-color: #C4C4C4 !important; }

a.bg-ccm-gray:hover, a.bg-ccm-gray:focus,
button.bg-ccm-gray:hover,
button.bg-ccm-gray:focus {
  background-color: #ababab !important; }

.bg-ccm-oslo {
  background-color: #8D89A5 !important; }

a.bg-ccm-oslo:hover, a.bg-ccm-oslo:focus,
button.bg-ccm-oslo:hover,
button.bg-ccm-oslo:focus {
  background-color: #716d8e !important; }

.bg-ccm-navy {
  background-color: #2980B9 !important; }

a.bg-ccm-navy:hover, a.bg-ccm-navy:focus,
button.bg-ccm-navy:hover,
button.bg-ccm-navy:focus {
  background-color: #20638f !important; }

.bg-ccm-blue {
  background-color: #74A3E1 !important; }

a.bg-ccm-blue:hover, a.bg-ccm-blue:focus,
button.bg-ccm-blue:hover,
button.bg-ccm-blue:focus {
  background-color: #4a87d8 !important; }

.bg-ccm-green {
  background-color: #00AB84 !important; }

a.bg-ccm-green:hover, a.bg-ccm-green:focus,
button.bg-ccm-green:hover,
button.bg-ccm-green:focus {
  background-color: #00785d !important; }

.bg-ccm-yellow {
  background-color: #E1CD00 !important; }

a.bg-ccm-yellow:hover, a.bg-ccm-yellow:focus,
button.bg-ccm-yellow:hover,
button.bg-ccm-yellow:focus {
  background-color: #ae9f00 !important; }

.bg-ccm-orange {
  background-color: #E67E22 !important; }

a.bg-ccm-orange:hover, a.bg-ccm-orange:focus,
button.bg-ccm-orange:hover,
button.bg-ccm-orange:focus {
  background-color: #bf6516 !important; }

.bg-ccm-bismark {
  background-color: #4A6784 !important; }

a.bg-ccm-bismark:hover, a.bg-ccm-bismark:focus,
button.bg-ccm-bismark:hover,
button.bg-ccm-bismark:focus {
  background-color: #384e63 !important; }

.bg-ccm-bali-hai {
  background-color: #8095B1 !important; }

a.bg-ccm-bali-hai:hover, a.bg-ccm-bali-hai:focus,
button.bg-ccm-bali-hai:hover,
button.bg-ccm-bali-hai:focus {
  background-color: #617b9d !important; }

.bg-ccm-bluewood {
  background-color: #2C3E50 !important; }

a.bg-ccm-bluewood:hover, a.bg-ccm-bluewood:focus,
button.bg-ccm-bluewood:hover,
button.bg-ccm-bluewood:focus {
  background-color: #1a252f !important; }

.bg-ccm-lipstick {
  background-color: #C6017D !important; }

a.bg-ccm-lipstick:hover, a.bg-ccm-lipstick:focus,
button.bg-ccm-lipstick:hover,
button.bg-ccm-lipstick:focus {
  background-color: #93015d !important; }

.bg-ccm-light-gray {
  background-color: #F2F2F2 !important; }

a.bg-ccm-light-gray:hover, a.bg-ccm-light-gray:focus,
button.bg-ccm-light-gray:hover,
button.bg-ccm-light-gray:focus {
  background-color: #d9d9d9 !important; }

.bg-ccm-bright-red {
  background-color: #FF0000 !important; }

a.bg-ccm-bright-red:hover, a.bg-ccm-bright-red:focus,
button.bg-ccm-bright-red:hover,
button.bg-ccm-bright-red:focus {
  background-color: #cc0000 !important; }

.bg-ccm-inactive {
  background-color: #929292 !important; }

a.bg-ccm-inactive:hover, a.bg-ccm-inactive:focus,
button.bg-ccm-inactive:hover,
button.bg-ccm-inactive:focus {
  background-color: #797979 !important; }

.bg-ccm-boulder {
  background-color: #767676 !important; }

a.bg-ccm-boulder:hover, a.bg-ccm-boulder:focus,
button.bg-ccm-boulder:hover,
button.bg-ccm-boulder:focus {
  background-color: #5d5d5d !important; }

.bg-ccm-darker-yellow {
  background-color: #BBAA03 !important; }

a.bg-ccm-darker-yellow:hover, a.bg-ccm-darker-yellow:focus,
button.bg-ccm-darker-yellow:hover,
button.bg-ccm-darker-yellow:focus {
  background-color: #897c02 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom, .patient-health-factors .health-factor {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0, .patient-health-factors .health-factor:last-child {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #74A3E1 !important; }

.border-secondary {
  border-color: #FFFFFF !important; }

.border-success {
  border-color: #00AB84 !important; }

.border-info {
  border-color: #74A3E1 !important; }

.border-warning {
  border-color: #E1CD00 !important; }

.border-danger {
  border-color: #E74C3C !important; }

.border-light {
  border-color: #FFFFFF !important; }

.border-dark {
  border-color: #000000 !important; }

.border-ccm-red {
  border-color: #E74C3C !important; }

.border-ccm-gray {
  border-color: #C4C4C4 !important; }

.border-ccm-oslo {
  border-color: #8D89A5 !important; }

.border-ccm-navy {
  border-color: #2980B9 !important; }

.border-ccm-blue {
  border-color: #74A3E1 !important; }

.border-ccm-green {
  border-color: #00AB84 !important; }

.border-ccm-yellow {
  border-color: #E1CD00 !important; }

.border-ccm-orange {
  border-color: #E67E22 !important; }

.border-ccm-bismark {
  border-color: #4A6784 !important; }

.border-ccm-bali-hai {
  border-color: #8095B1 !important; }

.border-ccm-bluewood {
  border-color: #2C3E50 !important; }

.border-ccm-lipstick {
  border-color: #C6017D !important; }

.border-ccm-light-gray {
  border-color: #F2F2F2 !important; }

.border-ccm-bright-red {
  border-color: #FF0000 !important; }

.border-ccm-inactive {
  border-color: #929292 !important; }

.border-ccm-boulder {
  border-color: #767676 !important; }

.border-ccm-darker-yellow {
  border-color: #BBAA03 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #74A3E1 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #3579d3 !important; }

.text-secondary {
  color: #FFFFFF !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-success {
  color: #00AB84 !important; }

a.text-success:hover, a.text-success:focus {
  color: #005f49 !important; }

.text-info {
  color: #74A3E1 !important; }

a.text-info:hover, a.text-info:focus {
  color: #3579d3 !important; }

.text-warning {
  color: #E1CD00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #958700 !important; }

.text-danger {
  color: #E74C3C !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bf2718 !important; }

.text-light {
  color: #FFFFFF !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #000000 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-ccm-red {
  color: #E74C3C !important; }

a.text-ccm-red:hover, a.text-ccm-red:focus {
  color: #bf2718 !important; }

.text-ccm-gray {
  color: #C4C4C4 !important; }

a.text-ccm-gray:hover, a.text-ccm-gray:focus {
  color: #9e9e9e !important; }

.text-ccm-oslo {
  color: #8D89A5 !important; }

a.text-ccm-oslo:hover, a.text-ccm-oslo:focus {
  color: #666280 !important; }

.text-ccm-navy {
  color: #2980B9 !important; }

a.text-ccm-navy:hover, a.text-ccm-navy:focus {
  color: #1b557a !important; }

.text-ccm-blue {
  color: #74A3E1 !important; }

a.text-ccm-blue:hover, a.text-ccm-blue:focus {
  color: #3579d3 !important; }

.text-ccm-green {
  color: #00AB84 !important; }

a.text-ccm-green:hover, a.text-ccm-green:focus {
  color: #005f49 !important; }

.text-ccm-yellow {
  color: #E1CD00 !important; }

a.text-ccm-yellow:hover, a.text-ccm-yellow:focus {
  color: #958700 !important; }

.text-ccm-orange {
  color: #E67E22 !important; }

a.text-ccm-orange:hover, a.text-ccm-orange:focus {
  color: #a85913 !important; }

.text-ccm-bismark {
  color: #4A6784 !important; }

a.text-ccm-bismark:hover, a.text-ccm-bismark:focus {
  color: #2f4153 !important; }

.text-ccm-bali-hai {
  color: #8095B1 !important; }

a.text-ccm-bali-hai:hover, a.text-ccm-bali-hai:focus {
  color: #576e8e !important; }

.text-ccm-bluewood {
  color: #2C3E50 !important; }

a.text-ccm-bluewood:hover, a.text-ccm-bluewood:focus {
  color: #11181f !important; }

.text-ccm-lipstick {
  color: #C6017D !important; }

a.text-ccm-lipstick:hover, a.text-ccm-lipstick:focus {
  color: #7a014d !important; }

.text-ccm-light-gray {
  color: #F2F2F2 !important; }

a.text-ccm-light-gray:hover, a.text-ccm-light-gray:focus {
  color: #cccccc !important; }

.text-ccm-bright-red {
  color: #FF0000 !important; }

a.text-ccm-bright-red:hover, a.text-ccm-bright-red:focus {
  color: #b30000 !important; }

.text-ccm-inactive {
  color: #929292 !important; }

a.text-ccm-inactive:hover, a.text-ccm-inactive:focus {
  color: #6c6c6c !important; }

.text-ccm-boulder {
  color: #767676 !important; }

a.text-ccm-boulder:hover, a.text-ccm-boulder:focus {
  color: #505050 !important; }

.text-ccm-darker-yellow {
  color: #BBAA03 !important; }

a.text-ccm-darker-yellow:hover, a.text-ccm-darker-yellow:focus {
  color: #706602 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* ------------------------
    Utility
------------------------- */
/* ------------------------
    #. Utility Mixins
------------------------- */
/* ------------------------
    #. Utility Classes
------------------------- */
.d-flex-center, .ccm-status-legend .nav .nav-item .nav-link, .ccm-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.d-flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.white-space-pre-line {
  white-space: pre-line; }

.text-underline {
  text-decoration: underline; }

.span-url {
  cursor: pointer; }

/* ------------------------
    #. Confetti Utility Classes
------------------------- */
[class|="confetti"] {
  position: absolute; }

.confetti-0 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 54%;
  opacity: 0.82544;
  transform: rotate(81.25836deg);
  animation: drop-confetti-0 5s 3s infinite linear; }

@keyframes drop-confetti-0 {
  100% {
    top: 110%;
    left: 61%; } }

.confetti-1 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 100%;
  opacity: 0.62104;
  transform: rotate(311.86739deg);
  animation: drop-confetti-1 9s 2s infinite linear; }

@keyframes drop-confetti-1 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-2 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 6%;
  opacity: 1.0394;
  transform: rotate(335.24793deg);
  animation: drop-confetti-2 5s 3s infinite linear; }

@keyframes drop-confetti-2 {
  100% {
    top: 110%;
    left: 12%; } }

.confetti-3 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 72%;
  opacity: 0.70162;
  transform: rotate(357.83701deg);
  animation: drop-confetti-3 5s 3s infinite linear; }

@keyframes drop-confetti-3 {
  100% {
    top: 110%;
    left: 76%; } }

.confetti-4 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 62%;
  opacity: 0.67644;
  transform: rotate(315.71985deg);
  animation: drop-confetti-4 7s 4s infinite linear; }

@keyframes drop-confetti-4 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-5 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 46%;
  opacity: 1.09599;
  transform: rotate(275.63082deg);
  animation: drop-confetti-5 7s 2s infinite linear; }

@keyframes drop-confetti-5 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-6 {
  width: 7px;
  height: 2.8px;
  background-color: #000000;
  top: -10%;
  left: 56%;
  opacity: 0.86314;
  transform: rotate(232.91524deg);
  animation: drop-confetti-6 7s 5s infinite linear; }

@keyframes drop-confetti-6 {
  100% {
    top: 110%;
    left: 59%; } }

.confetti-7 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 19%;
  opacity: 1.22839;
  transform: rotate(58.99332deg);
  animation: drop-confetti-7 7s 1s infinite linear; }

@keyframes drop-confetti-7 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-8 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 8%;
  opacity: 0.57536;
  transform: rotate(109.80991deg);
  animation: drop-confetti-8 5s 3s infinite linear; }

@keyframes drop-confetti-8 {
  100% {
    top: 110%;
    left: 20%; } }

.confetti-9 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 93%;
  opacity: 0.55235;
  transform: rotate(173.50041deg);
  animation: drop-confetti-9 9s 5s infinite linear; }

@keyframes drop-confetti-9 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-10 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 23%;
  opacity: 0.56078;
  transform: rotate(117.46663deg);
  animation: drop-confetti-10 6s 2s infinite linear; }

@keyframes drop-confetti-10 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-11 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 95%;
  opacity: 0.9939;
  transform: rotate(120.13903deg);
  animation: drop-confetti-11 7s 2s infinite linear; }

@keyframes drop-confetti-11 {
  100% {
    top: 110%;
    left: 106%; } }

.confetti-12 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 66%;
  opacity: 1.04612;
  transform: rotate(141.12268deg);
  animation: drop-confetti-12 9s 3s infinite linear; }

@keyframes drop-confetti-12 {
  100% {
    top: 110%;
    left: 75%; } }

.confetti-13 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 30%;
  opacity: 1.03204;
  transform: rotate(250.03307deg);
  animation: drop-confetti-13 5s 3s infinite linear; }

@keyframes drop-confetti-13 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-14 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 80%;
  opacity: 0.55291;
  transform: rotate(11.88479deg);
  animation: drop-confetti-14 7s 2s infinite linear; }

@keyframes drop-confetti-14 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-15 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 29%;
  opacity: 0.76657;
  transform: rotate(307.14654deg);
  animation: drop-confetti-15 9s 5s infinite linear; }

@keyframes drop-confetti-15 {
  100% {
    top: 110%;
    left: 39%; } }

.confetti-16 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 10%;
  opacity: 1.10247;
  transform: rotate(143.38773deg);
  animation: drop-confetti-16 8s 3s infinite linear; }

@keyframes drop-confetti-16 {
  100% {
    top: 110%;
    left: 11%; } }

.confetti-17 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 39%;
  opacity: 0.53269;
  transform: rotate(11.74862deg);
  animation: drop-confetti-17 8s 5s infinite linear; }

@keyframes drop-confetti-17 {
  100% {
    top: 110%;
    left: 44%; } }

.confetti-18 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 3%;
  opacity: 1.31311;
  transform: rotate(58.10682deg);
  animation: drop-confetti-18 8s 5s infinite linear; }

@keyframes drop-confetti-18 {
  100% {
    top: 110%;
    left: 4%; } }

.confetti-19 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 97%;
  opacity: 1.48689;
  transform: rotate(209.81014deg);
  animation: drop-confetti-19 6s 1s infinite linear; }

@keyframes drop-confetti-19 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-20 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 34%;
  opacity: 1.21052;
  transform: rotate(18.55909deg);
  animation: drop-confetti-20 7s 5s infinite linear; }

@keyframes drop-confetti-20 {
  100% {
    top: 110%;
    left: 42%; } }

.confetti-21 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 34%;
  opacity: 0.91567;
  transform: rotate(88.97324deg);
  animation: drop-confetti-21 6s 2s infinite linear; }

@keyframes drop-confetti-21 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-22 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 1%;
  opacity: 0.54499;
  transform: rotate(5.44251deg);
  animation: drop-confetti-22 6s 4s infinite linear; }

@keyframes drop-confetti-22 {
  100% {
    top: 110%;
    left: 2%; } }

.confetti-23 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 9%;
  opacity: 0.92617;
  transform: rotate(172.71977deg);
  animation: drop-confetti-23 5s 1s infinite linear; }

@keyframes drop-confetti-23 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-24 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 73%;
  opacity: 0.50377;
  transform: rotate(300.54599deg);
  animation: drop-confetti-24 6s 4s infinite linear; }

@keyframes drop-confetti-24 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-25 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 39%;
  opacity: 0.85678;
  transform: rotate(159.95512deg);
  animation: drop-confetti-25 9s 5s infinite linear; }

@keyframes drop-confetti-25 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-26 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 98%;
  opacity: 0.53968;
  transform: rotate(291.71495deg);
  animation: drop-confetti-26 6s 1s infinite linear; }

@keyframes drop-confetti-26 {
  100% {
    top: 110%;
    left: 101%; } }

.confetti-27 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 59%;
  opacity: 0.65085;
  transform: rotate(43.57195deg);
  animation: drop-confetti-27 5s 1s infinite linear; }

@keyframes drop-confetti-27 {
  100% {
    top: 110%;
    left: 72%; } }

.confetti-28 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 41%;
  opacity: 0.64899;
  transform: rotate(55.29866deg);
  animation: drop-confetti-28 9s 1s infinite linear; }

@keyframes drop-confetti-28 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-29 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 43%;
  opacity: 0.84248;
  transform: rotate(359.41345deg);
  animation: drop-confetti-29 8s 1s infinite linear; }

@keyframes drop-confetti-29 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-30 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 79%;
  opacity: 1.48206;
  transform: rotate(23.90986deg);
  animation: drop-confetti-30 5s 5s infinite linear; }

@keyframes drop-confetti-30 {
  100% {
    top: 110%;
    left: 92%; } }

.confetti-31 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 65%;
  opacity: 1.46262;
  transform: rotate(91.40645deg);
  animation: drop-confetti-31 6s 5s infinite linear; }

@keyframes drop-confetti-31 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-32 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 58%;
  opacity: 1.40098;
  transform: rotate(331.72393deg);
  animation: drop-confetti-32 7s 2s infinite linear; }

@keyframes drop-confetti-32 {
  100% {
    top: 110%;
    left: 62%; } }

.confetti-33 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 92%;
  opacity: 0.86682;
  transform: rotate(152.06084deg);
  animation: drop-confetti-33 8s 5s infinite linear; }

@keyframes drop-confetti-33 {
  100% {
    top: 110%;
    left: 95%; } }

.confetti-34 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 11%;
  opacity: 1.1942;
  transform: rotate(54.08786deg);
  animation: drop-confetti-34 9s 2s infinite linear; }

@keyframes drop-confetti-34 {
  100% {
    top: 110%;
    left: 13%; } }

.confetti-35 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 5%;
  opacity: 0.52836;
  transform: rotate(167.66108deg);
  animation: drop-confetti-35 8s 4s infinite linear; }

@keyframes drop-confetti-35 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-36 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 17%;
  opacity: 1.40776;
  transform: rotate(29.18983deg);
  animation: drop-confetti-36 6s 3s infinite linear; }

@keyframes drop-confetti-36 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-37 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 67%;
  opacity: 0.64154;
  transform: rotate(251.44745deg);
  animation: drop-confetti-37 5s 3s infinite linear; }

@keyframes drop-confetti-37 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-38 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 48%;
  opacity: 0.6682;
  transform: rotate(213.66531deg);
  animation: drop-confetti-38 5s 4s infinite linear; }

@keyframes drop-confetti-38 {
  100% {
    top: 110%;
    left: 61%; } }

.confetti-39 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 83%;
  opacity: 0.7016;
  transform: rotate(258.91691deg);
  animation: drop-confetti-39 7s 2s infinite linear; }

@keyframes drop-confetti-39 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-40 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 88%;
  opacity: 0.88494;
  transform: rotate(108.19367deg);
  animation: drop-confetti-40 7s 2s infinite linear; }

@keyframes drop-confetti-40 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-41 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 14%;
  opacity: 0.62913;
  transform: rotate(344.91029deg);
  animation: drop-confetti-41 5s 4s infinite linear; }

@keyframes drop-confetti-41 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-42 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 78%;
  opacity: 0.97371;
  transform: rotate(116.64702deg);
  animation: drop-confetti-42 9s 1s infinite linear; }

@keyframes drop-confetti-42 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-43 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 1%;
  opacity: 1.39572;
  transform: rotate(320.12701deg);
  animation: drop-confetti-43 6s 1s infinite linear; }

@keyframes drop-confetti-43 {
  100% {
    top: 110%;
    left: 6%; } }

.confetti-44 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 83%;
  opacity: 0.63326;
  transform: rotate(354.68023deg);
  animation: drop-confetti-44 5s 3s infinite linear; }

@keyframes drop-confetti-44 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-45 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 31%;
  opacity: 1.20486;
  transform: rotate(182.35574deg);
  animation: drop-confetti-45 9s 5s infinite linear; }

@keyframes drop-confetti-45 {
  100% {
    top: 110%;
    left: 35%; } }

.confetti-46 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 91%;
  opacity: 0.84751;
  transform: rotate(224.51361deg);
  animation: drop-confetti-46 5s 3s infinite linear; }

@keyframes drop-confetti-46 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-47 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 48%;
  opacity: 1.48565;
  transform: rotate(99.11784deg);
  animation: drop-confetti-47 5s 3s infinite linear; }

@keyframes drop-confetti-47 {
  100% {
    top: 110%;
    left: 56%; } }

.confetti-48 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 79%;
  opacity: 0.77924;
  transform: rotate(122.34932deg);
  animation: drop-confetti-48 7s 3s infinite linear; }

@keyframes drop-confetti-48 {
  100% {
    top: 110%;
    left: 86%; } }

.confetti-49 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 2%;
  opacity: 0.73934;
  transform: rotate(113.98863deg);
  animation: drop-confetti-49 5s 5s infinite linear; }

@keyframes drop-confetti-49 {
  100% {
    top: 110%;
    left: 15%; } }

.confetti-50 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 33%;
  opacity: 0.57479;
  transform: rotate(358.06559deg);
  animation: drop-confetti-50 5s 2s infinite linear; }

@keyframes drop-confetti-50 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-51 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 16%;
  opacity: 0.71947;
  transform: rotate(209.41698deg);
  animation: drop-confetti-51 9s 5s infinite linear; }

@keyframes drop-confetti-51 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-52 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 10%;
  opacity: 0.67064;
  transform: rotate(213.15905deg);
  animation: drop-confetti-52 6s 3s infinite linear; }

@keyframes drop-confetti-52 {
  100% {
    top: 110%;
    left: 13%; } }

.confetti-53 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 37%;
  opacity: 1.48422;
  transform: rotate(107.65372deg);
  animation: drop-confetti-53 7s 2s infinite linear; }

@keyframes drop-confetti-53 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-54 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 57%;
  opacity: 0.5901;
  transform: rotate(287.68836deg);
  animation: drop-confetti-54 7s 5s infinite linear; }

@keyframes drop-confetti-54 {
  100% {
    top: 110%;
    left: 67%; } }

.confetti-55 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 72%;
  opacity: 0.55143;
  transform: rotate(179.92413deg);
  animation: drop-confetti-55 9s 1s infinite linear; }

@keyframes drop-confetti-55 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-56 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 50%;
  opacity: 1.33557;
  transform: rotate(142.10565deg);
  animation: drop-confetti-56 7s 1s infinite linear; }

@keyframes drop-confetti-56 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-57 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 73%;
  opacity: 0.83819;
  transform: rotate(184.56782deg);
  animation: drop-confetti-57 9s 4s infinite linear; }

@keyframes drop-confetti-57 {
  100% {
    top: 110%;
    left: 79%; } }

.confetti-58 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 1%;
  opacity: 0.66632;
  transform: rotate(23.97379deg);
  animation: drop-confetti-58 7s 2s infinite linear; }

@keyframes drop-confetti-58 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-59 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 31%;
  opacity: 0.6619;
  transform: rotate(332.3364deg);
  animation: drop-confetti-59 6s 4s infinite linear; }

@keyframes drop-confetti-59 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-60 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 13%;
  opacity: 0.88253;
  transform: rotate(297.77136deg);
  animation: drop-confetti-60 8s 2s infinite linear; }

@keyframes drop-confetti-60 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-61 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 23%;
  opacity: 1.24664;
  transform: rotate(26.69371deg);
  animation: drop-confetti-61 6s 5s infinite linear; }

@keyframes drop-confetti-61 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-62 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 37%;
  opacity: 1.33007;
  transform: rotate(340.48157deg);
  animation: drop-confetti-62 5s 2s infinite linear; }

@keyframes drop-confetti-62 {
  100% {
    top: 110%;
    left: 49%; } }

.confetti-63 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 25%;
  opacity: 1.42574;
  transform: rotate(32.08958deg);
  animation: drop-confetti-63 8s 1s infinite linear; }

@keyframes drop-confetti-63 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-64 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 99%;
  opacity: 1.38481;
  transform: rotate(86.45524deg);
  animation: drop-confetti-64 5s 1s infinite linear; }

@keyframes drop-confetti-64 {
  100% {
    top: 110%;
    left: 108%; } }

.confetti-65 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 88%;
  opacity: 1.23433;
  transform: rotate(35.5635deg);
  animation: drop-confetti-65 9s 1s infinite linear; }

@keyframes drop-confetti-65 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-66 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 85%;
  opacity: 1.34878;
  transform: rotate(132.6097deg);
  animation: drop-confetti-66 6s 3s infinite linear; }

@keyframes drop-confetti-66 {
  100% {
    top: 110%;
    left: 98%; } }

.confetti-67 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 79%;
  opacity: 0.84991;
  transform: rotate(157.83545deg);
  animation: drop-confetti-67 5s 4s infinite linear; }

@keyframes drop-confetti-67 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-68 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 10%;
  opacity: 1.33302;
  transform: rotate(209.90306deg);
  animation: drop-confetti-68 6s 2s infinite linear; }

@keyframes drop-confetti-68 {
  100% {
    top: 110%;
    left: 25%; } }

.confetti-69 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 27%;
  opacity: 1.27281;
  transform: rotate(301.31403deg);
  animation: drop-confetti-69 9s 2s infinite linear; }

@keyframes drop-confetti-69 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-70 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 25%;
  opacity: 0.85218;
  transform: rotate(325.90396deg);
  animation: drop-confetti-70 5s 2s infinite linear; }

@keyframes drop-confetti-70 {
  100% {
    top: 110%;
    left: 36%; } }

.confetti-71 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 73%;
  opacity: 1.07087;
  transform: rotate(306.49177deg);
  animation: drop-confetti-71 9s 2s infinite linear; }

@keyframes drop-confetti-71 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-72 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 16%;
  opacity: 0.68754;
  transform: rotate(287.49277deg);
  animation: drop-confetti-72 9s 1s infinite linear; }

@keyframes drop-confetti-72 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-73 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 73%;
  opacity: 1.00543;
  transform: rotate(62.7562deg);
  animation: drop-confetti-73 7s 3s infinite linear; }

@keyframes drop-confetti-73 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-74 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 14%;
  opacity: 0.95947;
  transform: rotate(31.50819deg);
  animation: drop-confetti-74 6s 3s infinite linear; }

@keyframes drop-confetti-74 {
  100% {
    top: 110%;
    left: 29%; } }

.confetti-75 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 11%;
  opacity: 1.47404;
  transform: rotate(138.06606deg);
  animation: drop-confetti-75 8s 5s infinite linear; }

@keyframes drop-confetti-75 {
  100% {
    top: 110%;
    left: 14%; } }

.confetti-76 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 84%;
  opacity: 1.01035;
  transform: rotate(162.00394deg);
  animation: drop-confetti-76 9s 1s infinite linear; }

@keyframes drop-confetti-76 {
  100% {
    top: 110%;
    left: 98%; } }

.confetti-77 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 90%;
  opacity: 1.11987;
  transform: rotate(2.85805deg);
  animation: drop-confetti-77 7s 5s infinite linear; }

@keyframes drop-confetti-77 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-78 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 18%;
  opacity: 0.84714;
  transform: rotate(223.45943deg);
  animation: drop-confetti-78 5s 5s infinite linear; }

@keyframes drop-confetti-78 {
  100% {
    top: 110%;
    left: 20%; } }

.confetti-79 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 68%;
  opacity: 1.18096;
  transform: rotate(193.82102deg);
  animation: drop-confetti-79 9s 2s infinite linear; }

@keyframes drop-confetti-79 {
  100% {
    top: 110%;
    left: 73%; } }

.confetti-80 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 35%;
  opacity: 1.1253;
  transform: rotate(301.34786deg);
  animation: drop-confetti-80 9s 3s infinite linear; }

@keyframes drop-confetti-80 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-81 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 1%;
  opacity: 1.34389;
  transform: rotate(319.98565deg);
  animation: drop-confetti-81 7s 5s infinite linear; }

@keyframes drop-confetti-81 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-82 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 16%;
  opacity: 1.38998;
  transform: rotate(341.05474deg);
  animation: drop-confetti-82 7s 1s infinite linear; }

@keyframes drop-confetti-82 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-83 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 98%;
  opacity: 0.64471;
  transform: rotate(78.28078deg);
  animation: drop-confetti-83 6s 2s infinite linear; }

@keyframes drop-confetti-83 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-84 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 27%;
  opacity: 1.46003;
  transform: rotate(213.27343deg);
  animation: drop-confetti-84 6s 4s infinite linear; }

@keyframes drop-confetti-84 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-85 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 67%;
  opacity: 1.32044;
  transform: rotate(154.8842deg);
  animation: drop-confetti-85 9s 4s infinite linear; }

@keyframes drop-confetti-85 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-86 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 32%;
  opacity: 1.32483;
  transform: rotate(85.54089deg);
  animation: drop-confetti-86 7s 3s infinite linear; }

@keyframes drop-confetti-86 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-87 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 14%;
  opacity: 0.86912;
  transform: rotate(97.49197deg);
  animation: drop-confetti-87 7s 2s infinite linear; }

@keyframes drop-confetti-87 {
  100% {
    top: 110%;
    left: 21%; } }

.confetti-88 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 80%;
  opacity: 1.15664;
  transform: rotate(2.57979deg);
  animation: drop-confetti-88 7s 1s infinite linear; }

@keyframes drop-confetti-88 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-89 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 49%;
  opacity: 1.46951;
  transform: rotate(292.17062deg);
  animation: drop-confetti-89 9s 1s infinite linear; }

@keyframes drop-confetti-89 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-90 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 37%;
  opacity: 0.85042;
  transform: rotate(42.64614deg);
  animation: drop-confetti-90 9s 1s infinite linear; }

@keyframes drop-confetti-90 {
  100% {
    top: 110%;
    left: 48%; } }

.confetti-91 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 6%;
  opacity: 0.58445;
  transform: rotate(290.85236deg);
  animation: drop-confetti-91 6s 3s infinite linear; }

@keyframes drop-confetti-91 {
  100% {
    top: 110%;
    left: 17%; } }

.confetti-92 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 9%;
  opacity: 0.65581;
  transform: rotate(304.44634deg);
  animation: drop-confetti-92 9s 1s infinite linear; }

@keyframes drop-confetti-92 {
  100% {
    top: 110%;
    left: 13%; } }

.confetti-93 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 67%;
  opacity: 1.21417;
  transform: rotate(103.51877deg);
  animation: drop-confetti-93 9s 1s infinite linear; }

@keyframes drop-confetti-93 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-94 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 15%;
  opacity: 0.93753;
  transform: rotate(313.7848deg);
  animation: drop-confetti-94 5s 3s infinite linear; }

@keyframes drop-confetti-94 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-95 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 27%;
  opacity: 0.70545;
  transform: rotate(111.15989deg);
  animation: drop-confetti-95 7s 2s infinite linear; }

@keyframes drop-confetti-95 {
  100% {
    top: 110%;
    left: 41%; } }

.confetti-96 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 15%;
  opacity: 0.69102;
  transform: rotate(112.59012deg);
  animation: drop-confetti-96 9s 1s infinite linear; }

@keyframes drop-confetti-96 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-97 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 57%;
  opacity: 1.20037;
  transform: rotate(262.47079deg);
  animation: drop-confetti-97 5s 2s infinite linear; }

@keyframes drop-confetti-97 {
  100% {
    top: 110%;
    left: 64%; } }

.confetti-98 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 20%;
  opacity: 0.86424;
  transform: rotate(190.55167deg);
  animation: drop-confetti-98 6s 1s infinite linear; }

@keyframes drop-confetti-98 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-99 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 62%;
  opacity: 0.85679;
  transform: rotate(285.87126deg);
  animation: drop-confetti-99 9s 4s infinite linear; }

@keyframes drop-confetti-99 {
  100% {
    top: 110%;
    left: 71%; } }

.confetti-100 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 4%;
  opacity: 1.26434;
  transform: rotate(254.76944deg);
  animation: drop-confetti-100 8s 1s infinite linear; }

@keyframes drop-confetti-100 {
  100% {
    top: 110%;
    left: 13%; } }

.confetti-101 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 90%;
  opacity: 0.94205;
  transform: rotate(329.29108deg);
  animation: drop-confetti-101 5s 1s infinite linear; }

@keyframes drop-confetti-101 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-102 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 68%;
  opacity: 1.23085;
  transform: rotate(50.43306deg);
  animation: drop-confetti-102 8s 1s infinite linear; }

@keyframes drop-confetti-102 {
  100% {
    top: 110%;
    left: 80%; } }

.confetti-103 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 75%;
  opacity: 0.62865;
  transform: rotate(320.76436deg);
  animation: drop-confetti-103 5s 1s infinite linear; }

@keyframes drop-confetti-103 {
  100% {
    top: 110%;
    left: 80%; } }

.confetti-104 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 63%;
  opacity: 1.35602;
  transform: rotate(335.90391deg);
  animation: drop-confetti-104 8s 2s infinite linear; }

@keyframes drop-confetti-104 {
  100% {
    top: 110%;
    left: 68%; } }

.confetti-105 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 85%;
  opacity: 1.43689;
  transform: rotate(214.12298deg);
  animation: drop-confetti-105 5s 2s infinite linear; }

@keyframes drop-confetti-105 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-106 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 76%;
  opacity: 0.51117;
  transform: rotate(127.92354deg);
  animation: drop-confetti-106 7s 2s infinite linear; }

@keyframes drop-confetti-106 {
  100% {
    top: 110%;
    left: 86%; } }

.confetti-107 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 86%;
  opacity: 1.38729;
  transform: rotate(63.11055deg);
  animation: drop-confetti-107 9s 1s infinite linear; }

@keyframes drop-confetti-107 {
  100% {
    top: 110%;
    left: 98%; } }

.confetti-108 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 63%;
  opacity: 1.12039;
  transform: rotate(70.88266deg);
  animation: drop-confetti-108 7s 5s infinite linear; }

@keyframes drop-confetti-108 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-109 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 38%;
  opacity: 1.03213;
  transform: rotate(228.11794deg);
  animation: drop-confetti-109 9s 5s infinite linear; }

@keyframes drop-confetti-109 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-110 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 4%;
  opacity: 1.28186;
  transform: rotate(59.35995deg);
  animation: drop-confetti-110 9s 3s infinite linear; }

@keyframes drop-confetti-110 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-111 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 27%;
  opacity: 0.98099;
  transform: rotate(106.77456deg);
  animation: drop-confetti-111 5s 5s infinite linear; }

@keyframes drop-confetti-111 {
  100% {
    top: 110%;
    left: 32%; } }

.confetti-112 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 95%;
  opacity: 1.02666;
  transform: rotate(6.74837deg);
  animation: drop-confetti-112 8s 5s infinite linear; }

@keyframes drop-confetti-112 {
  100% {
    top: 110%;
    left: 110%; } }

.confetti-113 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 85%;
  opacity: 0.84403;
  transform: rotate(328.28345deg);
  animation: drop-confetti-113 7s 3s infinite linear; }

@keyframes drop-confetti-113 {
  100% {
    top: 110%;
    left: 92%; } }

.confetti-114 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 42%;
  opacity: 0.89369;
  transform: rotate(290.12476deg);
  animation: drop-confetti-114 6s 3s infinite linear; }

@keyframes drop-confetti-114 {
  100% {
    top: 110%;
    left: 57%; } }

.confetti-115 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 93%;
  opacity: 1.20061;
  transform: rotate(158.3223deg);
  animation: drop-confetti-115 9s 1s infinite linear; }

@keyframes drop-confetti-115 {
  100% {
    top: 110%;
    left: 108%; } }

.confetti-116 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 8%;
  opacity: 1.42091;
  transform: rotate(295.84601deg);
  animation: drop-confetti-116 8s 5s infinite linear; }

@keyframes drop-confetti-116 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-117 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 21%;
  opacity: 1.01743;
  transform: rotate(245.78373deg);
  animation: drop-confetti-117 6s 5s infinite linear; }

@keyframes drop-confetti-117 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-118 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 90%;
  opacity: 1.24623;
  transform: rotate(243.80444deg);
  animation: drop-confetti-118 8s 1s infinite linear; }

@keyframes drop-confetti-118 {
  100% {
    top: 110%;
    left: 97%; } }

.confetti-119 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 34%;
  opacity: 0.58515;
  transform: rotate(90.58494deg);
  animation: drop-confetti-119 7s 4s infinite linear; }

@keyframes drop-confetti-119 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-120 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 34%;
  opacity: 0.716;
  transform: rotate(3.37794deg);
  animation: drop-confetti-120 9s 2s infinite linear; }

@keyframes drop-confetti-120 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-121 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 39%;
  opacity: 0.71871;
  transform: rotate(30.70032deg);
  animation: drop-confetti-121 8s 1s infinite linear; }

@keyframes drop-confetti-121 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-122 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 25%;
  opacity: 0.71707;
  transform: rotate(182.62884deg);
  animation: drop-confetti-122 8s 4s infinite linear; }

@keyframes drop-confetti-122 {
  100% {
    top: 110%;
    left: 36%; } }

.confetti-123 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 94%;
  opacity: 1.43476;
  transform: rotate(301.85944deg);
  animation: drop-confetti-123 9s 1s infinite linear; }

@keyframes drop-confetti-123 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-124 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 24%;
  opacity: 1.21895;
  transform: rotate(152.31609deg);
  animation: drop-confetti-124 5s 1s infinite linear; }

@keyframes drop-confetti-124 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-125 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 79%;
  opacity: 1.10093;
  transform: rotate(214.07108deg);
  animation: drop-confetti-125 6s 5s infinite linear; }

@keyframes drop-confetti-125 {
  100% {
    top: 110%;
    left: 91%; } }

.confetti-126 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 3%;
  opacity: 1.31914;
  transform: rotate(279.18844deg);
  animation: drop-confetti-126 8s 4s infinite linear; }

@keyframes drop-confetti-126 {
  100% {
    top: 110%;
    left: 5%; } }

.confetti-127 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 64%;
  opacity: 0.6519;
  transform: rotate(55.72227deg);
  animation: drop-confetti-127 7s 2s infinite linear; }

@keyframes drop-confetti-127 {
  100% {
    top: 110%;
    left: 76%; } }

.confetti-128 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 83%;
  opacity: 1.49562;
  transform: rotate(314.12771deg);
  animation: drop-confetti-128 8s 2s infinite linear; }

@keyframes drop-confetti-128 {
  100% {
    top: 110%;
    left: 85%; } }

.confetti-129 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 65%;
  opacity: 0.99782;
  transform: rotate(73.5068deg);
  animation: drop-confetti-129 6s 3s infinite linear; }

@keyframes drop-confetti-129 {
  100% {
    top: 110%;
    left: 79%; } }

.confetti-130 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 39%;
  opacity: 0.73183;
  transform: rotate(221.21632deg);
  animation: drop-confetti-130 5s 2s infinite linear; }

@keyframes drop-confetti-130 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-131 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 95%;
  opacity: 0.87126;
  transform: rotate(342.07141deg);
  animation: drop-confetti-131 8s 1s infinite linear; }

@keyframes drop-confetti-131 {
  100% {
    top: 110%;
    left: 103%; } }

.confetti-132 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 20%;
  opacity: 0.65066;
  transform: rotate(79.13173deg);
  animation: drop-confetti-132 9s 3s infinite linear; }

@keyframes drop-confetti-132 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-133 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 94%;
  opacity: 0.63121;
  transform: rotate(44.68202deg);
  animation: drop-confetti-133 6s 4s infinite linear; }

@keyframes drop-confetti-133 {
  100% {
    top: 110%;
    left: 101%; } }

.confetti-134 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 100%;
  opacity: 0.77925;
  transform: rotate(109.00825deg);
  animation: drop-confetti-134 5s 2s infinite linear; }

@keyframes drop-confetti-134 {
  100% {
    top: 110%;
    left: 108%; } }

.confetti-135 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 34%;
  opacity: 1.26332;
  transform: rotate(354.57105deg);
  animation: drop-confetti-135 6s 5s infinite linear; }

@keyframes drop-confetti-135 {
  100% {
    top: 110%;
    left: 45%; } }

.confetti-136 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 19%;
  opacity: 0.91495;
  transform: rotate(255.06151deg);
  animation: drop-confetti-136 5s 2s infinite linear; }

@keyframes drop-confetti-136 {
  100% {
    top: 110%;
    left: 27%; } }

.confetti-137 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 24%;
  opacity: 1.23629;
  transform: rotate(327.0748deg);
  animation: drop-confetti-137 8s 2s infinite linear; }

@keyframes drop-confetti-137 {
  100% {
    top: 110%;
    left: 29%; } }

.confetti-138 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 70%;
  opacity: 0.66794;
  transform: rotate(249.67584deg);
  animation: drop-confetti-138 8s 3s infinite linear; }

@keyframes drop-confetti-138 {
  100% {
    top: 110%;
    left: 85%; } }

.confetti-139 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 69%;
  opacity: 0.50779;
  transform: rotate(203.78598deg);
  animation: drop-confetti-139 8s 1s infinite linear; }

@keyframes drop-confetti-139 {
  100% {
    top: 110%;
    left: 72%; } }

.confetti-140 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 43%;
  opacity: 0.56058;
  transform: rotate(318.31463deg);
  animation: drop-confetti-140 6s 4s infinite linear; }

@keyframes drop-confetti-140 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-141 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 68%;
  opacity: 1.01857;
  transform: rotate(177.92271deg);
  animation: drop-confetti-141 8s 3s infinite linear; }

@keyframes drop-confetti-141 {
  100% {
    top: 110%;
    left: 75%; } }

.confetti-142 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 3%;
  opacity: 1.03005;
  transform: rotate(46.65499deg);
  animation: drop-confetti-142 8s 3s infinite linear; }

@keyframes drop-confetti-142 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-143 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 24%;
  opacity: 0.92133;
  transform: rotate(319.83542deg);
  animation: drop-confetti-143 6s 1s infinite linear; }

@keyframes drop-confetti-143 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-144 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 60%;
  opacity: 0.59035;
  transform: rotate(190.63878deg);
  animation: drop-confetti-144 5s 4s infinite linear; }

@keyframes drop-confetti-144 {
  100% {
    top: 110%;
    left: 70%; } }

.confetti-145 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 6%;
  opacity: 1.36412;
  transform: rotate(10.00545deg);
  animation: drop-confetti-145 8s 1s infinite linear; }

@keyframes drop-confetti-145 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-146 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 77%;
  opacity: 1.34798;
  transform: rotate(76.02294deg);
  animation: drop-confetti-146 9s 3s infinite linear; }

@keyframes drop-confetti-146 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-147 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 73%;
  opacity: 1.10799;
  transform: rotate(6.15883deg);
  animation: drop-confetti-147 9s 1s infinite linear; }

@keyframes drop-confetti-147 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-148 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 98%;
  opacity: 0.8522;
  transform: rotate(241.96494deg);
  animation: drop-confetti-148 8s 2s infinite linear; }

@keyframes drop-confetti-148 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-149 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 7%;
  opacity: 0.96132;
  transform: rotate(330.01762deg);
  animation: drop-confetti-149 6s 3s infinite linear; }

@keyframes drop-confetti-149 {
  100% {
    top: 110%;
    left: 9%; } }

.confetti-150 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 43%;
  opacity: 0.903;
  transform: rotate(27.3131deg);
  animation: drop-confetti-150 8s 1s infinite linear; }

@keyframes drop-confetti-150 {
  100% {
    top: 110%;
    left: 48%; } }

.confetti-151 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 46%;
  opacity: 1.18528;
  transform: rotate(216.75314deg);
  animation: drop-confetti-151 7s 5s infinite linear; }

@keyframes drop-confetti-151 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-152 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 90%;
  opacity: 1.30938;
  transform: rotate(117.6876deg);
  animation: drop-confetti-152 7s 2s infinite linear; }

@keyframes drop-confetti-152 {
  100% {
    top: 110%;
    left: 102%; } }

.confetti-153 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 95%;
  opacity: 0.58301;
  transform: rotate(183.88155deg);
  animation: drop-confetti-153 5s 4s infinite linear; }

@keyframes drop-confetti-153 {
  100% {
    top: 110%;
    left: 98%; } }

.confetti-154 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 93%;
  opacity: 1.12978;
  transform: rotate(18.87643deg);
  animation: drop-confetti-154 5s 5s infinite linear; }

@keyframes drop-confetti-154 {
  100% {
    top: 110%;
    left: 108%; } }

.confetti-155 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 67%;
  opacity: 1.37181;
  transform: rotate(64.33886deg);
  animation: drop-confetti-155 8s 1s infinite linear; }

@keyframes drop-confetti-155 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-156 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 19%;
  opacity: 0.53244;
  transform: rotate(151.56585deg);
  animation: drop-confetti-156 5s 5s infinite linear; }

@keyframes drop-confetti-156 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-157 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 44%;
  opacity: 0.62284;
  transform: rotate(91.15436deg);
  animation: drop-confetti-157 9s 2s infinite linear; }

@keyframes drop-confetti-157 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-158 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 49%;
  opacity: 1.41712;
  transform: rotate(301.55113deg);
  animation: drop-confetti-158 7s 2s infinite linear; }

@keyframes drop-confetti-158 {
  100% {
    top: 110%;
    left: 62%; } }

.confetti-159 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 87%;
  opacity: 0.93664;
  transform: rotate(163.02954deg);
  animation: drop-confetti-159 9s 5s infinite linear; }

@keyframes drop-confetti-159 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-160 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 85%;
  opacity: 0.58132;
  transform: rotate(89.57311deg);
  animation: drop-confetti-160 8s 1s infinite linear; }

@keyframes drop-confetti-160 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-161 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 65%;
  opacity: 1.40685;
  transform: rotate(78.45282deg);
  animation: drop-confetti-161 6s 5s infinite linear; }

@keyframes drop-confetti-161 {
  100% {
    top: 110%;
    left: 68%; } }

.confetti-162 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 66%;
  opacity: 0.8779;
  transform: rotate(359.70972deg);
  animation: drop-confetti-162 9s 4s infinite linear; }

@keyframes drop-confetti-162 {
  100% {
    top: 110%;
    left: 67%; } }

.confetti-163 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 18%;
  opacity: 1.15272;
  transform: rotate(60.44844deg);
  animation: drop-confetti-163 5s 4s infinite linear; }

@keyframes drop-confetti-163 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-164 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 27%;
  opacity: 1.40298;
  transform: rotate(204.27464deg);
  animation: drop-confetti-164 5s 2s infinite linear; }

@keyframes drop-confetti-164 {
  100% {
    top: 110%;
    left: 41%; } }

.confetti-165 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 64%;
  opacity: 0.73122;
  transform: rotate(330.83422deg);
  animation: drop-confetti-165 8s 5s infinite linear; }

@keyframes drop-confetti-165 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-166 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 57%;
  opacity: 1.34578;
  transform: rotate(161.88231deg);
  animation: drop-confetti-166 8s 3s infinite linear; }

@keyframes drop-confetti-166 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-167 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 27%;
  opacity: 1.24682;
  transform: rotate(132.84142deg);
  animation: drop-confetti-167 8s 4s infinite linear; }

@keyframes drop-confetti-167 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-168 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 11%;
  opacity: 1.08601;
  transform: rotate(283.88926deg);
  animation: drop-confetti-168 6s 3s infinite linear; }

@keyframes drop-confetti-168 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-169 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 64%;
  opacity: 0.71124;
  transform: rotate(99.41511deg);
  animation: drop-confetti-169 5s 2s infinite linear; }

@keyframes drop-confetti-169 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-170 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 69%;
  opacity: 1.262;
  transform: rotate(207.04029deg);
  animation: drop-confetti-170 9s 4s infinite linear; }

@keyframes drop-confetti-170 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-171 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 27%;
  opacity: 0.5853;
  transform: rotate(250.04738deg);
  animation: drop-confetti-171 9s 4s infinite linear; }

@keyframes drop-confetti-171 {
  100% {
    top: 110%;
    left: 29%; } }

.confetti-172 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 80%;
  opacity: 1.4679;
  transform: rotate(177.3076deg);
  animation: drop-confetti-172 5s 5s infinite linear; }

@keyframes drop-confetti-172 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-173 {
  width: 7px;
  height: 2.8px;
  background-color: #000000;
  top: -10%;
  left: 17%;
  opacity: 0.7545;
  transform: rotate(276.09992deg);
  animation: drop-confetti-173 6s 4s infinite linear; }

@keyframes drop-confetti-173 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-174 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 81%;
  opacity: 1.43166;
  transform: rotate(203.47074deg);
  animation: drop-confetti-174 5s 1s infinite linear; }

@keyframes drop-confetti-174 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-175 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 100%;
  opacity: 0.81419;
  transform: rotate(153.12262deg);
  animation: drop-confetti-175 6s 3s infinite linear; }

@keyframes drop-confetti-175 {
  100% {
    top: 110%;
    left: 113%; } }

.confetti-176 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 5%;
  opacity: 0.61378;
  transform: rotate(25.62371deg);
  animation: drop-confetti-176 8s 1s infinite linear; }

@keyframes drop-confetti-176 {
  100% {
    top: 110%;
    left: 10%; } }

.confetti-177 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 36%;
  opacity: 0.54592;
  transform: rotate(302.15485deg);
  animation: drop-confetti-177 7s 3s infinite linear; }

@keyframes drop-confetti-177 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-178 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 63%;
  opacity: 1.29754;
  transform: rotate(343.30419deg);
  animation: drop-confetti-178 7s 1s infinite linear; }

@keyframes drop-confetti-178 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-179 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 39%;
  opacity: 1.28555;
  transform: rotate(219.96169deg);
  animation: drop-confetti-179 8s 3s infinite linear; }

@keyframes drop-confetti-179 {
  100% {
    top: 110%;
    left: 41%; } }

.confetti-180 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 51%;
  opacity: 0.99348;
  transform: rotate(197.88497deg);
  animation: drop-confetti-180 8s 4s infinite linear; }

@keyframes drop-confetti-180 {
  100% {
    top: 110%;
    left: 56%; } }

.confetti-181 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 47%;
  opacity: 0.67966;
  transform: rotate(296.11436deg);
  animation: drop-confetti-181 7s 3s infinite linear; }

@keyframes drop-confetti-181 {
  100% {
    top: 110%;
    left: 61%; } }

.confetti-182 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 15%;
  opacity: 0.62782;
  transform: rotate(53.15192deg);
  animation: drop-confetti-182 7s 5s infinite linear; }

@keyframes drop-confetti-182 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-183 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 54%;
  opacity: 0.74758;
  transform: rotate(9.22342deg);
  animation: drop-confetti-183 7s 2s infinite linear; }

@keyframes drop-confetti-183 {
  100% {
    top: 110%;
    left: 68%; } }

.confetti-184 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 8%;
  opacity: 1.06595;
  transform: rotate(191.2631deg);
  animation: drop-confetti-184 6s 5s infinite linear; }

@keyframes drop-confetti-184 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-185 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 71%;
  opacity: 1.32043;
  transform: rotate(91.51919deg);
  animation: drop-confetti-185 5s 4s infinite linear; }

@keyframes drop-confetti-185 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-186 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 31%;
  opacity: 0.7385;
  transform: rotate(108.40453deg);
  animation: drop-confetti-186 7s 3s infinite linear; }

@keyframes drop-confetti-186 {
  100% {
    top: 110%;
    left: 42%; } }

.confetti-187 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 17%;
  opacity: 0.60489;
  transform: rotate(203.9953deg);
  animation: drop-confetti-187 8s 5s infinite linear; }

@keyframes drop-confetti-187 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-188 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 81%;
  opacity: 1.15238;
  transform: rotate(42.22228deg);
  animation: drop-confetti-188 8s 4s infinite linear; }

@keyframes drop-confetti-188 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-189 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 64%;
  opacity: 1.41256;
  transform: rotate(233.1545deg);
  animation: drop-confetti-189 7s 3s infinite linear; }

@keyframes drop-confetti-189 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-190 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 25%;
  opacity: 0.80299;
  transform: rotate(77.39906deg);
  animation: drop-confetti-190 8s 5s infinite linear; }

@keyframes drop-confetti-190 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-191 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 87%;
  opacity: 1.12969;
  transform: rotate(50.84267deg);
  animation: drop-confetti-191 6s 4s infinite linear; }

@keyframes drop-confetti-191 {
  100% {
    top: 110%;
    left: 95%; } }

.confetti-192 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 89%;
  opacity: 1.10098;
  transform: rotate(23.32598deg);
  animation: drop-confetti-192 9s 5s infinite linear; }

@keyframes drop-confetti-192 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-193 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 49%;
  opacity: 1.2854;
  transform: rotate(5.22565deg);
  animation: drop-confetti-193 6s 1s infinite linear; }

@keyframes drop-confetti-193 {
  100% {
    top: 110%;
    left: 51%; } }

.confetti-194 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 3%;
  opacity: 1.1078;
  transform: rotate(97.17327deg);
  animation: drop-confetti-194 7s 3s infinite linear; }

@keyframes drop-confetti-194 {
  100% {
    top: 110%;
    left: 8%; } }

.confetti-195 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 99%;
  opacity: 0.87365;
  transform: rotate(191.47786deg);
  animation: drop-confetti-195 6s 2s infinite linear; }

@keyframes drop-confetti-195 {
  100% {
    top: 110%;
    left: 103%; } }

.confetti-196 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 89%;
  opacity: 1.10792;
  transform: rotate(215.72073deg);
  animation: drop-confetti-196 5s 3s infinite linear; }

@keyframes drop-confetti-196 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-197 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 17%;
  opacity: 0.67227;
  transform: rotate(13.36197deg);
  animation: drop-confetti-197 5s 4s infinite linear; }

@keyframes drop-confetti-197 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-198 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 51%;
  opacity: 0.884;
  transform: rotate(289.67925deg);
  animation: drop-confetti-198 6s 2s infinite linear; }

@keyframes drop-confetti-198 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-199 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 52%;
  opacity: 0.94261;
  transform: rotate(341.98771deg);
  animation: drop-confetti-199 5s 2s infinite linear; }

@keyframes drop-confetti-199 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-200 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 74%;
  opacity: 0.50316;
  transform: rotate(317.1675deg);
  animation: drop-confetti-200 7s 4s infinite linear; }

@keyframes drop-confetti-200 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-201 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 99%;
  opacity: 0.69761;
  transform: rotate(190.13778deg);
  animation: drop-confetti-201 9s 3s infinite linear; }

@keyframes drop-confetti-201 {
  100% {
    top: 110%;
    left: 102%; } }

.confetti-202 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 26%;
  opacity: 1.16048;
  transform: rotate(11.76664deg);
  animation: drop-confetti-202 8s 4s infinite linear; }

@keyframes drop-confetti-202 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-203 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 68%;
  opacity: 1.30087;
  transform: rotate(297.24663deg);
  animation: drop-confetti-203 9s 1s infinite linear; }

@keyframes drop-confetti-203 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-204 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 21%;
  opacity: 0.63299;
  transform: rotate(134.64012deg);
  animation: drop-confetti-204 6s 1s infinite linear; }

@keyframes drop-confetti-204 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-205 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 85%;
  opacity: 0.63127;
  transform: rotate(17.06994deg);
  animation: drop-confetti-205 8s 4s infinite linear; }

@keyframes drop-confetti-205 {
  100% {
    top: 110%;
    left: 97%; } }

.confetti-206 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 43%;
  opacity: 1.16379;
  transform: rotate(347.90102deg);
  animation: drop-confetti-206 9s 3s infinite linear; }

@keyframes drop-confetti-206 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-207 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 34%;
  opacity: 1.07203;
  transform: rotate(78.23403deg);
  animation: drop-confetti-207 9s 2s infinite linear; }

@keyframes drop-confetti-207 {
  100% {
    top: 110%;
    left: 48%; } }

.confetti-208 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 63%;
  opacity: 0.99979;
  transform: rotate(285.91229deg);
  animation: drop-confetti-208 8s 2s infinite linear; }

@keyframes drop-confetti-208 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-209 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 84%;
  opacity: 1.48093;
  transform: rotate(189.70506deg);
  animation: drop-confetti-209 7s 2s infinite linear; }

@keyframes drop-confetti-209 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-210 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 22%;
  opacity: 0.81468;
  transform: rotate(43.79905deg);
  animation: drop-confetti-210 9s 5s infinite linear; }

@keyframes drop-confetti-210 {
  100% {
    top: 110%;
    left: 27%; } }

.confetti-211 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 38%;
  opacity: 1.20884;
  transform: rotate(116.66427deg);
  animation: drop-confetti-211 6s 5s infinite linear; }

@keyframes drop-confetti-211 {
  100% {
    top: 110%;
    left: 48%; } }

.confetti-212 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 52%;
  opacity: 0.92566;
  transform: rotate(283.50587deg);
  animation: drop-confetti-212 7s 1s infinite linear; }

@keyframes drop-confetti-212 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-213 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 42%;
  opacity: 0.89987;
  transform: rotate(99.24917deg);
  animation: drop-confetti-213 6s 3s infinite linear; }

@keyframes drop-confetti-213 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-214 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 38%;
  opacity: 0.94472;
  transform: rotate(158.57879deg);
  animation: drop-confetti-214 9s 2s infinite linear; }

@keyframes drop-confetti-214 {
  100% {
    top: 110%;
    left: 39%; } }

.confetti-215 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 17%;
  opacity: 0.80475;
  transform: rotate(253.52869deg);
  animation: drop-confetti-215 5s 2s infinite linear; }

@keyframes drop-confetti-215 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-216 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 17%;
  opacity: 1.10809;
  transform: rotate(207.08968deg);
  animation: drop-confetti-216 5s 1s infinite linear; }

@keyframes drop-confetti-216 {
  100% {
    top: 110%;
    left: 25%; } }

.confetti-217 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 74%;
  opacity: 0.52454;
  transform: rotate(120.25773deg);
  animation: drop-confetti-217 7s 2s infinite linear; }

@keyframes drop-confetti-217 {
  100% {
    top: 110%;
    left: 80%; } }

.confetti-218 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 56%;
  opacity: 0.92313;
  transform: rotate(149.60498deg);
  animation: drop-confetti-218 9s 4s infinite linear; }

@keyframes drop-confetti-218 {
  100% {
    top: 110%;
    left: 70%; } }

.confetti-219 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 55%;
  opacity: 1.4759;
  transform: rotate(85.65344deg);
  animation: drop-confetti-219 9s 4s infinite linear; }

@keyframes drop-confetti-219 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-220 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 46%;
  opacity: 0.67055;
  transform: rotate(68.52033deg);
  animation: drop-confetti-220 7s 5s infinite linear; }

@keyframes drop-confetti-220 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-221 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 27%;
  opacity: 1.28994;
  transform: rotate(225.7291deg);
  animation: drop-confetti-221 6s 3s infinite linear; }

@keyframes drop-confetti-221 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-222 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 55%;
  opacity: 0.51566;
  transform: rotate(183.67547deg);
  animation: drop-confetti-222 9s 4s infinite linear; }

@keyframes drop-confetti-222 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-223 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 65%;
  opacity: 1.02576;
  transform: rotate(71.19501deg);
  animation: drop-confetti-223 8s 4s infinite linear; }

@keyframes drop-confetti-223 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-224 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 21%;
  opacity: 1.00456;
  transform: rotate(262.9149deg);
  animation: drop-confetti-224 6s 4s infinite linear; }

@keyframes drop-confetti-224 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-225 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 92%;
  opacity: 1.19772;
  transform: rotate(79.09006deg);
  animation: drop-confetti-225 9s 2s infinite linear; }

@keyframes drop-confetti-225 {
  100% {
    top: 110%;
    left: 106%; } }

.confetti-226 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 70%;
  opacity: 0.81274;
  transform: rotate(97.63517deg);
  animation: drop-confetti-226 8s 2s infinite linear; }

@keyframes drop-confetti-226 {
  100% {
    top: 110%;
    left: 75%; } }

.confetti-227 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 15%;
  opacity: 1.40642;
  transform: rotate(323.27912deg);
  animation: drop-confetti-227 5s 2s infinite linear; }

@keyframes drop-confetti-227 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-228 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 12%;
  opacity: 0.68749;
  transform: rotate(1.73532deg);
  animation: drop-confetti-228 6s 2s infinite linear; }

@keyframes drop-confetti-228 {
  100% {
    top: 110%;
    left: 21%; } }

.confetti-229 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 18%;
  opacity: 0.50761;
  transform: rotate(160.80351deg);
  animation: drop-confetti-229 5s 4s infinite linear; }

@keyframes drop-confetti-229 {
  100% {
    top: 110%;
    left: 20%; } }

.confetti-230 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 10%;
  opacity: 0.96357;
  transform: rotate(200.83841deg);
  animation: drop-confetti-230 6s 3s infinite linear; }

@keyframes drop-confetti-230 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-231 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 17%;
  opacity: 0.79784;
  transform: rotate(56.84817deg);
  animation: drop-confetti-231 8s 4s infinite linear; }

@keyframes drop-confetti-231 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-232 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 48%;
  opacity: 1.22103;
  transform: rotate(325.53959deg);
  animation: drop-confetti-232 5s 3s infinite linear; }

@keyframes drop-confetti-232 {
  100% {
    top: 110%;
    left: 58%; } }

.confetti-233 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 26%;
  opacity: 0.62013;
  transform: rotate(247.34736deg);
  animation: drop-confetti-233 5s 4s infinite linear; }

@keyframes drop-confetti-233 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-234 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 45%;
  opacity: 1.1495;
  transform: rotate(311.6159deg);
  animation: drop-confetti-234 7s 3s infinite linear; }

@keyframes drop-confetti-234 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-235 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 40%;
  opacity: 1.27943;
  transform: rotate(82.27372deg);
  animation: drop-confetti-235 9s 1s infinite linear; }

@keyframes drop-confetti-235 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-236 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 33%;
  opacity: 0.97339;
  transform: rotate(17.04044deg);
  animation: drop-confetti-236 6s 5s infinite linear; }

@keyframes drop-confetti-236 {
  100% {
    top: 110%;
    left: 39%; } }

.confetti-237 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 36%;
  opacity: 1.25537;
  transform: rotate(186.53581deg);
  animation: drop-confetti-237 9s 2s infinite linear; }

@keyframes drop-confetti-237 {
  100% {
    top: 110%;
    left: 44%; } }

.confetti-238 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 42%;
  opacity: 0.69583;
  transform: rotate(174.22635deg);
  animation: drop-confetti-238 9s 4s infinite linear; }

@keyframes drop-confetti-238 {
  100% {
    top: 110%;
    left: 55%; } }

.confetti-239 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 44%;
  opacity: 0.5715;
  transform: rotate(259.73792deg);
  animation: drop-confetti-239 9s 2s infinite linear; }

@keyframes drop-confetti-239 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-240 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 21%;
  opacity: 0.83405;
  transform: rotate(35.36313deg);
  animation: drop-confetti-240 5s 4s infinite linear; }

@keyframes drop-confetti-240 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-241 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 94%;
  opacity: 0.57442;
  transform: rotate(243.11839deg);
  animation: drop-confetti-241 7s 3s infinite linear; }

@keyframes drop-confetti-241 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-242 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 30%;
  opacity: 0.69098;
  transform: rotate(232.49714deg);
  animation: drop-confetti-242 5s 1s infinite linear; }

@keyframes drop-confetti-242 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-243 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 55%;
  opacity: 0.71407;
  transform: rotate(293.44003deg);
  animation: drop-confetti-243 7s 4s infinite linear; }

@keyframes drop-confetti-243 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-244 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 68%;
  opacity: 1.43944;
  transform: rotate(252.63037deg);
  animation: drop-confetti-244 6s 1s infinite linear; }

@keyframes drop-confetti-244 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-245 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 22%;
  opacity: 1.38192;
  transform: rotate(66.82947deg);
  animation: drop-confetti-245 7s 4s infinite linear; }

@keyframes drop-confetti-245 {
  100% {
    top: 110%;
    left: 36%; } }

.confetti-246 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 82%;
  opacity: 1.14457;
  transform: rotate(299.62856deg);
  animation: drop-confetti-246 7s 2s infinite linear; }

@keyframes drop-confetti-246 {
  100% {
    top: 110%;
    left: 90%; } }

.confetti-247 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 52%;
  opacity: 0.75863;
  transform: rotate(343.79983deg);
  animation: drop-confetti-247 6s 4s infinite linear; }

@keyframes drop-confetti-247 {
  100% {
    top: 110%;
    left: 63%; } }

.confetti-248 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 57%;
  opacity: 0.64913;
  transform: rotate(131.61656deg);
  animation: drop-confetti-248 5s 2s infinite linear; }

@keyframes drop-confetti-248 {
  100% {
    top: 110%;
    left: 67%; } }

.confetti-249 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 72%;
  opacity: 0.74867;
  transform: rotate(246.86232deg);
  animation: drop-confetti-249 6s 3s infinite linear; }

@keyframes drop-confetti-249 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-250 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 67%;
  opacity: 0.67214;
  transform: rotate(177.63317deg);
  animation: drop-confetti-250 6s 2s infinite linear; }

@keyframes drop-confetti-250 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-251 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 61%;
  opacity: 1.44714;
  transform: rotate(253.23636deg);
  animation: drop-confetti-251 9s 2s infinite linear; }

@keyframes drop-confetti-251 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-252 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 45%;
  opacity: 0.93842;
  transform: rotate(290.38462deg);
  animation: drop-confetti-252 5s 2s infinite linear; }

@keyframes drop-confetti-252 {
  100% {
    top: 110%;
    left: 51%; } }

.confetti-253 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 38%;
  opacity: 1.1961;
  transform: rotate(117.99976deg);
  animation: drop-confetti-253 7s 5s infinite linear; }

@keyframes drop-confetti-253 {
  100% {
    top: 110%;
    left: 39%; } }

.confetti-254 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 43%;
  opacity: 1.20817;
  transform: rotate(42.86254deg);
  animation: drop-confetti-254 8s 5s infinite linear; }

@keyframes drop-confetti-254 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-255 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 11%;
  opacity: 1.35446;
  transform: rotate(259.78721deg);
  animation: drop-confetti-255 9s 3s infinite linear; }

@keyframes drop-confetti-255 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-256 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 42%;
  opacity: 0.5255;
  transform: rotate(328.66223deg);
  animation: drop-confetti-256 6s 1s infinite linear; }

@keyframes drop-confetti-256 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-257 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 71%;
  opacity: 0.96547;
  transform: rotate(53.20512deg);
  animation: drop-confetti-257 7s 2s infinite linear; }

@keyframes drop-confetti-257 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-258 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 42%;
  opacity: 0.74837;
  transform: rotate(283.09868deg);
  animation: drop-confetti-258 9s 5s infinite linear; }

@keyframes drop-confetti-258 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-259 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 52%;
  opacity: 1.13246;
  transform: rotate(250.78852deg);
  animation: drop-confetti-259 5s 2s infinite linear; }

@keyframes drop-confetti-259 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-260 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 96%;
  opacity: 1.09618;
  transform: rotate(64.84354deg);
  animation: drop-confetti-260 8s 4s infinite linear; }

@keyframes drop-confetti-260 {
  100% {
    top: 110%;
    left: 101%; } }

.confetti-261 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 97%;
  opacity: 1.45643;
  transform: rotate(132.60247deg);
  animation: drop-confetti-261 8s 1s infinite linear; }

@keyframes drop-confetti-261 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-262 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 99%;
  opacity: 0.53432;
  transform: rotate(186.36982deg);
  animation: drop-confetti-262 6s 5s infinite linear; }

@keyframes drop-confetti-262 {
  100% {
    top: 110%;
    left: 111%; } }

.confetti-263 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 46%;
  opacity: 1.01404;
  transform: rotate(253.32244deg);
  animation: drop-confetti-263 6s 2s infinite linear; }

@keyframes drop-confetti-263 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-264 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 86%;
  opacity: 0.64081;
  transform: rotate(300.34637deg);
  animation: drop-confetti-264 7s 2s infinite linear; }

@keyframes drop-confetti-264 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-265 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 39%;
  opacity: 1.39178;
  transform: rotate(254.02178deg);
  animation: drop-confetti-265 7s 4s infinite linear; }

@keyframes drop-confetti-265 {
  100% {
    top: 110%;
    left: 52%; } }

.confetti-266 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 45%;
  opacity: 0.89917;
  transform: rotate(258.65721deg);
  animation: drop-confetti-266 7s 1s infinite linear; }

@keyframes drop-confetti-266 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-267 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 3%;
  opacity: 0.7099;
  transform: rotate(78.61836deg);
  animation: drop-confetti-267 9s 5s infinite linear; }

@keyframes drop-confetti-267 {
  100% {
    top: 110%;
    left: 12%; } }

.confetti-268 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 20%;
  opacity: 1.34602;
  transform: rotate(54.87054deg);
  animation: drop-confetti-268 7s 3s infinite linear; }

@keyframes drop-confetti-268 {
  100% {
    top: 110%;
    left: 21%; } }

.confetti-269 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 49%;
  opacity: 1.19572;
  transform: rotate(182.53026deg);
  animation: drop-confetti-269 6s 4s infinite linear; }

@keyframes drop-confetti-269 {
  100% {
    top: 110%;
    left: 51%; } }

.confetti-270 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 64%;
  opacity: 0.57365;
  transform: rotate(34.56486deg);
  animation: drop-confetti-270 7s 5s infinite linear; }

@keyframes drop-confetti-270 {
  100% {
    top: 110%;
    left: 70%; } }

.confetti-271 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 69%;
  opacity: 0.50866;
  transform: rotate(348.27414deg);
  animation: drop-confetti-271 5s 2s infinite linear; }

@keyframes drop-confetti-271 {
  100% {
    top: 110%;
    left: 81%; } }

.confetti-272 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 76%;
  opacity: 1.23218;
  transform: rotate(48.92455deg);
  animation: drop-confetti-272 9s 1s infinite linear; }

@keyframes drop-confetti-272 {
  100% {
    top: 110%;
    left: 85%; } }

.confetti-273 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 97%;
  opacity: 1.31896;
  transform: rotate(199.73303deg);
  animation: drop-confetti-273 5s 4s infinite linear; }

@keyframes drop-confetti-273 {
  100% {
    top: 110%;
    left: 102%; } }

.confetti-274 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 37%;
  opacity: 1.44206;
  transform: rotate(102.16761deg);
  animation: drop-confetti-274 6s 1s infinite linear; }

@keyframes drop-confetti-274 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-275 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 75%;
  opacity: 1.3036;
  transform: rotate(227.57961deg);
  animation: drop-confetti-275 7s 2s infinite linear; }

@keyframes drop-confetti-275 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-276 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 43%;
  opacity: 1.31573;
  transform: rotate(212.24529deg);
  animation: drop-confetti-276 5s 1s infinite linear; }

@keyframes drop-confetti-276 {
  100% {
    top: 110%;
    left: 55%; } }

.confetti-277 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 2%;
  opacity: 1.22779;
  transform: rotate(241.22227deg);
  animation: drop-confetti-277 6s 4s infinite linear; }

@keyframes drop-confetti-277 {
  100% {
    top: 110%;
    left: 17%; } }

.confetti-278 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 62%;
  opacity: 0.93065;
  transform: rotate(164.68892deg);
  animation: drop-confetti-278 9s 4s infinite linear; }

@keyframes drop-confetti-278 {
  100% {
    top: 110%;
    left: 63%; } }

.confetti-279 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 18%;
  opacity: 0.74345;
  transform: rotate(34.23672deg);
  animation: drop-confetti-279 8s 1s infinite linear; }

@keyframes drop-confetti-279 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-280 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 28%;
  opacity: 0.51398;
  transform: rotate(171.01519deg);
  animation: drop-confetti-280 9s 5s infinite linear; }

@keyframes drop-confetti-280 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-281 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 39%;
  opacity: 1.01395;
  transform: rotate(234.22793deg);
  animation: drop-confetti-281 8s 3s infinite linear; }

@keyframes drop-confetti-281 {
  100% {
    top: 110%;
    left: 49%; } }

.confetti-282 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 14%;
  opacity: 0.5749;
  transform: rotate(322.4691deg);
  animation: drop-confetti-282 8s 4s infinite linear; }

@keyframes drop-confetti-282 {
  100% {
    top: 110%;
    left: 21%; } }

.confetti-283 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 96%;
  opacity: 0.55295;
  transform: rotate(132.49736deg);
  animation: drop-confetti-283 6s 4s infinite linear; }

@keyframes drop-confetti-283 {
  100% {
    top: 110%;
    left: 103%; } }

.confetti-284 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 45%;
  opacity: 0.75163;
  transform: rotate(194.62777deg);
  animation: drop-confetti-284 7s 2s infinite linear; }

@keyframes drop-confetti-284 {
  100% {
    top: 110%;
    left: 49%; } }

.confetti-285 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 3%;
  opacity: 0.78626;
  transform: rotate(158.21641deg);
  animation: drop-confetti-285 6s 4s infinite linear; }

@keyframes drop-confetti-285 {
  100% {
    top: 110%;
    left: 6%; } }

.confetti-286 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 18%;
  opacity: 1.0717;
  transform: rotate(61.16812deg);
  animation: drop-confetti-286 7s 5s infinite linear; }

@keyframes drop-confetti-286 {
  100% {
    top: 110%;
    left: 20%; } }

.confetti-287 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 51%;
  opacity: 1.22333;
  transform: rotate(200.33307deg);
  animation: drop-confetti-287 8s 1s infinite linear; }

@keyframes drop-confetti-287 {
  100% {
    top: 110%;
    left: 64%; } }

.confetti-288 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 76%;
  opacity: 0.84539;
  transform: rotate(297.63573deg);
  animation: drop-confetti-288 5s 5s infinite linear; }

@keyframes drop-confetti-288 {
  100% {
    top: 110%;
    left: 81%; } }

.confetti-289 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 8%;
  opacity: 0.51738;
  transform: rotate(55.46769deg);
  animation: drop-confetti-289 7s 3s infinite linear; }

@keyframes drop-confetti-289 {
  100% {
    top: 110%;
    left: 14%; } }

.confetti-290 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 20%;
  opacity: 1.43342;
  transform: rotate(151.12841deg);
  animation: drop-confetti-290 5s 3s infinite linear; }

@keyframes drop-confetti-290 {
  100% {
    top: 110%;
    left: 32%; } }

.confetti-291 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 4%;
  opacity: 1.0452;
  transform: rotate(308.14211deg);
  animation: drop-confetti-291 5s 4s infinite linear; }

@keyframes drop-confetti-291 {
  100% {
    top: 110%;
    left: 11%; } }

.confetti-292 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 99%;
  opacity: 1.02047;
  transform: rotate(76.58187deg);
  animation: drop-confetti-292 8s 3s infinite linear; }

@keyframes drop-confetti-292 {
  100% {
    top: 110%;
    left: 102%; } }

.confetti-293 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 23%;
  opacity: 1.27746;
  transform: rotate(355.98109deg);
  animation: drop-confetti-293 7s 5s infinite linear; }

@keyframes drop-confetti-293 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-294 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 50%;
  opacity: 1.32745;
  transform: rotate(327.52deg);
  animation: drop-confetti-294 9s 5s infinite linear; }

@keyframes drop-confetti-294 {
  100% {
    top: 110%;
    left: 62%; } }

.confetti-295 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 42%;
  opacity: 0.79763;
  transform: rotate(353.58677deg);
  animation: drop-confetti-295 6s 1s infinite linear; }

@keyframes drop-confetti-295 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-296 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 1%;
  opacity: 1.08093;
  transform: rotate(143.55121deg);
  animation: drop-confetti-296 7s 4s infinite linear; }

@keyframes drop-confetti-296 {
  100% {
    top: 110%;
    left: 7%; } }

.confetti-297 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 62%;
  opacity: 1.10144;
  transform: rotate(245.67835deg);
  animation: drop-confetti-297 5s 3s infinite linear; }

@keyframes drop-confetti-297 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-298 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 70%;
  opacity: 1.00113;
  transform: rotate(296.548deg);
  animation: drop-confetti-298 8s 1s infinite linear; }

@keyframes drop-confetti-298 {
  100% {
    top: 110%;
    left: 72%; } }

.confetti-299 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 67%;
  opacity: 0.81502;
  transform: rotate(255.06039deg);
  animation: drop-confetti-299 9s 5s infinite linear; }

@keyframes drop-confetti-299 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-300 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 72%;
  opacity: 0.73705;
  transform: rotate(346.10243deg);
  animation: drop-confetti-300 5s 1s infinite linear; }

@keyframes drop-confetti-300 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-301 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 76%;
  opacity: 0.70339;
  transform: rotate(88.11702deg);
  animation: drop-confetti-301 8s 3s infinite linear; }

@keyframes drop-confetti-301 {
  100% {
    top: 110%;
    left: 89%; } }

.confetti-302 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 66%;
  opacity: 1.28195;
  transform: rotate(118.63903deg);
  animation: drop-confetti-302 6s 1s infinite linear; }

@keyframes drop-confetti-302 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-303 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 98%;
  opacity: 1.33592;
  transform: rotate(230.98678deg);
  animation: drop-confetti-303 5s 3s infinite linear; }

@keyframes drop-confetti-303 {
  100% {
    top: 110%;
    left: 103%; } }

.confetti-304 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 84%;
  opacity: 1.23369;
  transform: rotate(252.56966deg);
  animation: drop-confetti-304 8s 4s infinite linear; }

@keyframes drop-confetti-304 {
  100% {
    top: 110%;
    left: 91%; } }

.confetti-305 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 12%;
  opacity: 1.44588;
  transform: rotate(17.60134deg);
  animation: drop-confetti-305 8s 3s infinite linear; }

@keyframes drop-confetti-305 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-306 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 49%;
  opacity: 1.23622;
  transform: rotate(344.15766deg);
  animation: drop-confetti-306 8s 3s infinite linear; }

@keyframes drop-confetti-306 {
  100% {
    top: 110%;
    left: 59%; } }

.confetti-307 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 80%;
  opacity: 1.06842;
  transform: rotate(345.46279deg);
  animation: drop-confetti-307 7s 2s infinite linear; }

@keyframes drop-confetti-307 {
  100% {
    top: 110%;
    left: 95%; } }

.confetti-308 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 18%;
  opacity: 1.14465;
  transform: rotate(221.09242deg);
  animation: drop-confetti-308 8s 5s infinite linear; }

@keyframes drop-confetti-308 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-309 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 41%;
  opacity: 1.03904;
  transform: rotate(54.26296deg);
  animation: drop-confetti-309 8s 3s infinite linear; }

@keyframes drop-confetti-309 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-310 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 82%;
  opacity: 0.6665;
  transform: rotate(120.47189deg);
  animation: drop-confetti-310 6s 3s infinite linear; }

@keyframes drop-confetti-310 {
  100% {
    top: 110%;
    left: 92%; } }

.confetti-311 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 100%;
  opacity: 0.6527;
  transform: rotate(11.03203deg);
  animation: drop-confetti-311 6s 1s infinite linear; }

@keyframes drop-confetti-311 {
  100% {
    top: 110%;
    left: 115%; } }

.confetti-312 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 37%;
  opacity: 0.96773;
  transform: rotate(310.48566deg);
  animation: drop-confetti-312 5s 4s infinite linear; }

@keyframes drop-confetti-312 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-313 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 3%;
  opacity: 0.52701;
  transform: rotate(63.8346deg);
  animation: drop-confetti-313 7s 2s infinite linear; }

@keyframes drop-confetti-313 {
  100% {
    top: 110%;
    left: 8%; } }

.confetti-314 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 2%;
  opacity: 0.66225;
  transform: rotate(237.93817deg);
  animation: drop-confetti-314 8s 2s infinite linear; }

@keyframes drop-confetti-314 {
  100% {
    top: 110%;
    left: 5%; } }

.confetti-315 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 58%;
  opacity: 0.83402;
  transform: rotate(230.4044deg);
  animation: drop-confetti-315 9s 1s infinite linear; }

@keyframes drop-confetti-315 {
  100% {
    top: 110%;
    left: 72%; } }

.confetti-316 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 50%;
  opacity: 0.81224;
  transform: rotate(217.18774deg);
  animation: drop-confetti-316 6s 3s infinite linear; }

@keyframes drop-confetti-316 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-317 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 15%;
  opacity: 0.91902;
  transform: rotate(185.96044deg);
  animation: drop-confetti-317 5s 4s infinite linear; }

@keyframes drop-confetti-317 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-318 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 100%;
  opacity: 1.38182;
  transform: rotate(310.84379deg);
  animation: drop-confetti-318 9s 5s infinite linear; }

@keyframes drop-confetti-318 {
  100% {
    top: 110%;
    left: 102%; } }

.confetti-319 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 23%;
  opacity: 1.11815;
  transform: rotate(299.80098deg);
  animation: drop-confetti-319 8s 1s infinite linear; }

@keyframes drop-confetti-319 {
  100% {
    top: 110%;
    left: 27%; } }

.confetti-320 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 92%;
  opacity: 0.85293;
  transform: rotate(126.81361deg);
  animation: drop-confetti-320 7s 5s infinite linear; }

@keyframes drop-confetti-320 {
  100% {
    top: 110%;
    left: 99%; } }

.confetti-321 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 91%;
  opacity: 1.47833;
  transform: rotate(12.26585deg);
  animation: drop-confetti-321 5s 2s infinite linear; }

@keyframes drop-confetti-321 {
  100% {
    top: 110%;
    left: 100%; } }

.confetti-322 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 42%;
  opacity: 1.16532;
  transform: rotate(280.90372deg);
  animation: drop-confetti-322 9s 5s infinite linear; }

@keyframes drop-confetti-322 {
  100% {
    top: 110%;
    left: 48%; } }

.confetti-323 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 20%;
  opacity: 0.64354;
  transform: rotate(87.42545deg);
  animation: drop-confetti-323 9s 5s infinite linear; }

@keyframes drop-confetti-323 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-324 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 91%;
  opacity: 1.28429;
  transform: rotate(128.78691deg);
  animation: drop-confetti-324 6s 5s infinite linear; }

@keyframes drop-confetti-324 {
  100% {
    top: 110%;
    left: 95%; } }

.confetti-325 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 72%;
  opacity: 1.23258;
  transform: rotate(144.13542deg);
  animation: drop-confetti-325 9s 1s infinite linear; }

@keyframes drop-confetti-325 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-326 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 18%;
  opacity: 1.38911;
  transform: rotate(70.97945deg);
  animation: drop-confetti-326 9s 2s infinite linear; }

@keyframes drop-confetti-326 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-327 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 85%;
  opacity: 1.33412;
  transform: rotate(221.82764deg);
  animation: drop-confetti-327 9s 2s infinite linear; }

@keyframes drop-confetti-327 {
  100% {
    top: 110%;
    left: 89%; } }

.confetti-328 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 94%;
  opacity: 1.07946;
  transform: rotate(326.30155deg);
  animation: drop-confetti-328 7s 1s infinite linear; }

@keyframes drop-confetti-328 {
  100% {
    top: 110%;
    left: 108%; } }

.confetti-329 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 81%;
  opacity: 0.8718;
  transform: rotate(82.05345deg);
  animation: drop-confetti-329 6s 3s infinite linear; }

@keyframes drop-confetti-329 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-330 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 45%;
  opacity: 1.47061;
  transform: rotate(225.41121deg);
  animation: drop-confetti-330 6s 4s infinite linear; }

@keyframes drop-confetti-330 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-331 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 75%;
  opacity: 0.97841;
  transform: rotate(19.86083deg);
  animation: drop-confetti-331 5s 1s infinite linear; }

@keyframes drop-confetti-331 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-332 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 20%;
  opacity: 0.89854;
  transform: rotate(232.97031deg);
  animation: drop-confetti-332 5s 5s infinite linear; }

@keyframes drop-confetti-332 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-333 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 4%;
  opacity: 0.59186;
  transform: rotate(319.44086deg);
  animation: drop-confetti-333 7s 3s infinite linear; }

@keyframes drop-confetti-333 {
  100% {
    top: 110%;
    left: 5%; } }

.confetti-334 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 33%;
  opacity: 0.99886;
  transform: rotate(355.18121deg);
  animation: drop-confetti-334 8s 1s infinite linear; }

@keyframes drop-confetti-334 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-335 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 27%;
  opacity: 1.04066;
  transform: rotate(101.68682deg);
  animation: drop-confetti-335 7s 5s infinite linear; }

@keyframes drop-confetti-335 {
  100% {
    top: 110%;
    left: 32%; } }

.confetti-336 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 67%;
  opacity: 1.23575;
  transform: rotate(175.07097deg);
  animation: drop-confetti-336 9s 3s infinite linear; }

@keyframes drop-confetti-336 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-337 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 13%;
  opacity: 1.12786;
  transform: rotate(273.94206deg);
  animation: drop-confetti-337 5s 1s infinite linear; }

@keyframes drop-confetti-337 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-338 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 69%;
  opacity: 1.3878;
  transform: rotate(231.52402deg);
  animation: drop-confetti-338 7s 5s infinite linear; }

@keyframes drop-confetti-338 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-339 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 89%;
  opacity: 0.8795;
  transform: rotate(227.50835deg);
  animation: drop-confetti-339 6s 3s infinite linear; }

@keyframes drop-confetti-339 {
  100% {
    top: 110%;
    left: 98%; } }

.confetti-340 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 31%;
  opacity: 0.7761;
  transform: rotate(269.44173deg);
  animation: drop-confetti-340 6s 4s infinite linear; }

@keyframes drop-confetti-340 {
  100% {
    top: 110%;
    left: 43%; } }

.confetti-341 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 59%;
  opacity: 1.08814;
  transform: rotate(19.05347deg);
  animation: drop-confetti-341 9s 1s infinite linear; }

@keyframes drop-confetti-341 {
  100% {
    top: 110%;
    left: 61%; } }

.confetti-342 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 29%;
  opacity: 0.52778;
  transform: rotate(274.47587deg);
  animation: drop-confetti-342 8s 5s infinite linear; }

@keyframes drop-confetti-342 {
  100% {
    top: 110%;
    left: 31%; } }

.confetti-343 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 68%;
  opacity: 0.9808;
  transform: rotate(329.11001deg);
  animation: drop-confetti-343 5s 1s infinite linear; }

@keyframes drop-confetti-343 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-344 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 54%;
  opacity: 1.21544;
  transform: rotate(28.68402deg);
  animation: drop-confetti-344 9s 2s infinite linear; }

@keyframes drop-confetti-344 {
  100% {
    top: 110%;
    left: 64%; } }

.confetti-345 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 73%;
  opacity: 1.08653;
  transform: rotate(37.80725deg);
  animation: drop-confetti-345 9s 1s infinite linear; }

@keyframes drop-confetti-345 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-346 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 54%;
  opacity: 1.28432;
  transform: rotate(207.68214deg);
  animation: drop-confetti-346 9s 3s infinite linear; }

@keyframes drop-confetti-346 {
  100% {
    top: 110%;
    left: 56%; } }

.confetti-347 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 9%;
  opacity: 0.5861;
  transform: rotate(206.62458deg);
  animation: drop-confetti-347 7s 4s infinite linear; }

@keyframes drop-confetti-347 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-348 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 35%;
  opacity: 1.48069;
  transform: rotate(101.4342deg);
  animation: drop-confetti-348 7s 3s infinite linear; }

@keyframes drop-confetti-348 {
  100% {
    top: 110%;
    left: 41%; } }

.confetti-349 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 59%;
  opacity: 1.26727;
  transform: rotate(341.93942deg);
  animation: drop-confetti-349 8s 4s infinite linear; }

@keyframes drop-confetti-349 {
  100% {
    top: 110%;
    left: 62%; } }

.confetti-350 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 57%;
  opacity: 0.89983;
  transform: rotate(131.8078deg);
  animation: drop-confetti-350 9s 5s infinite linear; }

@keyframes drop-confetti-350 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-351 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 29%;
  opacity: 1.11129;
  transform: rotate(90.71011deg);
  animation: drop-confetti-351 9s 1s infinite linear; }

@keyframes drop-confetti-351 {
  100% {
    top: 110%;
    left: 43%; } }

.confetti-352 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 44%;
  opacity: 0.69337;
  transform: rotate(184.22883deg);
  animation: drop-confetti-352 8s 1s infinite linear; }

@keyframes drop-confetti-352 {
  100% {
    top: 110%;
    left: 57%; } }

.confetti-353 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 10%;
  opacity: 1.30866;
  transform: rotate(111.22174deg);
  animation: drop-confetti-353 9s 1s infinite linear; }

@keyframes drop-confetti-353 {
  100% {
    top: 110%;
    left: 20%; } }

.confetti-354 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 97%;
  opacity: 0.90704;
  transform: rotate(263.92824deg);
  animation: drop-confetti-354 9s 3s infinite linear; }

@keyframes drop-confetti-354 {
  100% {
    top: 110%;
    left: 101%; } }

.confetti-355 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 58%;
  opacity: 0.55252;
  transform: rotate(1.54088deg);
  animation: drop-confetti-355 7s 1s infinite linear; }

@keyframes drop-confetti-355 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-356 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 91%;
  opacity: 0.91998;
  transform: rotate(217.88906deg);
  animation: drop-confetti-356 6s 4s infinite linear; }

@keyframes drop-confetti-356 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-357 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 79%;
  opacity: 0.77714;
  transform: rotate(52.77851deg);
  animation: drop-confetti-357 8s 5s infinite linear; }

@keyframes drop-confetti-357 {
  100% {
    top: 110%;
    left: 86%; } }

.confetti-358 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 16%;
  opacity: 1.42193;
  transform: rotate(61.67408deg);
  animation: drop-confetti-358 8s 2s infinite linear; }

@keyframes drop-confetti-358 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-359 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 34%;
  opacity: 0.62118;
  transform: rotate(14.02481deg);
  animation: drop-confetti-359 6s 1s infinite linear; }

@keyframes drop-confetti-359 {
  100% {
    top: 110%;
    left: 49%; } }

.confetti-360 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 26%;
  opacity: 1.36242;
  transform: rotate(266.48356deg);
  animation: drop-confetti-360 5s 2s infinite linear; }

@keyframes drop-confetti-360 {
  100% {
    top: 110%;
    left: 38%; } }

.confetti-361 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 44%;
  opacity: 0.90294;
  transform: rotate(185.15994deg);
  animation: drop-confetti-361 6s 1s infinite linear; }

@keyframes drop-confetti-361 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-362 {
  width: 2px;
  height: 0.8px;
  background-color: #2980B9;
  top: -10%;
  left: 98%;
  opacity: 0.85297;
  transform: rotate(46.09446deg);
  animation: drop-confetti-362 5s 5s infinite linear; }

@keyframes drop-confetti-362 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-363 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 55%;
  opacity: 1.04336;
  transform: rotate(65.86638deg);
  animation: drop-confetti-363 7s 1s infinite linear; }

@keyframes drop-confetti-363 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-364 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 78%;
  opacity: 0.68143;
  transform: rotate(255.6106deg);
  animation: drop-confetti-364 7s 5s infinite linear; }

@keyframes drop-confetti-364 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-365 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 87%;
  opacity: 0.75984;
  transform: rotate(98.23417deg);
  animation: drop-confetti-365 5s 2s infinite linear; }

@keyframes drop-confetti-365 {
  100% {
    top: 110%;
    left: 100%; } }

.confetti-366 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 6%;
  opacity: 1.03297;
  transform: rotate(314.70246deg);
  animation: drop-confetti-366 7s 1s infinite linear; }

@keyframes drop-confetti-366 {
  100% {
    top: 110%;
    left: 12%; } }

.confetti-367 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 52%;
  opacity: 1.49502;
  transform: rotate(100.88406deg);
  animation: drop-confetti-367 7s 5s infinite linear; }

@keyframes drop-confetti-367 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-368 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 41%;
  opacity: 0.82675;
  transform: rotate(44.41672deg);
  animation: drop-confetti-368 9s 1s infinite linear; }

@keyframes drop-confetti-368 {
  100% {
    top: 110%;
    left: 46%; } }

.confetti-369 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 77%;
  opacity: 1.44977;
  transform: rotate(12.30272deg);
  animation: drop-confetti-369 9s 3s infinite linear; }

@keyframes drop-confetti-369 {
  100% {
    top: 110%;
    left: 83%; } }

.confetti-370 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 6%;
  opacity: 1.44257;
  transform: rotate(304.37413deg);
  animation: drop-confetti-370 8s 5s infinite linear; }

@keyframes drop-confetti-370 {
  100% {
    top: 110%;
    left: 21%; } }

.confetti-371 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 19%;
  opacity: 1.4267;
  transform: rotate(245.71631deg);
  animation: drop-confetti-371 5s 4s infinite linear; }

@keyframes drop-confetti-371 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-372 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 44%;
  opacity: 0.50205;
  transform: rotate(68.93487deg);
  animation: drop-confetti-372 6s 4s infinite linear; }

@keyframes drop-confetti-372 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-373 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 26%;
  opacity: 1.15506;
  transform: rotate(106.42709deg);
  animation: drop-confetti-373 9s 4s infinite linear; }

@keyframes drop-confetti-373 {
  100% {
    top: 110%;
    left: 41%; } }

.confetti-374 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 75%;
  opacity: 1.07164;
  transform: rotate(41.25423deg);
  animation: drop-confetti-374 5s 3s infinite linear; }

@keyframes drop-confetti-374 {
  100% {
    top: 110%;
    left: 86%; } }

.confetti-375 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 17%;
  opacity: 0.73992;
  transform: rotate(344.71501deg);
  animation: drop-confetti-375 8s 4s infinite linear; }

@keyframes drop-confetti-375 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-376 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 83%;
  opacity: 1.3395;
  transform: rotate(318.03552deg);
  animation: drop-confetti-376 6s 4s infinite linear; }

@keyframes drop-confetti-376 {
  100% {
    top: 110%;
    left: 93%; } }

.confetti-377 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 68%;
  opacity: 0.92182;
  transform: rotate(51.30845deg);
  animation: drop-confetti-377 6s 4s infinite linear; }

@keyframes drop-confetti-377 {
  100% {
    top: 110%;
    left: 79%; } }

.confetti-378 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 3%;
  opacity: 1.10299;
  transform: rotate(206.35188deg);
  animation: drop-confetti-378 9s 5s infinite linear; }

@keyframes drop-confetti-378 {
  100% {
    top: 110%;
    left: 8%; } }

.confetti-379 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 4%;
  opacity: 0.81409;
  transform: rotate(191.36515deg);
  animation: drop-confetti-379 6s 1s infinite linear; }

@keyframes drop-confetti-379 {
  100% {
    top: 110%;
    left: 11%; } }

.confetti-380 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 85%;
  opacity: 0.70249;
  transform: rotate(58.37097deg);
  animation: drop-confetti-380 7s 5s infinite linear; }

@keyframes drop-confetti-380 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-381 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 51%;
  opacity: 0.80167;
  transform: rotate(149.5354deg);
  animation: drop-confetti-381 9s 2s infinite linear; }

@keyframes drop-confetti-381 {
  100% {
    top: 110%;
    left: 59%; } }

.confetti-382 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 53%;
  opacity: 1.15685;
  transform: rotate(290.87388deg);
  animation: drop-confetti-382 9s 4s infinite linear; }

@keyframes drop-confetti-382 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-383 {
  width: 4px;
  height: 1.6px;
  background-color: #E1CD00;
  top: -10%;
  left: 83%;
  opacity: 0.65756;
  transform: rotate(120.57481deg);
  animation: drop-confetti-383 9s 4s infinite linear; }

@keyframes drop-confetti-383 {
  100% {
    top: 110%;
    left: 87%; } }

.confetti-384 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 85%;
  opacity: 1.14533;
  transform: rotate(316.22856deg);
  animation: drop-confetti-384 7s 2s infinite linear; }

@keyframes drop-confetti-384 {
  100% {
    top: 110%;
    left: 97%; } }

.confetti-385 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 43%;
  opacity: 0.51384;
  transform: rotate(351.47319deg);
  animation: drop-confetti-385 8s 2s infinite linear; }

@keyframes drop-confetti-385 {
  100% {
    top: 110%;
    left: 44%; } }

.confetti-386 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 53%;
  opacity: 0.98841;
  transform: rotate(334.02145deg);
  animation: drop-confetti-386 6s 2s infinite linear; }

@keyframes drop-confetti-386 {
  100% {
    top: 110%;
    left: 68%; } }

.confetti-387 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 16%;
  opacity: 0.89336;
  transform: rotate(43.75901deg);
  animation: drop-confetti-387 5s 5s infinite linear; }

@keyframes drop-confetti-387 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-388 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 91%;
  opacity: 0.68075;
  transform: rotate(139.82483deg);
  animation: drop-confetti-388 5s 1s infinite linear; }

@keyframes drop-confetti-388 {
  100% {
    top: 110%;
    left: 100%; } }

.confetti-389 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 52%;
  opacity: 1.15544;
  transform: rotate(237.22807deg);
  animation: drop-confetti-389 7s 3s infinite linear; }

@keyframes drop-confetti-389 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-390 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 90%;
  opacity: 1.28098;
  transform: rotate(17.88702deg);
  animation: drop-confetti-390 9s 2s infinite linear; }

@keyframes drop-confetti-390 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-391 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 11%;
  opacity: 0.66516;
  transform: rotate(78.70872deg);
  animation: drop-confetti-391 5s 2s infinite linear; }

@keyframes drop-confetti-391 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-392 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 38%;
  opacity: 1.45345;
  transform: rotate(190.06667deg);
  animation: drop-confetti-392 5s 1s infinite linear; }

@keyframes drop-confetti-392 {
  100% {
    top: 110%;
    left: 44%; } }

.confetti-393 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 9%;
  opacity: 1.24095;
  transform: rotate(223.26079deg);
  animation: drop-confetti-393 5s 5s infinite linear; }

@keyframes drop-confetti-393 {
  100% {
    top: 110%;
    left: 11%; } }

.confetti-394 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 92%;
  opacity: 1.33519;
  transform: rotate(45.48785deg);
  animation: drop-confetti-394 5s 3s infinite linear; }

@keyframes drop-confetti-394 {
  100% {
    top: 110%;
    left: 97%; } }

.confetti-395 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 88%;
  opacity: 0.8443;
  transform: rotate(277.62847deg);
  animation: drop-confetti-395 8s 1s infinite linear; }

@keyframes drop-confetti-395 {
  100% {
    top: 110%;
    left: 89%; } }

.confetti-396 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 60%;
  opacity: 1.21324;
  transform: rotate(141.65396deg);
  animation: drop-confetti-396 9s 1s infinite linear; }

@keyframes drop-confetti-396 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-397 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 54%;
  opacity: 0.74165;
  transform: rotate(246.99533deg);
  animation: drop-confetti-397 7s 4s infinite linear; }

@keyframes drop-confetti-397 {
  100% {
    top: 110%;
    left: 68%; } }

.confetti-398 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 35%;
  opacity: 1.31607;
  transform: rotate(259.17852deg);
  animation: drop-confetti-398 6s 2s infinite linear; }

@keyframes drop-confetti-398 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-399 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 31%;
  opacity: 1.44133;
  transform: rotate(136.48005deg);
  animation: drop-confetti-399 6s 2s infinite linear; }

@keyframes drop-confetti-399 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-400 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 16%;
  opacity: 0.63376;
  transform: rotate(282.57982deg);
  animation: drop-confetti-400 9s 1s infinite linear; }

@keyframes drop-confetti-400 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-401 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 32%;
  opacity: 0.75208;
  transform: rotate(251.74597deg);
  animation: drop-confetti-401 9s 3s infinite linear; }

@keyframes drop-confetti-401 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-402 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 6%;
  opacity: 0.92915;
  transform: rotate(294.57804deg);
  animation: drop-confetti-402 7s 5s infinite linear; }

@keyframes drop-confetti-402 {
  100% {
    top: 110%;
    left: 14%; } }

.confetti-403 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 62%;
  opacity: 1.20338;
  transform: rotate(5.20204deg);
  animation: drop-confetti-403 8s 2s infinite linear; }

@keyframes drop-confetti-403 {
  100% {
    top: 110%;
    left: 70%; } }

.confetti-404 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 77%;
  opacity: 0.93309;
  transform: rotate(209.97834deg);
  animation: drop-confetti-404 5s 5s infinite linear; }

@keyframes drop-confetti-404 {
  100% {
    top: 110%;
    left: 82%; } }

.confetti-405 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 38%;
  opacity: 0.97775;
  transform: rotate(355.6349deg);
  animation: drop-confetti-405 6s 1s infinite linear; }

@keyframes drop-confetti-405 {
  100% {
    top: 110%;
    left: 42%; } }

.confetti-406 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 33%;
  opacity: 1.43291;
  transform: rotate(171.24383deg);
  animation: drop-confetti-406 9s 4s infinite linear; }

@keyframes drop-confetti-406 {
  100% {
    top: 110%;
    left: 35%; } }

.confetti-407 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 6%;
  opacity: 0.58415;
  transform: rotate(63.9283deg);
  animation: drop-confetti-407 6s 1s infinite linear; }

@keyframes drop-confetti-407 {
  100% {
    top: 110%;
    left: 17%; } }

.confetti-408 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 59%;
  opacity: 1.32277;
  transform: rotate(325.90427deg);
  animation: drop-confetti-408 6s 4s infinite linear; }

@keyframes drop-confetti-408 {
  100% {
    top: 110%;
    left: 66%; } }

.confetti-409 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 58%;
  opacity: 0.60041;
  transform: rotate(139.93313deg);
  animation: drop-confetti-409 5s 4s infinite linear; }

@keyframes drop-confetti-409 {
  100% {
    top: 110%;
    left: 63%; } }

.confetti-410 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 64%;
  opacity: 1.10768;
  transform: rotate(142.88984deg);
  animation: drop-confetti-410 8s 1s infinite linear; }

@keyframes drop-confetti-410 {
  100% {
    top: 110%;
    left: 73%; } }

.confetti-411 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 5%;
  opacity: 1.39438;
  transform: rotate(357.73208deg);
  animation: drop-confetti-411 6s 5s infinite linear; }

@keyframes drop-confetti-411 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-412 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 18%;
  opacity: 0.60907;
  transform: rotate(89.2693deg);
  animation: drop-confetti-412 9s 1s infinite linear; }

@keyframes drop-confetti-412 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-413 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 69%;
  opacity: 1.08678;
  transform: rotate(273.73074deg);
  animation: drop-confetti-413 5s 2s infinite linear; }

@keyframes drop-confetti-413 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-414 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 71%;
  opacity: 1.2431;
  transform: rotate(64.76124deg);
  animation: drop-confetti-414 5s 2s infinite linear; }

@keyframes drop-confetti-414 {
  100% {
    top: 110%;
    left: 74%; } }

.confetti-415 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 9%;
  opacity: 1.0922;
  transform: rotate(215.45906deg);
  animation: drop-confetti-415 6s 1s infinite linear; }

@keyframes drop-confetti-415 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-416 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 22%;
  opacity: 0.9675;
  transform: rotate(144.22374deg);
  animation: drop-confetti-416 8s 5s infinite linear; }

@keyframes drop-confetti-416 {
  100% {
    top: 110%;
    left: 26%; } }

.confetti-417 {
  width: 7px;
  height: 2.8px;
  background-color: #000000;
  top: -10%;
  left: 20%;
  opacity: 0.71079;
  transform: rotate(91.59967deg);
  animation: drop-confetti-417 8s 3s infinite linear; }

@keyframes drop-confetti-417 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-418 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 19%;
  opacity: 0.60269;
  transform: rotate(183.10912deg);
  animation: drop-confetti-418 6s 5s infinite linear; }

@keyframes drop-confetti-418 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-419 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 52%;
  opacity: 1.37147;
  transform: rotate(147.04039deg);
  animation: drop-confetti-419 8s 3s infinite linear; }

@keyframes drop-confetti-419 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-420 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 42%;
  opacity: 0.92927;
  transform: rotate(294.07291deg);
  animation: drop-confetti-420 7s 3s infinite linear; }

@keyframes drop-confetti-420 {
  100% {
    top: 110%;
    left: 57%; } }

.confetti-421 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 70%;
  opacity: 0.5652;
  transform: rotate(205.42198deg);
  animation: drop-confetti-421 5s 4s infinite linear; }

@keyframes drop-confetti-421 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-422 {
  width: 1px;
  height: 0.4px;
  background-color: #00AB84;
  top: -10%;
  left: 14%;
  opacity: 1.26161;
  transform: rotate(302.68752deg);
  animation: drop-confetti-422 6s 2s infinite linear; }

@keyframes drop-confetti-422 {
  100% {
    top: 110%;
    left: 29%; } }

.confetti-423 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 75%;
  opacity: 0.8824;
  transform: rotate(106.71689deg);
  animation: drop-confetti-423 6s 5s infinite linear; }

@keyframes drop-confetti-423 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-424 {
  width: 8px;
  height: 3.2px;
  background-color: #00AB84;
  top: -10%;
  left: 55%;
  opacity: 1.13338;
  transform: rotate(148.03639deg);
  animation: drop-confetti-424 5s 5s infinite linear; }

@keyframes drop-confetti-424 {
  100% {
    top: 110%;
    left: 65%; } }

.confetti-425 {
  width: 3px;
  height: 1.2px;
  background-color: #2980B9;
  top: -10%;
  left: 60%;
  opacity: 0.5193;
  transform: rotate(256.61734deg);
  animation: drop-confetti-425 5s 1s infinite linear; }

@keyframes drop-confetti-425 {
  100% {
    top: 110%;
    left: 67%; } }

.confetti-426 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 73%;
  opacity: 1.34229;
  transform: rotate(146.81066deg);
  animation: drop-confetti-426 9s 5s infinite linear; }

@keyframes drop-confetti-426 {
  100% {
    top: 110%;
    left: 75%; } }

.confetti-427 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 57%;
  opacity: 1.08909;
  transform: rotate(55.71163deg);
  animation: drop-confetti-427 7s 5s infinite linear; }

@keyframes drop-confetti-427 {
  100% {
    top: 110%;
    left: 70%; } }

.confetti-428 {
  width: 5px;
  height: 2px;
  background-color: #000000;
  top: -10%;
  left: 70%;
  opacity: 0.71504;
  transform: rotate(33.09673deg);
  animation: drop-confetti-428 8s 1s infinite linear; }

@keyframes drop-confetti-428 {
  100% {
    top: 110%;
    left: 78%; } }

.confetti-429 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 24%;
  opacity: 1.36855;
  transform: rotate(353.59335deg);
  animation: drop-confetti-429 9s 3s infinite linear; }

@keyframes drop-confetti-429 {
  100% {
    top: 110%;
    left: 34%; } }

.confetti-430 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 27%;
  opacity: 0.64429;
  transform: rotate(264.10718deg);
  animation: drop-confetti-430 6s 4s infinite linear; }

@keyframes drop-confetti-430 {
  100% {
    top: 110%;
    left: 36%; } }

.confetti-431 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 41%;
  opacity: 0.55051;
  transform: rotate(299.98776deg);
  animation: drop-confetti-431 9s 5s infinite linear; }

@keyframes drop-confetti-431 {
  100% {
    top: 110%;
    left: 54%; } }

.confetti-432 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 45%;
  opacity: 1.03078;
  transform: rotate(40.65545deg);
  animation: drop-confetti-432 5s 2s infinite linear; }

@keyframes drop-confetti-432 {
  100% {
    top: 110%;
    left: 60%; } }

.confetti-433 {
  width: 4px;
  height: 1.6px;
  background-color: #2980B9;
  top: -10%;
  left: 40%;
  opacity: 0.83177;
  transform: rotate(101.21874deg);
  animation: drop-confetti-433 8s 3s infinite linear; }

@keyframes drop-confetti-433 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-434 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 48%;
  opacity: 1.43106;
  transform: rotate(14.52186deg);
  animation: drop-confetti-434 5s 3s infinite linear; }

@keyframes drop-confetti-434 {
  100% {
    top: 110%;
    left: 50%; } }

.confetti-435 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 31%;
  opacity: 1.19421;
  transform: rotate(184.37976deg);
  animation: drop-confetti-435 9s 4s infinite linear; }

@keyframes drop-confetti-435 {
  100% {
    top: 110%;
    left: 39%; } }

.confetti-436 {
  width: 4px;
  height: 1.6px;
  background-color: #00AB84;
  top: -10%;
  left: 24%;
  opacity: 0.64327;
  transform: rotate(66.47406deg);
  animation: drop-confetti-436 5s 3s infinite linear; }

@keyframes drop-confetti-436 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-437 {
  width: 2px;
  height: 0.8px;
  background-color: #000000;
  top: -10%;
  left: 75%;
  opacity: 1.43527;
  transform: rotate(297.52933deg);
  animation: drop-confetti-437 9s 5s infinite linear; }

@keyframes drop-confetti-437 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-438 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 25%;
  opacity: 0.64808;
  transform: rotate(342.59304deg);
  animation: drop-confetti-438 9s 5s infinite linear; }

@keyframes drop-confetti-438 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-439 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 38%;
  opacity: 0.98686;
  transform: rotate(103.16654deg);
  animation: drop-confetti-439 8s 5s infinite linear; }

@keyframes drop-confetti-439 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-440 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 19%;
  opacity: 1.03435;
  transform: rotate(169.6235deg);
  animation: drop-confetti-440 6s 4s infinite linear; }

@keyframes drop-confetti-440 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-441 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 45%;
  opacity: 1.47975;
  transform: rotate(235.01715deg);
  animation: drop-confetti-441 5s 1s infinite linear; }

@keyframes drop-confetti-441 {
  100% {
    top: 110%;
    left: 46%; } }

.confetti-442 {
  width: 5px;
  height: 2px;
  background-color: #00AB84;
  top: -10%;
  left: 99%;
  opacity: 1.37018;
  transform: rotate(278.14894deg);
  animation: drop-confetti-442 7s 4s infinite linear; }

@keyframes drop-confetti-442 {
  100% {
    top: 110%;
    left: 103%; } }

.confetti-443 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 57%;
  opacity: 0.51724;
  transform: rotate(266.96806deg);
  animation: drop-confetti-443 7s 5s infinite linear; }

@keyframes drop-confetti-443 {
  100% {
    top: 110%;
    left: 58%; } }

.confetti-444 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 87%;
  opacity: 0.76352;
  transform: rotate(167.15159deg);
  animation: drop-confetti-444 5s 1s infinite linear; }

@keyframes drop-confetti-444 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-445 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 71%;
  opacity: 1.28626;
  transform: rotate(199.51489deg);
  animation: drop-confetti-445 9s 1s infinite linear; }

@keyframes drop-confetti-445 {
  100% {
    top: 110%;
    left: 72%; } }

.confetti-446 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 46%;
  opacity: 1.03394;
  transform: rotate(153.71396deg);
  animation: drop-confetti-446 8s 1s infinite linear; }

@keyframes drop-confetti-446 {
  100% {
    top: 110%;
    left: 55%; } }

.confetti-447 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 80%;
  opacity: 1.19454;
  transform: rotate(297.34384deg);
  animation: drop-confetti-447 7s 2s infinite linear; }

@keyframes drop-confetti-447 {
  100% {
    top: 110%;
    left: 92%; } }

.confetti-448 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 14%;
  opacity: 1.26123;
  transform: rotate(170.89489deg);
  animation: drop-confetti-448 5s 3s infinite linear; }

@keyframes drop-confetti-448 {
  100% {
    top: 110%;
    left: 17%; } }

.confetti-449 {
  width: 8px;
  height: 3.2px;
  background-color: #000000;
  top: -10%;
  left: 9%;
  opacity: 0.97655;
  transform: rotate(64.12324deg);
  animation: drop-confetti-449 6s 2s infinite linear; }

@keyframes drop-confetti-449 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-450 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 34%;
  opacity: 1.10613;
  transform: rotate(20.59123deg);
  animation: drop-confetti-450 5s 4s infinite linear; }

@keyframes drop-confetti-450 {
  100% {
    top: 110%;
    left: 47%; } }

.confetti-451 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 81%;
  opacity: 1.38273;
  transform: rotate(225.02627deg);
  animation: drop-confetti-451 8s 3s infinite linear; }

@keyframes drop-confetti-451 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-452 {
  width: 8px;
  height: 3.2px;
  background-color: #C6017D;
  top: -10%;
  left: 71%;
  opacity: 1.06322;
  transform: rotate(230.20205deg);
  animation: drop-confetti-452 8s 1s infinite linear; }

@keyframes drop-confetti-452 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-453 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 25%;
  opacity: 1.39948;
  transform: rotate(295.62914deg);
  animation: drop-confetti-453 5s 2s infinite linear; }

@keyframes drop-confetti-453 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-454 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 33%;
  opacity: 0.83817;
  transform: rotate(235.57484deg);
  animation: drop-confetti-454 8s 5s infinite linear; }

@keyframes drop-confetti-454 {
  100% {
    top: 110%;
    left: 46%; } }

.confetti-455 {
  width: 6px;
  height: 2.4px;
  background-color: #C6017D;
  top: -10%;
  left: 23%;
  opacity: 0.83865;
  transform: rotate(212.86906deg);
  animation: drop-confetti-455 6s 1s infinite linear; }

@keyframes drop-confetti-455 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-456 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 63%;
  opacity: 0.60105;
  transform: rotate(341.63464deg);
  animation: drop-confetti-456 5s 3s infinite linear; }

@keyframes drop-confetti-456 {
  100% {
    top: 110%;
    left: 69%; } }

.confetti-457 {
  width: 4px;
  height: 1.6px;
  background-color: #C6017D;
  top: -10%;
  left: 15%;
  opacity: 0.99223;
  transform: rotate(30.59772deg);
  animation: drop-confetti-457 5s 1s infinite linear; }

@keyframes drop-confetti-457 {
  100% {
    top: 110%;
    left: 30%; } }

.confetti-458 {
  width: 7px;
  height: 2.8px;
  background-color: #C6017D;
  top: -10%;
  left: 72%;
  opacity: 0.91701;
  transform: rotate(35.40534deg);
  animation: drop-confetti-458 7s 5s infinite linear; }

@keyframes drop-confetti-458 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-459 {
  width: 4px;
  height: 1.6px;
  background-color: #000000;
  top: -10%;
  left: 67%;
  opacity: 1.01882;
  transform: rotate(189.70869deg);
  animation: drop-confetti-459 7s 5s infinite linear; }

@keyframes drop-confetti-459 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-460 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 74%;
  opacity: 0.65905;
  transform: rotate(176.11102deg);
  animation: drop-confetti-460 7s 4s infinite linear; }

@keyframes drop-confetti-460 {
  100% {
    top: 110%;
    left: 77%; } }

.confetti-461 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 26%;
  opacity: 1.08285;
  transform: rotate(183.30656deg);
  animation: drop-confetti-461 6s 1s infinite linear; }

@keyframes drop-confetti-461 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-462 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 14%;
  opacity: 0.72304;
  transform: rotate(44.54953deg);
  animation: drop-confetti-462 9s 4s infinite linear; }

@keyframes drop-confetti-462 {
  100% {
    top: 110%;
    left: 19%; } }

.confetti-463 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 29%;
  opacity: 1.19229;
  transform: rotate(287.65187deg);
  animation: drop-confetti-463 5s 3s infinite linear; }

@keyframes drop-confetti-463 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-464 {
  width: 8px;
  height: 3.2px;
  background-color: #2980B9;
  top: -10%;
  left: 45%;
  opacity: 1.21741;
  transform: rotate(295.85801deg);
  animation: drop-confetti-464 6s 3s infinite linear; }

@keyframes drop-confetti-464 {
  100% {
    top: 110%;
    left: 58%; } }

.confetti-465 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 79%;
  opacity: 1.01755;
  transform: rotate(338.61928deg);
  animation: drop-confetti-465 6s 4s infinite linear; }

@keyframes drop-confetti-465 {
  100% {
    top: 110%;
    left: 86%; } }

.confetti-466 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 99%;
  opacity: 0.81988;
  transform: rotate(108.28024deg);
  animation: drop-confetti-466 7s 4s infinite linear; }

@keyframes drop-confetti-466 {
  100% {
    top: 110%;
    left: 106%; } }

.confetti-467 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 14%;
  opacity: 0.5786;
  transform: rotate(236.06497deg);
  animation: drop-confetti-467 7s 4s infinite linear; }

@keyframes drop-confetti-467 {
  100% {
    top: 110%;
    left: 22%; } }

.confetti-468 {
  width: 7px;
  height: 2.8px;
  background-color: #00AB84;
  top: -10%;
  left: 81%;
  opacity: 0.92068;
  transform: rotate(250.95708deg);
  animation: drop-confetti-468 9s 4s infinite linear; }

@keyframes drop-confetti-468 {
  100% {
    top: 110%;
    left: 94%; } }

.confetti-469 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 34%;
  opacity: 0.85084;
  transform: rotate(148.59662deg);
  animation: drop-confetti-469 5s 3s infinite linear; }

@keyframes drop-confetti-469 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-470 {
  width: 1px;
  height: 0.4px;
  background-color: #000000;
  top: -10%;
  left: 86%;
  opacity: 1.16854;
  transform: rotate(226.23891deg);
  animation: drop-confetti-470 7s 1s infinite linear; }

@keyframes drop-confetti-470 {
  100% {
    top: 110%;
    left: 88%; } }

.confetti-471 {
  width: 5px;
  height: 2px;
  background-color: #E1CD00;
  top: -10%;
  left: 12%;
  opacity: 0.97447;
  transform: rotate(120.22688deg);
  animation: drop-confetti-471 6s 3s infinite linear; }

@keyframes drop-confetti-471 {
  100% {
    top: 110%;
    left: 14%; } }

.confetti-472 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 6%;
  opacity: 1.37092;
  transform: rotate(284.08598deg);
  animation: drop-confetti-472 9s 1s infinite linear; }

@keyframes drop-confetti-472 {
  100% {
    top: 110%;
    left: 12%; } }

.confetti-473 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 77%;
  opacity: 1.19838;
  transform: rotate(184.25069deg);
  animation: drop-confetti-473 5s 1s infinite linear; }

@keyframes drop-confetti-473 {
  100% {
    top: 110%;
    left: 90%; } }

.confetti-474 {
  width: 5px;
  height: 2px;
  background-color: #2980B9;
  top: -10%;
  left: 94%;
  opacity: 1.11266;
  transform: rotate(342.80349deg);
  animation: drop-confetti-474 7s 2s infinite linear; }

@keyframes drop-confetti-474 {
  100% {
    top: 110%;
    left: 101%; } }

.confetti-475 {
  width: 5px;
  height: 2px;
  background-color: #C6017D;
  top: -10%;
  left: 29%;
  opacity: 0.58331;
  transform: rotate(273.01308deg);
  animation: drop-confetti-475 9s 5s infinite linear; }

@keyframes drop-confetti-475 {
  100% {
    top: 110%;
    left: 40%; } }

.confetti-476 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 90%;
  opacity: 1.3438;
  transform: rotate(82.88008deg);
  animation: drop-confetti-476 9s 5s infinite linear; }

@keyframes drop-confetti-476 {
  100% {
    top: 110%;
    left: 100%; } }

.confetti-477 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 79%;
  opacity: 1.2169;
  transform: rotate(36.12262deg);
  animation: drop-confetti-477 6s 1s infinite linear; }

@keyframes drop-confetti-477 {
  100% {
    top: 110%;
    left: 84%; } }

.confetti-478 {
  width: 3px;
  height: 1.2px;
  background-color: #C6017D;
  top: -10%;
  left: 6%;
  opacity: 1.26122;
  transform: rotate(143.6367deg);
  animation: drop-confetti-478 5s 1s infinite linear; }

@keyframes drop-confetti-478 {
  100% {
    top: 110%;
    left: 18%; } }

.confetti-479 {
  width: 7px;
  height: 2.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 88%;
  opacity: 1.34306;
  transform: rotate(41.80614deg);
  animation: drop-confetti-479 9s 5s infinite linear; }

@keyframes drop-confetti-479 {
  100% {
    top: 110%;
    left: 96%; } }

.confetti-480 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 10%;
  opacity: 1.34602;
  transform: rotate(96.47337deg);
  animation: drop-confetti-480 5s 2s infinite linear; }

@keyframes drop-confetti-480 {
  100% {
    top: 110%;
    left: 23%; } }

.confetti-481 {
  width: 3px;
  height: 1.2px;
  background-color: #000000;
  top: -10%;
  left: 41%;
  opacity: 1.42942;
  transform: rotate(158.26083deg);
  animation: drop-confetti-481 5s 4s infinite linear; }

@keyframes drop-confetti-481 {
  100% {
    top: 110%;
    left: 53%; } }

.confetti-482 {
  width: 2px;
  height: 0.8px;
  background-color: #00AB84;
  top: -10%;
  left: 96%;
  opacity: 0.78304;
  transform: rotate(254.28337deg);
  animation: drop-confetti-482 5s 3s infinite linear; }

@keyframes drop-confetti-482 {
  100% {
    top: 110%;
    left: 97%; } }

.confetti-483 {
  width: 1px;
  height: 0.4px;
  background-color: #2980B9;
  top: -10%;
  left: 88%;
  opacity: 1.16943;
  transform: rotate(166.05458deg);
  animation: drop-confetti-483 8s 4s infinite linear; }

@keyframes drop-confetti-483 {
  100% {
    top: 110%;
    left: 100%; } }

.confetti-484 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 84%;
  opacity: 0.52045;
  transform: rotate(143.44436deg);
  animation: drop-confetti-484 6s 2s infinite linear; }

@keyframes drop-confetti-484 {
  100% {
    top: 110%;
    left: 95%; } }

.confetti-485 {
  width: 8px;
  height: 3.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 85%;
  opacity: 1.35678;
  transform: rotate(276.0118deg);
  animation: drop-confetti-485 8s 5s infinite linear; }

@keyframes drop-confetti-485 {
  100% {
    top: 110%;
    left: 91%; } }

.confetti-486 {
  width: 6px;
  height: 2.4px;
  background-color: #2980B9;
  top: -10%;
  left: 45%;
  opacity: 1.1921;
  transform: rotate(303.30613deg);
  animation: drop-confetti-486 5s 1s infinite linear; }

@keyframes drop-confetti-486 {
  100% {
    top: 110%;
    left: 51%; } }

.confetti-487 {
  width: 2px;
  height: 0.8px;
  background-color: #C6017D;
  top: -10%;
  left: 47%;
  opacity: 1.35989;
  transform: rotate(332.61329deg);
  animation: drop-confetti-487 5s 2s infinite linear; }

@keyframes drop-confetti-487 {
  100% {
    top: 110%;
    left: 57%; } }

.confetti-488 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 20%;
  opacity: 0.74326;
  transform: rotate(329.88982deg);
  animation: drop-confetti-488 5s 3s infinite linear; }

@keyframes drop-confetti-488 {
  100% {
    top: 110%;
    left: 28%; } }

.confetti-489 {
  width: 6px;
  height: 2.4px;
  background-color: #00AB84;
  top: -10%;
  left: 8%;
  opacity: 1.3628;
  transform: rotate(329.45453deg);
  animation: drop-confetti-489 5s 5s infinite linear; }

@keyframes drop-confetti-489 {
  100% {
    top: 110%;
    left: 9%; } }

.confetti-490 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 95%;
  opacity: 1.3916;
  transform: rotate(241.91404deg);
  animation: drop-confetti-490 9s 5s infinite linear; }

@keyframes drop-confetti-490 {
  100% {
    top: 110%;
    left: 110%; } }

.confetti-491 {
  width: 6px;
  height: 2.4px;
  background-color: #000000;
  top: -10%;
  left: 98%;
  opacity: 1.46741;
  transform: rotate(265.06407deg);
  animation: drop-confetti-491 8s 2s infinite linear; }

@keyframes drop-confetti-491 {
  100% {
    top: 110%;
    left: 105%; } }

.confetti-492 {
  width: 3px;
  height: 1.2px;
  background-color: #00AB84;
  top: -10%;
  left: 37%;
  opacity: 1.35969;
  transform: rotate(285.8474deg);
  animation: drop-confetti-492 8s 1s infinite linear; }

@keyframes drop-confetti-492 {
  100% {
    top: 110%;
    left: 49%; } }

.confetti-493 {
  width: 1px;
  height: 0.4px;
  background-color: #C6017D;
  top: -10%;
  left: 29%;
  opacity: 1.00185;
  transform: rotate(241.53348deg);
  animation: drop-confetti-493 9s 3s infinite linear; }

@keyframes drop-confetti-493 {
  100% {
    top: 110%;
    left: 32%; } }

.confetti-494 {
  width: 1px;
  height: 0.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 13%;
  opacity: 0.79693;
  transform: rotate(165.58093deg);
  animation: drop-confetti-494 5s 1s infinite linear; }

@keyframes drop-confetti-494 {
  100% {
    top: 110%;
    left: 24%; } }

.confetti-495 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 4%;
  opacity: 1.195;
  transform: rotate(46.09942deg);
  animation: drop-confetti-495 7s 2s infinite linear; }

@keyframes drop-confetti-495 {
  100% {
    top: 110%;
    left: 16%; } }

.confetti-496 {
  width: 6px;
  height: 2.4px;
  background-color: #E1CD00;
  top: -10%;
  left: 33%;
  opacity: 1.193;
  transform: rotate(110.07859deg);
  animation: drop-confetti-496 8s 2s infinite linear; }

@keyframes drop-confetti-496 {
  100% {
    top: 110%;
    left: 37%; } }

.confetti-497 {
  width: 2px;
  height: 0.8px;
  background-color: #E1CD00;
  top: -10%;
  left: 1%;
  opacity: 0.97177;
  transform: rotate(155.28039deg);
  animation: drop-confetti-497 9s 1s infinite linear; }

@keyframes drop-confetti-497 {
  100% {
    top: 110%;
    left: 10%; } }

.confetti-498 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 27%;
  opacity: 1.31423;
  transform: rotate(43.69165deg);
  animation: drop-confetti-498 6s 4s infinite linear; }

@keyframes drop-confetti-498 {
  100% {
    top: 110%;
    left: 33%; } }

.confetti-499 {
  width: 7px;
  height: 2.8px;
  background-color: #2980B9;
  top: -10%;
  left: 51%;
  opacity: 0.59909;
  transform: rotate(126.21834deg);
  animation: drop-confetti-499 5s 3s infinite linear; }

@keyframes drop-confetti-499 {
  100% {
    top: 110%;
    left: 61%; } }

.confetti-500 {
  width: 3px;
  height: 1.2px;
  background-color: #E1CD00;
  top: -10%;
  left: 42%;
  opacity: 0.92899;
  transform: rotate(233.78063deg);
  animation: drop-confetti-500 9s 1s infinite linear; }

@keyframes drop-confetti-500 {
  100% {
    top: 110%;
    left: 52%; } }

/* ------------------------
    Typography
------------------------- */
:is(h1, h2, h3, h4, h5, h6) {
  font-family: "Roboto", sans-serif; }

h1 {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0;
  padding: 20px 0; }
  @media (max-width: 1200px) {
    h1 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h6 {
  font-size: 0.875rem; }

small {
  font-size: 70%; }

a {
  transition: all 0.5s ease; }
  a:hover, a:focus, a:active, a:visited {
    box-shadow: none;
    text-decoration: none; }

.btn:hover {
  text-decoration: none; }

.btn.btn-link {
  text-decoration: none; }

@font-face {
  font-family: 'icon-font';
  src: url("./fonts/icon-font/icon-font.ttf") format("truetype"), url("./fonts/icon-font/icon-font.woff") format("woff"), url("./fonts/icon-font/icon-font.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.i-search:before {
  content: "\e900"; }

.i-arrow-left:before {
  content: "\e901"; }

.i-info:before {
  content: "\e902"; }

.i-close:before {
  content: "\e903"; }

.i-clock:before {
  content: "\e905"; }

.i-calendar:before {
  content: "\e906"; }

.i-question:before {
  content: "\e907"; }

.i-arrows-v:before {
  content: "\e908"; }

.i-alert-triangle:before {
  content: "\e909"; }

.i-phone:before {
  content: "\e942"; }

.i-autorefresh {
  content: url("./images/autorefresh.svg"); }

/* ------------------------
    Base
------------------------- */
html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 768px;
  color: #273342;
  background-color: #F6F7FB;
  text-align: center;
  overflow-x: hidden;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  font-family: "Roboto", sans-serif; }
  body:before, body:after {
    content: ' ';
    display: table; }
  body:after {
    clear: both; }
  body #root {
    height: 100%; }
    body #root .ccm-main {
      position: relative;
      height: 100%; }
      body #root .ccm-main .ccm-body {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        overflow: hidden; }

.box-wrapper {
  background-color: #FFFFFF;
  border: 2px solid #E5E5E5; }

.wysiwyg-wrapper {
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column; }
  .wysiwyg-wrapper .wysiwyg-toolbar {
    border: none;
    border-bottom: 1px solid #E5E5E5; }
  .wysiwyg-wrapper .wysiwyg-editor {
    height: auto;
    flex-grow: 1;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    z-index: 0; }
    .wysiwyg-wrapper .wysiwyg-editor .DraftEditor-root {
      height: calc(100% - (0.5rem * 2)); }
      .wysiwyg-wrapper .wysiwyg-editor .DraftEditor-root .DraftEditor-editorContainer {
        height: 100%; }

.hidden {
  display: none !important;
  visibility: hidden !important;
  width: 0 !important;
  height: 0 !important; }

.cursor-default {
  cursor: default !important; }

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10; }
  .overlay.shown {
    display: block; }

.__react_component_tooltip {
  max-width: 250px; }
  .__react_component_tooltip ul {
    padding: 10px;
    margin: 0; }
    .__react_component_tooltip ul li {
      margin-bottom: 5px; }
      .__react_component_tooltip ul li:last-child {
        margin-bottom: 0; }

.Select.is-open + .__react_component_tooltip {
  display: none; }

/* ------------------------
    Top Bar
------------------------- */
.top-bar {
  width: 100%;
  height: 65px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px; }
  .top-bar .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .top-bar .navbar-brand img {
      height: 40px; }
  .top-bar .queue-control a,
  .top-bar .reports-control a,
  .top-bar .flight-plan-control a,
  .top-bar .qa-control a {
    color: #273342;
    transition: all 0.5s ease; }
  .top-bar .queue-control a:hover,
  .top-bar .queue-control a.active,
  .top-bar .reports-control a:hover,
  .top-bar .reports-control a.active,
  .top-bar .flight-plan-control a:hover,
  .top-bar .flight-plan-control a.active,
  .top-bar .qa-control a:hover,
  .top-bar .qa-control a.active {
    color: #74A3E1; }
  .top-bar .queue-control a.active,
  .top-bar .reports-control a.active,
  .top-bar .flight-plan-control a.active,
  .top-bar .qa-control a.active {
    font-weight: 500; }
  .top-bar .queue-control .queue-icon,
  .top-bar .reports-control .queue-icon,
  .top-bar .flight-plan-control .queue-icon,
  .top-bar .qa-control .queue-icon {
    font-size: 1.5rem;
    color: #498FE1; }
  .top-bar .queue-control .flight-plan-icon,
  .top-bar .reports-control .flight-plan-icon,
  .top-bar .flight-plan-control .flight-plan-icon,
  .top-bar .qa-control .flight-plan-icon {
    height: 25px; }
  .top-bar .queue-control .qa-control-icon,
  .top-bar .queue-control .reports-control-icon,
  .top-bar .reports-control .qa-control-icon,
  .top-bar .reports-control .reports-control-icon,
  .top-bar .flight-plan-control .qa-control-icon,
  .top-bar .flight-plan-control .reports-control-icon,
  .top-bar .qa-control .qa-control-icon,
  .top-bar .qa-control .reports-control-icon {
    font-size: 1.3rem; }
  .top-bar .stopwatch-timer {
    font-weight: bold;
    font-size: 1.3rem; }
  .top-bar .bi-stopwatch {
    font-size: 1.5rem; }
  .top-bar .user-control .user-credentials .username {
    font-size: 0.875rem;
    color: #273342;
    font-weight: 700;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis; }
  .top-bar .user-control .user-credentials .tenant {
    font-size: 0.75rem;
    color: #8095B1;
    line-height: 1;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis; }
  .top-bar .user-control .user-menu .dropdown-toggle {
    color: #000000; }
  .top-bar .user-control .user-menu .dropdown-item {
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
    color: #8095B1;
    font-weight: 500; }
    .top-bar .user-control .user-menu .dropdown-item:hover {
      color: #498FE1; }
  .top-bar .user-control .goals {
    margin-top: 8px; }

.line {
  border-radius: 4px;
  width: 40px;
  background-color: #8095B1;
  margin-bottom: 7px;
  overflow: hidden; }
  .line:last-child {
    margin-bottom: 0; }
  .line div {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 9px; }
    .line div.full {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .line div.approved {
      background-color: #14CE45; }
    .line div.pending {
      background-color: #E1CD00; }

.goal-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .goal-wrapper .goal {
    color: #8095B1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 17px;
    margin-right: 7px; }

/* ------------------------
    Footer
------------------------- */
.ccm-footer .footer {
  font-size: 0.75rem;
  flex-wrap: wrap;
  color: #8095B1;
  height: 30px;
  position: relative; }
  .ccm-footer .footer .build-version {
    margin-left: 50%;
    transform: translateX(-50%); }
  .ccm-footer .footer .corporate-info {
    text-align: right; }

/* ------------------------
    Forms
------------------------- */
.form-label {
  color: #2C3E50;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: capitalize; }

.simple-form-label {
  color: #2C3E50;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: none; }

.label-size-m {
  width: 100px; }

.form-invalid-bottom {
  position: absolute;
  bottom: -22px;
  text-align: center; }

.form-invalid-appt {
  margin-top: 30px; }

textarea.form-control::placeholder {
  color: #A1A1A1; }

.form-control {
  font-size: 0.875rem;
  padding: 0.375rem;
  border: 1px solid #C4C4C4; }
  .form-control:not(textarea) {
    height: 30px;
    padding: 0.375rem 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C4C4C4; }
  .form-control:focus {
    box-shadow: none;
    border-color: #74A3E1; }

.card .card-header .form-control {
  height: auto;
  padding: 0; }

.lipstick-checkbox .form-check-input:checked {
  accent-color: #C6017D; }

.custom-switch {
  z-index: 0; }

.form-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px; }
  .form-switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .form-switch input:checked + .form-slider {
      background-color: #74A3E1; }
      .form-switch input:checked + .form-slider:before {
        transform: translateX(18px); }
    .form-switch input:focus + .form-slider {
      box-shadow: 0 0 1px #74A3E1; }
  .form-switch .form-slider {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #C4C4C4;
    transition: 0.5s; }
    .form-switch .form-slider:before {
      position: absolute;
      content: "";
      left: 4px;
      bottom: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #FFFFFF;
      transition: 0.5s; }

.Select {
  width: 100%;
  min-width: 128px;
  font-size: 0.75rem;
  border-bottom: 1px solid #C4C4C4; }
  .Select.Select--single > .Select-control, .Select.Select--multi > .Select-control {
    height: 30px;
    border: none;
    border-radius: 0; }
    .Select.Select--single > .Select-control .Select-value,
    .Select.Select--single > .Select-control .Select-input,
    .Select.Select--single > .Select-control .Select-placeholder, .Select.Select--multi > .Select-control .Select-value,
    .Select.Select--multi > .Select-control .Select-input,
    .Select.Select--multi > .Select-control .Select-placeholder {
      padding-left: 0;
      margin-left: 0;
      margin-right: 5px; }
  .Select.Select-menu-up .Select-menu-outer .Select-menu {
    position: absolute;
    width: 100%;
    bottom: 30px;
    border: 1px solid #DCE3ED;
    background-color: #FFFFFF;
    border-radius: 2px; }
  .Select .Select-option {
    line-height: 1.4; }
  .Select.is-disabled > .Select-control {
    background-color: #FFFFFF; }
  .Select.is-focused:not(.is-open) {
    border-bottom: 1px solid #74A3E1; }
    .Select.is-focused:not(.is-open) > .Select-control {
      box-shadow: none !important; }

.react-datepicker-popper {
  z-index: 10; }

.medium-text {
  font-size: 0.875rem; }

.small-select.Select {
  width: 100%;
  height: 28px; }
  .small-select.Select .Select-input {
    height: 25px; }
    .small-select.Select .Select-input input {
      height: 18px;
      padding-top: 5px; }
  .small-select.Select .Select-control {
    height: 26px; }
    .small-select.Select .Select-control .Select-value,
    .small-select.Select .Select-control .Select-value-label {
      line-height: 28px; }
    .small-select.Select .Select-control .Select-placeholder {
      height: 26px;
      line-height: 26px; }
  .small-select.Select .Select-multi-value-wrapper .Select-value {
    padding-left: 5px;
    line-height: 14px;
    margin-left: 0;
    margin-right: 5px; }

.form-container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .form-container.login-form {
    width: 318px;
    margin: 0 auto; }
    .form-container.login-form .form-row:first-child {
      padding-top: 100px; }
    .form-container.login-form .several-links {
      display: flex;
      justify-content: space-between; }
    .form-container.login-form .react-datepicker-wrapper,
    .form-container.login-form .react-datepicker__input-container {
      width: 100%; }
  .form-container .form-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 0; }
    .form-container .form-row label {
      display: block;
      flex: 1 1 auto; }
    .form-container .form-row input {
      transform: translate3d(0, 0, 0);
      height: 34px;
      width: 100%;
      box-sizing: border-box; }
    .form-container .form-row .editable-input {
      width: 100%; }
    .form-container .form-row button:not(.react-datepicker__navigation) {
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      font-weight: 400;
      cursor: pointer;
      border: none;
      width: 152px;
      height: 34px;
      outline: none; }
      .form-container .form-row button:not(.react-datepicker__navigation).button-active {
        border-radius: 4px;
        color: #498FE1;
        border: 1px solid #498FE1;
        background-color: #FFFFFF; }
        .form-container .form-row button:not(.react-datepicker__navigation).button-active:hover {
          color: #FFFFFF;
          background-color: #498FE1; }
    .form-container .form-row .is-focused:not(.is-open) > .Select-control {
      box-shadow: none; }
    .form-container .form-row.links {
      margin-top: 70px; }

.form-container .nav-link {
  color: #498FE1;
  padding: 6px 10px;
  margin: 0 45px;
  text-decoration: none;
  opacity: .8;
  cursor: pointer; }
  .form-container .nav-link:visited {
    color: #498FE1; }
  .form-container .nav-link:hover {
    opacity: 1; }

.row.small-columns {
  padding: 6px 20px; }
  .row.small-columns .column {
    min-width: 170px; }

.row.delete-row {
  border-top: 1px solid #E5E5E5; }

.row .column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px; }
  .row .column.full-width {
    width: 100%; }

.other {
  width: 100%; }
  .other .editable-input {
    width: 100%;
    margin-left: 10px; }

.phone {
  border-bottom: 1px solid #E5E5E5; }

.label {
  color: #8095B1;
  padding: 4px 10px;
  min-width: 184px;
  text-align: left;
  font-weight: 400; }
  @media only screen and (max-width: 1023px) {
    .label {
      min-width: 75px; } }

.value {
  width: 100%;
  text-align: left; }

input::-webkit-input-placeholder {
  color: #8095B1; }

input::-moz-placeholder {
  color: #8095B1;
  opacity: 1; }

input::-moz-placeholder {
  color: #8095B1;
  opacity: 1; }

input:-ms-input-placeholder {
  color: #8095B1; }

input:disabled {
  background-color: #F9F9F9; }

input:invalid {
  box-shadow: none;
  -moz-box-shadow: none; }

input.other-input:disabled {
  display: none; }

input.small {
  width: 60px; }

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none; }

input[type="date"]::-ms-clear {
  display: none; }

.editable-input {
  width: 150px;
  text-align: left;
  position: relative; }
  .editable-input input {
    width: 100%;
    box-sizing: border-box; }
  .editable-input.small {
    width: 35px; }
    .editable-input.small .text {
      text-align: right; }
  .editable-input .text {
    cursor: pointer;
    min-height: 30px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden; }
  .editable-input .validation-label {
    position: absolute;
    font-size: 11px;
    color: #E74C3C;
    top: -17px;
    white-space: nowrap;
    background-color: #FFFFFF;
    font-weight: 700; }
  .editable-input.invalid input,
  .editable-input.invalid .Select {
    border-color: transparent transparent #E74C3C; }
    .editable-input.invalid input:focus,
    .editable-input.invalid .Select:focus {
      outline: none; }
  .editable-input.full-flex-width {
    flex: 1 1 auto;
    margin-right: 10px; }

.fake-value {
  width: 150px;
  text-align: left; }
  .fake-value .phone-number {
    cursor: pointer;
    position: relative; }
    .fake-value .phone-number:not(.simple-phone-number) {
      padding-left: 20px; }
      .fake-value .phone-number:not(.simple-phone-number)::before {
        content: '';
        background: url(./images/pencil.svg) no-repeat 50% 50%/contain;
        width: 14px;
        height: 100%;
        position: absolute;
        left: 0; }

.custom-checkbox {
  display: flex;
  align-items: center;
  text-align: left; }
  .custom-checkbox:hover .label {
    color: #498FE1; }
  .custom-checkbox .label {
    padding: 0;
    margin-left: 6px;
    cursor: pointer;
    width: auto;
    color: #273342;
    user-select: none; }
  .custom-checkbox .icon {
    align-self: baseline;
    width: 20px;
    min-width: 20px;
    height: 20px;
    cursor: pointer; }
    .custom-checkbox .icon.checked {
      background-image: url("./images/checked.png"); }
    .custom-checkbox .icon.unchecked {
      background-image: url("./images/unchecked.png"); }

.custom-checkbox-disabled:hover .label {
  color: #273342; }

.custom-checkbox-disabled .label {
  cursor: default; }

textarea {
  resize: none;
  width: 95%;
  height: 200px;
  padding: 10px;
  border: 1px solid #E5E5E5;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400; }

.input-label {
  text-align: left;
  font-size: 14px;
  color: #8095B1;
  position: relative;
  margin-bottom: 2px; }

label.invalid .input-label {
  color: #E74C3C; }

label.invalid input,
label.invalid .date-choose {
  border-color: transparent transparent #E74C3C; }

.Select-menu-outer {
  z-index: 3; }

.form-hint {
  padding: 10px 0;
  font-size: 16px;
  color: #BEC4CC; }

.info-icon {
  cursor: pointer;
  font-size: 16px;
  color: #8095B1;
  display: inline-block;
  vertical-align: top; }

.input-hint {
  color: #BEC4CC;
  font-size: 12px;
  padding: 4px 0; }

.rdw-option-wrapper img {
  width: 13px;
  height: 13px; }

.date-picker {
  justify-content: space-between;
  min-width: 300px; }
  @media only screen and (max-width: 1023px) {
    .date-picker {
      min-width: 240px; } }
  .date-picker > div {
    width: 30%; }
    @media only screen and (max-width: 1023px) {
      .date-picker > div {
        width: 32%; } }

.gray-checkbox {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid #8095B1;
  background-color: #FFFFFF;
  margin-right: 3px; }
  .gray-checkbox.checked {
    background-color: #8095B1; }

.radio-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .radio-container .gray-checkbox {
    margin-right: 7px; }
  .radio-container + .radio-container {
    margin-left: 20px; }
  .radio-container input[type=radio] {
    display: none; }
    .radio-container input[type=radio] ~ .radio-icon {
      display: inline-block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      border: 2px solid #8095B1;
      background-color: #FFFFFF;
      margin-right: 5px; }
    .radio-container input[type=radio]:checked ~ .radio-icon {
      background-color: #8095B1; }

.radio-container-inactive {
  color: #8095B1; }
  .radio-container-inactive .label {
    color: #8095B1; }

.radio-container-disabled {
  cursor: default;
  opacity: .5; }

.one-field-date-picker input {
  font-size: 0.875rem;
  padding: 0 0.75rem;
  border-radius: 10px;
  border: 1px solid #E5E5E5; }

.one-field-date-picker .validation-label {
  color: #E74C3C;
  white-space: nowrap; }

.one-field-date-picker.invalid input,
.one-field-date-picker.invalid .Select {
  border-color: rgba(231, 76, 60, 0.5); }
  .one-field-date-picker.invalid input:focus,
  .one-field-date-picker.invalid .Select:focus {
    outline: none; }

.one-field-date-picker .day-selected {
  border-radius: 0.3rem;
  background-color: #498FE1;
  color: #FFFFFF; }
  .one-field-date-picker .day-selected:hover {
    background-color: #1976D2; }

.one-field-date-picker .day-today {
  font-weight: bold; }

.react-datepicker__header {
  background-color: #498FE1;
  color: #FFFFFF; }
  .react-datepicker__header .react-datepicker__year-dropdown {
    color: #000000; }

.react-datepicker__current-month, .react-datepicker__day-name {
  color: #FFFFFF; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #498FE1; }

.react-datepicker__day--selected {
  background-color: #498FE1; }

.react-datepicker__day--outside-month {
  color: #C4C4C4; }

.react-datepicker__day--disabled {
  color: #DDDDDD; }

.react-datepicker__day--keyboard-selected {
  border-radius: inherit;
  background-color: inherit;
  color: inherit; }
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #F6F7FB; }

.react-datepicker__day--today {
  font-weight: inherit; }

.time-selector {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .time-selector .form-control {
    width: 42px;
    height: 35px;
    border-radius: 5px; }
    .time-selector .form-control:focus {
      border-width: 1.5px !important;
      border-color: #74A3E1 !important; }
  .time-selector input::-webkit-outer-spin-button,
  .time-selector input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center; }
  .time-selector input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center; }
  .time-selector .btn-group-vertical .btn {
    height: 17.5px;
    width: 25px;
    font-size: 0.5rem;
    padding: 0; }

/* ------------------------
    Loading
------------------------- */
.spinner-border {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.15em;
  margin: 0 auto; }

/* ------------------------
    Notifications
------------------------- */
.ccm-notification-container {
  position: fixed;
  top: 5%;
  left: 50%;
  z-index: 999999;
  width: 420px;
  max-height: calc(100% - 30px);
  transform: translateX(-50%);
  overflow-x: hidden;
  overflow-y: auto; }
  .ccm-notification-container .alert:first-child {
    margin-top: 30px; }

/* ------------------------
    Login
------------------------- */
.ccm-form-signin {
  background-image: url("./images/amelia-splash.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .ccm-form-signin .wrapper-login {
    background-color: #FFFFFF;
    border-radius: 30px; }
    .ccm-form-signin .wrapper-login img.logo {
      height: calc(40px + 10px);
      margin: 1.5rem auto; }
    .ccm-form-signin .wrapper-login .form-control:not(textarea) {
      font-size: 1rem;
      font-weight: 300;
      min-height: 40px;
      padding: 0.75rem;
      letter-spacing: .25px;
      border: 1px solid #E7F1FE; }

/* ------------------------
    Patient
------------------------- */
.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .avatar-icon, .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .avatar-icon {
  font-size: 0.75rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  transition: all 0.5s ease; }
  .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .avatar-icon .avatar-badge, .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .avatar-icon .avatar-badge {
    position: absolute;
    font-size: 0.6875rem;
    left: 18px;
    top: -8px;
    right: 0;
    width: 17px;
    height: 17px;
    background-color: #E74C3C;
    transition: all 0.5s ease; }

.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__name, .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__name {
  font-size: 1.25rem;
  padding-right: 3rem;
  line-height: 1.2; }

.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__gender, .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__gender,
.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__id,
.ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__id,
.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__dob,
.ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__dob,
.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__pcp,
.ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__pcp {
  font-size: 0.875rem; }

.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__phone .btn-group .btn, .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__phone .btn-group .btn {
  padding: 0.125rem 1rem; }

.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__phone .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__phone .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  font-size: 0.75rem; }

.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .patient__phone .btn-group > .btn:not(:first-child), .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info .patient__phone .btn-group > .btn:not(:first-child) {
  border-left-color: #FFFFFF;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.ccm-patient-main {
  position: relative;
  flex: 1;
  overflow: hidden;
  color: #2C3E50;
  /* ------------------------
    Patients Profile
  ------------------------- */ }
  .ccm-patient-main .ccm-patient-panel-menu {
    width: 350px;
    min-width: 350px; }
    .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper {
      position: relative; }
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .general-info-buttons {
        position: absolute;
        z-index: 9;
        top: 0;
        right: 1rem;
        padding: 1rem 0; }
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-info .dropdown .dropdown-toggle::after {
        color: #000000; }
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-categories .categories__title {
        font-weight: 500; }
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-categories .categories__title,
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-categories .categories__conditions {
        font-size: 0.875rem; }
      .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-info .general-categories .categories__outreachDate {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  .ccm-patient-main .ccm-patient-panel-right {
    text-align: left;
    transition: all 0.5s ease;
    width: 80px;
    min-width: 80px; }
    .ccm-patient-main .ccm-patient-panel-right i {
      transition: all 0.25s ease; }
    .ccm-patient-main .ccm-patient-panel-right.is-opened {
      width: 280px;
      min-width: 280px; }
    .ccm-patient-main .ccm-patient-panel-right.is-closed i {
      display: block;
      font-size: 1.25rem;
      margin-bottom: 0.75rem; }
    .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list {
      max-height: 150px;
      overflow: scroll; }
      .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check input[type="checkbox"] {
        margin-top: 0; }
        .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check input[type="checkbox"]:checked + .reminder__content label, .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check input[type="checkbox"]:checked + .reminder__content span {
          text-decoration: line-through; }
        .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check input[type="checkbox"]:checked + .reminder__content span {
          color: #929292; }
      .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check .reminder__content {
        font-size: 0.75rem;
        color: #2C3E50;
        line-height: 1;
        transition: color 0.25s ease; }
        .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check .reminder__content:hover {
          color: #74A3E1; }
        .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check .reminder__content label:hover,
        .ccm-patient-main .ccm-patient-panel-right .ccm-patient-reminders .reminders-list .form-check .reminder__content span:hover {
          cursor: pointer; }
  .ccm-patient-main .patient-profile {
    color: rgba(0, 0, 0, 0.7); }
    .ccm-patient-main .patient-profile span {
      font-size: 0.875rem; }
    .ccm-patient-main .patient-profile .dropdown .dropdown-menu {
      z-index: 19001; }
    .ccm-patient-main .patient-profile .dropdown .dropdown-toggle {
      font-size: 0.875rem; }
      .ccm-patient-main .patient-profile .dropdown .dropdown-toggle::after {
        color: #000000; }
    .ccm-patient-main .patient-profile .dropdown .dropdown-menu.show {
      left: 0.5rem !important;
      right: auto !important; }
    .ccm-patient-main .patient-profile .patient-preferences .dropdown .dropdown-menu.show {
      left: auto !important;
      right: 0.5rem !important; }
    .ccm-patient-main .patient-profile .patient-outreach table thead tr .batch__status,
    .ccm-patient-main .patient-profile .patient-outreach table tbody tr .batch__status {
      font-weight: 500; }
    .ccm-patient-main .patient-profile .patient-outreach table thead tr .batch__comment,
    .ccm-patient-main .patient-profile .patient-outreach table tbody tr .batch__comment {
      color: #2C3E50;
      max-width: 50%; }
    .ccm-patient-main .patient-profile .care-team-section table thead th:nth-child(1), .ccm-patient-main .patient-profile .care-team-section table thead td:nth-child(1), .ccm-patient-main .patient-profile .care-team-section table .table-body th:nth-child(1), .ccm-patient-main .patient-profile .care-team-section table .table-body td:nth-child(1) {
      width: 17%; }
    .ccm-patient-main .patient-profile .care-team-section table thead th:nth-child(2), .ccm-patient-main .patient-profile .care-team-section table thead td:nth-child(2), .ccm-patient-main .patient-profile .care-team-section table .table-body th:nth-child(2), .ccm-patient-main .patient-profile .care-team-section table .table-body td:nth-child(2) {
      width: 27%; }
    .ccm-patient-main .patient-profile .care-team-section table thead th:nth-child(3), .ccm-patient-main .patient-profile .care-team-section table thead td:nth-child(3), .ccm-patient-main .patient-profile .care-team-section table .table-body th:nth-child(3), .ccm-patient-main .patient-profile .care-team-section table .table-body td:nth-child(3) {
      width: 25%; }
    .ccm-patient-main .patient-profile .care-team-section table thead th:nth-child(4), .ccm-patient-main .patient-profile .care-team-section table thead td:nth-child(4), .ccm-patient-main .patient-profile .care-team-section table .table-body th:nth-child(4), .ccm-patient-main .patient-profile .care-team-section table .table-body td:nth-child(4) {
      width: 16%; }
    .ccm-patient-main .patient-profile .care-team-section table thead th:nth-child(5), .ccm-patient-main .patient-profile .care-team-section table thead td:nth-child(5), .ccm-patient-main .patient-profile .care-team-section table .table-body th:nth-child(5), .ccm-patient-main .patient-profile .care-team-section table .table-body td:nth-child(5) {
      width: 15%; }

.care-team-section-modal .form-check input, .care-team-section-modal .form-check label {
  cursor: pointer; }

/* ------------------------
    Patients List
------------------------- */
.ccm-status-legend {
  height: 55px;
  padding: 0 2.6rem 0 5.7rem; }
  .ccm-status-legend .select-status {
    width: 235px; }
    .ccm-status-legend .select-status .btn-light:not(:disabled):not(.disabled):active:focus,
    .ccm-status-legend .select-status .btn-light:not(:disabled):not(.disabled).active:focus,
    .ccm-status-legend .select-status .show > .btn-light.dropdown-toggle:focus {
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      background-color: transparent; }
    .ccm-status-legend .select-status button.dropdown-toggle {
      display: flex;
      border: none;
      align-items: center; }
    .ccm-status-legend .select-status .category, .ccm-status-legend .select-status .status-label {
      font-size: 0.875rem; }
    .ccm-status-legend .select-status .dropdown-menu.show {
      overflow-y: auto;
      max-height: 81vh; }
    .ccm-status-legend .select-status .dropdown-menu.show::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px; }
    .ccm-status-legend .select-status .dropdown-menu.show::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
    .ccm-status-legend .select-status .avatar-icon {
      width: 29px;
      height: 29px;
      line-height: 29px;
      text-align: center; }
  .ccm-status-legend .quick-filter {
    width: calc(100% - 235px); }
    .ccm-status-legend .quick-filter .pill-title {
      width: 100px;
      font-size: 0.75rem; }
    .ccm-status-legend .quick-filter .pill-label {
      line-height: 15px;
      font-size: 0.75rem;
      color: #929292; }
      .ccm-status-legend .quick-filter .pill-label:hover {
        color: #74A3E1; }
  .ccm-status-legend .nav .nav-item {
    flex: 1 1 0px; }
    .ccm-status-legend .nav .nav-item .avatar .avatar-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center; }
    .ccm-status-legend .nav .nav-item .avatar .avatar-label {
      line-height: 1.2;
      flex: 1 1 0;
      font-size: 14px; }
    .ccm-status-legend .nav .nav-item .nav-link {
      color: #000000;
      white-space: normal;
      padding: 0.25rem 1rem; }
      .ccm-status-legend .nav .nav-item .nav-link:hover {
        color: #74A3E1; }
      .ccm-status-legend .nav .nav-item .nav-link.disabled {
        color: #C4C4C4; }
    .ccm-status-legend .nav .nav-item.status-active {
      border: 1px solid #74A3E1;
      border-radius: 0.75rem; }
      .ccm-status-legend .nav .nav-item.status-active .nav-link {
        color: #74A3E1; }
        .ccm-status-legend .nav .nav-item.status-active .nav-link .status-remove i {
          font-size: 20px; }

.ccm-patients-list {
  position: relative;
  flex: 1;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .ccm-patients-list {
      padding-top: 0 !important; } }
  .ccm-patients-list .ccm-patient-panel-search {
    width: 85px;
    min-width: 85px;
    transition: all 0.25s ease; }
    .ccm-patients-list .ccm-patient-panel-search.is-opened {
      width: 245px;
      min-width: 245px; }
    .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper {
      position: relative; }
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper button[data-for="tooltip-patientSearch"],
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper button[data-for="tooltip-qaSearch"] {
        position: absolute;
        top: 0.5rem;
        right: 5px; }
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card {
        overflow: visible; }
        .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card:last-child {
          margin-bottom: 100px; }
        .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-header {
          color: #74A3E1;
          border-bottom: none;
          border-left: 5px solid transparent;
          background-color: transparent;
          transition: all 0.25s ease; }
          .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-header.is-active {
            border-left: 5px solid #74A3E1;
            background-color: rgba(116, 163, 225, 0.1); }
          .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-header .card-header-icon {
            font-size: 1.875rem; }
            @media (max-width: 1200px) {
              .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-header .card-header-icon {
                font-size: calc(1.3125rem + 0.75vw) ; } }
          .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-header .card-header-title {
            font-size: 0.875rem;
            font-weight: 300; }
        .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-body {
          padding: 1.25rem 0.75rem; }
          .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-body .form-label {
            color: #74A3E1; }
          .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .accordion .card .card-body .form-control {
            font-size: 0.75rem;
            border-color: #74A3E1; }
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .enabled-filters .filters-badge {
        position: absolute;
        font-size: 0.625rem;
        color: #FFFFFF;
        top: -5px;
        right: -5px;
        width: 15px;
        height: 15px;
        background-color: #00AB84; }
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .enabled-filters .clean-filters-badge {
        position: absolute;
        font-size: 0.625rem;
        top: 7px;
        right: -1px;
        width: 15px;
        height: 15px;
        background-color: transparent; }
      .ccm-patients-list .ccm-patient-panel-search .ccm-search-wrapper .enabled-filters .inactive .filters-badge {
        background-color: #C4C4C4; }
  .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container {
    transition: all 0.5s ease;
    height: 40px;
    min-height: 40px;
    overflow: hidden; }
    .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container .ccm-table-pager .btn-group .btn {
      line-height: 1.2;
      padding: 0.1rem 0.5rem;
      font-size: 0.75rem; }
    .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container.is-opened {
      height: 80px;
      min-height: 80px; }
      .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container.is-opened.is-cn-role {
        height: 70px;
        min-height: 70px; }
    .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container.is-closed .ccm-table-pager .btn-group .btn {
      font-size: 0.75rem;
      padding: 0.1rem 0.5rem; }
    .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container .sorting-results span {
      font-size: 0.875rem;
      color: #74A3E1;
      font-weight: 300; }
    .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container .sorting-results .btn {
      color: #000000; }
      .ccm-patients-list .ccm-patient-list-content .ccm-sorting-container .sorting-results .btn:hover {
        color: #4A6784; }
  .ccm-patients-list .ccm-patient-list-content .ccm-actions-container {
    transition: all 0.5s ease;
    height: 40px;
    min-height: 40px; }
    .ccm-patients-list .ccm-patient-list-content .ccm-actions-container.is-opened {
      height: 80px;
      min-height: 80px; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container.is-opened.is-cn-role {
        height: 70px;
        min-height: 70px; }
    .ccm-patients-list .ccm-patient-list-content .ccm-actions-container.is-closed .patient-list-actions .btn {
      padding: 0;
      margin-bottom: 0; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container.is-closed .patient-list-actions .btn i[class^="bi-"] {
        font-size: 1rem; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container.is-closed .patient-list-actions .btn > span {
        flex-direction: row; }
    .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-list-actions .btn {
      font-size: 0.75rem;
      flex-grow: 1;
      font-weight: 400;
      padding: 0.25rem;
      border-radius: 0.5rem;
      margin-bottom: 0.25rem;
      transition: all 0.15s ease-in-out; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-list-actions .btn i[class^="bi-"] {
        font-size: 1.25rem;
        transition: all 0.5s ease; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-list-actions .btn > span {
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease; }
        .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-list-actions .btn > span span {
          line-height: 1.35;
          text-align: left; }
    .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-progress-bars {
      font-size: 0.875rem;
      color: #2C3E50; }
      .ccm-patients-list .ccm-patient-list-content .ccm-actions-container .patient-progress-bars .metric__title {
        font-size: 0.8125rem;
        font-weight: 500; }
  .ccm-patients-list .ccm-patient-list-content .ccm-table-container {
    overflow-y: auto;
    overflow-x: hidden; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(229, 229, 229, 0.5);
      border: 1px solid transparent;
      border-bottom-color: #E5E5E5; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table.table-striped tbody tr:nth-of-type(even) {
      border: 1px solid #E5E5E5;
      border-left-color: transparent; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table.table-striped tbody tr:hover {
      background-color: #E5E5E5; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table [class^='patientCol__'] {
      height: 100%;
      border: none;
      padding: 0.35rem 0.25rem;
      vertical-align: middle; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table [class^='patientCol__']:last-child {
        padding: 0.5rem 0; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table .patientCol__status {
      width: 4px; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead {
      background-color: #FFFFFF; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead th,
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead th .btn {
        font-size: 0.75rem;
        line-height: 1.2;
        white-space: nowrap;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        border: none; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead th .btn:hover,
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead th .btn .btn:hover {
          color: rgba(0, 0, 0, 0.8); }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead .patientCol__actionSelect .dropdown-toggle {
        padding: 0;
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.6);
        text-transform: uppercase; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead .patientCol__actionSelect .dropdown-toggle::after {
          margin-left: 0; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead .patientCol__actionSelect .dropdown-menu .dropdown-item,
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table thead .patientCol__actionSelect .dropdown-menu .dropdown-item-text {
        font-size: 0.75rem;
        font-weight: 400;
        text-transform: none; }
    .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody {
      font-size: 0.875rem;
      color: #2C3E50; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .avatar-icon {
        font-size: 1.125rem;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        position: relative; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .avatar-icon .avatar-badge {
          position: absolute;
          font-size: 0.625rem;
          top: -7px;
          right: -3px;
          width: 15px;
          height: 15px;
          background-color: #E74C3C; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .info-icons {
        min-width: 15px; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .patient__name,
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .patient__gender,
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .patient__id {
        line-height: 1.4; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__info .patient__id {
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.35); }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__conditions {
        max-width: 325px; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__rafUplift,
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__monthlyCalls {
        font-size: 0.75rem; }
      .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm {
        line-height: 1.2; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .ccm-on-hold {
          font-size: 1.375rem; }
          @media (max-width: 1200px) {
            .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .ccm-on-hold {
              font-size: calc(1.2625rem + 0.15vw) ; } }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .ccm-info-label {
          font-size: 0.75rem;
          color: #74A3E1;
          display: block; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .ccm-info-time {
          font-size: 0.75rem; }
        .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .line {
          width: 50px;
          margin-bottom: 7px;
          border-radius: 4px;
          background-color: #8095B1; }
          .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .line:last-child {
            margin-bottom: 0; }
          .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .line div.full {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px; }
          .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .line div.approved {
            background-color: #00AB84; }
          .ccm-patients-list .ccm-patient-list-content .ccm-table-container table tbody .patientCol__totalCcm .line div.pending {
            background-color: #E1CD00; }

.search-container {
  margin-bottom: 18px;
  position: relative; }
  .search-container .status-select .Select-control {
    overflow: visible; }
  .search-container .status-select .status-select-item {
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .search-container .label {
    padding: 0;
    margin-bottom: 2px;
    position: relative; }
    .search-container .label .collapse-icon {
      margin-bottom: 10px;
      position: absolute;
      right: 12px;
      cursor: pointer; }
      .search-container .label .collapse-icon::after {
        content: '';
        border: 5px solid #999;
        border-right-color: transparent;
        border-top: none;
        border-left-color: transparent;
        width: 0;
        display: inline-block;
        margin-left: 4px;
        transform: translateY(-3px); }
      .search-container .label .collapse-icon.collapse::after {
        transform: rotate(180deg) translateY(3px); }
  .search-container input {
    width: 100%;
    box-sizing: border-box; }
    .search-container input.applied {
      border-bottom: 4px solid #8095B1; }
    .search-container input.invalid {
      border-bottom-color: #FF2400; }
  .search-container .validation-label {
    position: absolute;
    font-size: 11px;
    color: #FF2400;
    top: 16px;
    white-space: nowrap;
    background-color: #FFFFFF;
    font-weight: 700;
    z-index: 1; }
  .search-container .Select.applied {
    border-bottom: 4px solid #8095B1; }
    .search-container .Select.applied .Select-control {
      background: none; }
  .search-container .editable-input {
    width: 100%; }
  .search-container .custom-checkbox {
    margin-bottom: 5px; }

.patients-change-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px; }

.fixed-list-header-info-statistic {
  text-align: right; }

.next-action-date-selection-container {
  display: flex;
  justify-content: center; }
  .next-action-date-selection-container .icon {
    font-size: 24px; }

.quick-filter-selected {
  position: relative; }
  .quick-filter-selected .avatar-badge {
    position: absolute;
    font-size: 0.625rem;
    top: -14px;
    right: -52px;
    width: 20px;
    height: 20px;
    background-color: #E74C3C;
    color: #FFFFFF; }

.quick-filter-unselected {
  position: relative; }
  .quick-filter-unselected .avatar-badge {
    position: absolute;
    font-size: 0.625rem;
    top: -10px;
    right: -21px;
    width: 20px;
    height: 20px;
    background-color: #E74C3C;
    color: #FFFFFF; }

/* ------------------------
    Menu
------------------------- */
.ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a, .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a, .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a, .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a {
  color: #8095B1;
  border-left: 4px solid transparent;
  padding: 0.5rem 2rem; }
  .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a.disabled, .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a.disabled, .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a.disabled, .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a.disabled {
    color: #929292;
    pointer-events: none; }
  .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a:hover, .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a:hover, .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a:hover, .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a:hover, .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a.active:not(.subLink), .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a.active:not(.subLink), .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a.active:not(.subLink), .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a.active:not(.subLink) {
    color: #273342;
    background-color: rgba(116, 163, 225, 0.15); }
  .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a.active:not(.subLink), .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a.active:not(.subLink), .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a.active:not(.subLink), .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a.active:not(.subLink) {
    border-left: 5px solid #74A3E1; }
  .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a.subLink, .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a.subLink, .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a.subLink, .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a.subLink {
    padding: 0.5rem 3rem; }
    .ccm-patient-main .ccm-patient-panel-menu .ccm-patient-menu-wrapper .patient-menu-items a.subLink.active, .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-menu-items a.subLink.active, .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper .reports-menu-items a.subLink.active, .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper .flight-plan-menu-items a.subLink.active {
      color: #273342; }

.menu-button {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 6px; }
  .menu-button:hover {
    opacity: .7; }
  .menu-button .burger-row {
    width: 100%;
    height: 15%;
    background-color: #273342; }

.communication {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; }

.container {
  background-color: #FFFFFF;
  border-collapse: collapse;
  margin-bottom: 20px; }
  .container:last-child {
    margin-bottom: 0; }
  .container .title {
    text-align: left;
    padding: 20px 26px;
    border: 1px solid #E5E5E5;
    position: relative;
    font-weight: 700; }
    .container .title:after {
      content: ' ';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 49%;
      right: 20px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      transition: border .1s ease; }
  .container .content {
    overflow: hidden;
    border: 1px solid #E5E5E5;
    border-top: none;
    transition: height .4s ease; }
    .container .content > div {
      padding: 1px; }
  .container .content--border {
    border-top: 1px solid #E5E5E5; }
  .container.container-collapsible.opened .title:after {
    border-bottom: 5px solid #273342; }
  .container.container-collapsible.closed .title:after {
    border-top: 5px solid #273342; }
  .container.container-collapsible.closed .content {
    height: 0 !important;
    border-bottom: none; }
  .container.container-collapsible.fully-opened .content {
    overflow: visible; }
  .container.container-collapsible .title {
    cursor: pointer; }
    .container.container-collapsible .title:hover {
      border-bottom-color: #498FE1; }

.flex-container {
  display: flex;
  flex-wrap: wrap; }
  .flex-container .box {
    box-sizing: border-box; }
    .flex-container .box.box-full {
      flex-basis: 100%;
      width: 100%;
      min-width: 350px; }
    .flex-container .box.box-half {
      flex-basis: 50%;
      width: 50%;
      min-width: 350px; }
    .flex-container .box.box-third {
      width: 33%; }
    .flex-container .box.box-quarter {
      flex-basis: 25%;
      width: 25%;
      min-width: 249px; }
      @media only screen and (max-width: 1023px) {
        .flex-container .box.box-quarter {
          flex-basis: 100%;
          width: 100%; } }
    .flex-container .box.box-triple {
      flex-grow: 3; }
    .flex-container .box .row {
      padding: 6px 20px; }
    .flex-container .box .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden; }
    .flex-container .box .inner-box {
      display: flex;
      flex-direction: column;
      padding: 16px 30px; }
    .flex-container .box.other .inner-box {
      padding-bottom: 8px; }
      .flex-container .box.other .inner-box:last-child {
        padding-top: 2px;
        padding-bottom: 10px; }
  .flex-container .label {
    padding: 0;
    cursor: pointer;
    width: 100%;
    color: #273342;
    user-select: none; }
  .flex-container .value {
    padding: 0;
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: #8095B1;
    user-select: none; }
  .flex-container.static .label {
    cursor: default; }
  .flex-container.static .value {
    cursor: default; }
    .flex-container.static .value.text-center {
      text-align: center; }
  .flex-container.center {
    justify-content: center; }

div.iradio {
  padding: 0; }
  div.iradio > input[type='radio'] {
    /* Hide the input, but have it still be clickable */
    display: none;
    opacity: 0;
    float: left;
    width: 1px; }
  div.iradio > input[type='radio'] + label {
    display: block;
    float: left;
    margin: 0;
    clear: none;
    padding: 10px 28px 0;
    height: 32px;
    cursor: pointer;
    background: transparent;
    color: #273342;
    border: 1px solid #E5E5E5;
    border-right: 0;
    z-index: 1;
    position: relative; }
    div.iradio > input[type='radio'] + label:first-of-type {
      border-radius: 4px 0 0 4px; }
    div.iradio > input[type='radio'] + label:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #E5E5E5; }
  div.iradio > input[type='radio']:checked + label {
    border-radius: 4px;
    z-index: 2;
    border: 1px solid #679BEF;
    background: #679BEF;
    box-shadow: 2px 2px 2px #acc9f6;
    color: #FFFFFF; }
    div.iradio > input[type='radio']:checked + label:not(:first-of-type) {
      margin-left: -2px;
      padding-left: 30px; }
    div.iradio > input[type='radio']:checked + label:last-of-type {
      margin-right: -2px;
      padding-right: 30px; }
    div.iradio > input[type='radio']:checked + label + input + label {
      border-left: 0; }

.flex-wrapper {
  display: flex;
  align-items: center; }

.open-toggle {
  cursor: pointer;
  position: relative; }
  .open-toggle:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    right: -10px;
    top: -2px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; }
  .open-toggle.opened:after {
    border-bottom: 5px solid #273342; }
  .open-toggle.closed:after {
    border-top: 5px solid #273342; }

.text-center {
  text-align: center; }

.full-flex-width {
  flex: 1 0 auto;
  box-sizing: border-box; }

.box .inner-box .value {
  flex: 0 1 auto; }

@media only screen and (max-width: 1023px) {
  .order-1-tablet {
    order: 1; } }

.relative {
  position: relative; }

.title-with-button,
.title-billing-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 26px;
  min-height: 64px;
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
  font-weight: 700; }

.title-with-button .button {
  margin: 0;
  font-weight: 400; }

.title-with-button .collapse-icon {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  padding: 0; }
  .title-with-button .collapse-icon::after {
    content: '';
    border: 9px solid #273342;
    border-right-color: transparent;
    border-top: none;
    border-left-color: transparent;
    width: 0;
    display: inline-block;
    margin-left: 10px; }
  .title-with-button .collapse-icon.collapse::after {
    transform: rotate(180deg); }

.title-with-button .title-icon {
  display: flex;
  align-items: center;
  font-size: inherit;
  margin: 0; }
  .title-with-button .title-icon img {
    width: 20px;
    height: 20px;
    margin-right: 5px; }

.billing-table-title {
  margin-right: 40px; }

/* ------------------------
    Patient Care Plan
------------------------- */
.patient-care-plan .care-plan-goals .card-body table thead tr .goal__target,
.patient-care-plan .care-plan-goals .card-body table tbody tr .goal__target {
  width: 25%;
  max-width: 25%; }

.patient-care-plan .care-plan-goals .card-body table thead tr .goal__date,
.patient-care-plan .care-plan-goals .card-body table thead tr .goal__status,
.patient-care-plan .care-plan-goals .card-body table tbody tr .goal__date,
.patient-care-plan .care-plan-goals .card-body table tbody tr .goal__status {
  width: 150px;
  max-width: 150px; }

.patient-care-plan .care-plan-goals .card-body table thead tr .goal__action,
.patient-care-plan .care-plan-goals .card-body table tbody tr .goal__action {
  width: 30px;
  max-width: 30px; }

.patient-care-plan .care-plan-goals .card-body table tbody tr td .goal__title,
.patient-care-plan .care-plan-goals .card-body table tbody tr td .goal__status {
  font-weight: 500; }

.patient-care-plan .care-plan-goals .card-body table tbody tr td .goal__status {
  line-height: 1.3; }

.patient-care-plan .care-plan-goals .card-body table tbody tr td .card-body {
  color: #2C3E50; }
  .patient-care-plan .care-plan-goals .card-body table tbody tr td .card-body .goal__progress {
    cursor: pointer;
    font-weight: 300; }
    .patient-care-plan .care-plan-goals .card-body table tbody tr td .card-body .goal__progress i[class^="bi-"] {
      visibility: hidden; }
    .patient-care-plan .care-plan-goals .card-body table tbody tr td .card-body .goal__progress:hover {
      color: #74A3E1; }
      .patient-care-plan .care-plan-goals .card-body table tbody tr td .card-body .goal__progress:hover i[class^="bi-"] {
        visibility: visible; }

.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__createdDate,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__createdDate {
  width: 10%;
  max-width: 10%;
  cursor: pointer; }

.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__createdBy,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__createdBy {
  width: 15%;
  max-width: 15%;
  cursor: pointer; }

.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__title,
.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__type,
.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__priority,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__title,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__type,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__priority {
  width: 20%;
  max-width: 20%;
  cursor: pointer; }

.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__solve,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__solve {
  width: 5%;
  max-width: 5%; }

.patient-care-plan .care-plan-interventions .card-body table thead tr .intervention__action,
.patient-care-plan .care-plan-interventions .card-body table tbody tr .intervention__action {
  width: 3%;
  max-width: 3%; }

.patient-care-plan .care-plan-interventions .card-body table tbody tr td .card-body {
  color: #2C3E50; }
  .patient-care-plan .care-plan-interventions .card-body table tbody tr td .card-body .intervention__description {
    font-weight: 300; }

.patient-care-plan .care-plan-additional-info .card-body table thead tr .information__createdAt,
.patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__createdAt {
  width: 20%;
  max-width: 20%; }

.patient-care-plan .care-plan-additional-info .card-body table thead tr .information__createdBy,
.patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__createdBy {
  width: 15%;
  max-width: 15%; }

.patient-care-plan .care-plan-additional-info .card-body table thead tr .information__note,
.patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__note {
  width: 57%;
  max-width: 57%; }
  .patient-care-plan .care-plan-additional-info .card-body table thead tr .information__note .truncate-text,
  .patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__note .truncate-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical; }
    .patient-care-plan .care-plan-additional-info .card-body table thead tr .information__note .truncate-text:hover,
    .patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__note .truncate-text:hover {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 20;
      line-clamp: 20;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical; }

.patient-care-plan .care-plan-additional-info .card-body table thead tr .information__solve,
.patient-care-plan .care-plan-additional-info .card-body table tbody tr .information__solve {
  width: 5%;
  max-width: 5%; }

.valid-input-wrapper {
  position: relative; }

.datetime-validation-label {
  position: absolute;
  font-size: 11px;
  color: #FF2400;
  top: -17px;
  white-space: nowrap;
  background-color: #FFFFFF;
  font-weight: 700;
  z-index: 0; }

.screening-group {
  text-align: left;
  font-size: 0.875rem; }

.screening-with-border {
  border: 1px solid #000;
  padding-top: 2rem;
  position: relative;
  margin-bottom: 1.5rem; }
  .screening-with-border .screening-group-title {
    background-color: #fff;
    position: absolute;
    top: -0.5rem;
    padding: 0 5px;
    width: max-content;
    max-width: 99%;
    line-height: 1rem;
    left: 50%;
    transform: translateX(-50%); }

.problem-list-table,
.billing-history-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; }
  @media only screen and (max-width: 1023px) {
    .problem-list-table,
    .billing-history-table {
      font-size: 12px; } }
  .problem-list-table thead,
  .billing-history-table thead {
    color: #8095B1;
    text-transform: uppercase;
    font-weight: 700; }
    .problem-list-table thead td,
    .billing-history-table thead td {
      padding: 18px 3px 10px; }
  .problem-list-table tbody td,
  .billing-history-table tbody td {
    padding: 14px 3px; }
  .problem-list-table tbody .draggable-container,
  .billing-history-table tbody .draggable-container {
    cursor: move; }
  .problem-list-table td,
  .billing-history-table td {
    border-bottom: 1px solid #E5E5E5; }
  .problem-list-table .label,
  .billing-history-table .label {
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    padding: 4px 16px;
    min-width: 0;
    background-color: #E67E22;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700; }
    @media only screen and (max-width: 1023px) {
      .problem-list-table .label,
      .billing-history-table .label {
        font-size: 10px; } }
  .problem-list-table .text-column,
  .billing-history-table .text-column {
    text-align: left;
    word-wrap: break-word;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px; }
    .problem-list-table .text-column:first-child,
    .billing-history-table .text-column:first-child {
      padding-left: 26px; }
    .problem-list-table .text-column .text-column-tooltip,
    .billing-history-table .text-column .text-column-tooltip {
      position: relative;
      white-space: inherit;
      text-overflow: inherit;
      overflow: inherit; }
      .problem-list-table .text-column .text-column-tooltip .text-column-tooltip-wrapper,
      .billing-history-table .text-column .text-column-tooltip .text-column-tooltip-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
  .problem-list-table .mark-column,
  .billing-history-table .mark-column {
    width: 110px;
    position: relative; }
    @media only screen and (max-width: 1023px) {
      .problem-list-table .mark-column,
      .billing-history-table .mark-column {
        width: 70px; } }
  .problem-list-table .date-column,
  .billing-history-table .date-column {
    width: 100px;
    padding-right: 8px; }
    @media only screen and (max-width: 1023px) {
      .problem-list-table .date-column,
      .billing-history-table .date-column {
        width: 90px; } }
    .problem-list-table .date-column .one-field-date-picker,
    .billing-history-table .date-column .one-field-date-picker {
      width: auto; }
      .problem-list-table .date-column .one-field-date-picker:not(.invalid) .dateText:not(.react-datepicker-ignore-onclickoutside),
      .billing-history-table .date-column .one-field-date-picker:not(.invalid) .dateText:not(.react-datepicker-ignore-onclickoutside) {
        border-bottom-color: transparent;
        padding: 0;
        color: inherit; }
  .problem-list-table .sortable,
  .billing-history-table .sortable {
    position: relative;
    cursor: pointer; }
    .problem-list-table .sortable:before, .problem-list-table .sortable:after,
    .billing-history-table .sortable:before,
    .billing-history-table .sortable:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      right: 25px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; }
      @media only screen and (max-width: 1023px) {
        .problem-list-table .sortable:before, .problem-list-table .sortable:after,
        .billing-history-table .sortable:before,
        .billing-history-table .sortable:after {
          right: 10px; } }
    .problem-list-table .sortable:before,
    .billing-history-table .sortable:before {
      top: calc(60% - 10px);
      border-bottom: 5px solid #8095B1; }
    .problem-list-table .sortable:after,
    .billing-history-table .sortable:after {
      top: 60%;
      border-top: 5px solid #8095B1; }
    .problem-list-table .sortable.active.reverse:after,
    .billing-history-table .sortable.active.reverse:after {
      border-top: 5px solid #273342; }
    .problem-list-table .sortable.active:not(.reverse):before,
    .billing-history-table .sortable.active:not(.reverse):before {
      border-bottom: 5px solid #273342; }
  .problem-list-table .sortable,
  .problem-list-table .sortable-value,
  .billing-history-table .sortable,
  .billing-history-table .sortable-value {
    padding-right: 35px; }
    @media only screen and (max-width: 1023px) {
      .problem-list-table .sortable,
      .problem-list-table .sortable-value,
      .billing-history-table .sortable,
      .billing-history-table .sortable-value {
        padding-right: 20px; } }
  .problem-list-table .editable-input,
  .billing-history-table .editable-input {
    width: 100%; }
    .problem-list-table .editable-input input,
    .billing-history-table .editable-input input {
      background-color: transparent; }
  .problem-list-table .add-column,
  .billing-history-table .add-column {
    width: 30px; }
  .problem-list-table .add-billing,
  .billing-history-table .add-billing {
    border: none;
    background-color: transparent;
    background-image: url("./images/icon-plus.svg");
    width: 20px;
    background-size: cover;
    height: 20px;
    cursor: pointer;
    margin-left: 5px; }
  .problem-list-table .dragging-row,
  .billing-history-table .dragging-row {
    opacity: .8;
    background-color: #DAE9F6; }
  .problem-list-table .static-validation-label,
  .billing-history-table .static-validation-label {
    position: absolute;
    font-size: 11px;
    color: #FF2400;
    top: 0;
    white-space: nowrap;
    font-weight: 700;
    z-index: 1;
    width: 91%; }
    .problem-list-table .static-validation-label::after,
    .billing-history-table .static-validation-label::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #FF2400;
      top: 27px;
      position: absolute; }
  .problem-list-table .indicator-column,
  .billing-history-table .indicator-column {
    position: relative; }
  .problem-list-table .invalid-indicator,
  .billing-history-table .invalid-indicator {
    color: #FF2400;
    border: 1px solid #FF2400;
    border-radius: 50%;
    width: 11px;
    height: 12px;
    display: block;
    position: absolute;
    top: 7px;
    right: 6px;
    font-size: 10px;
    line-height: 12px;
    padding-left: 1px; }

/* ------------------------
    Patient Medications
------------------------- */
.patient-medications .patient-medication-card {
  text-align: left;
  border-radius: 0.75rem;
  border: 1px solid #4A6784;
  min-height: 135px; }
  .patient-medications .patient-medication-card.inactive-medication {
    border-color: #C4C4C4;
    background-color: rgba(196, 196, 196, 0.3); }
  .patient-medications .patient-medication-card .medication-title {
    line-height: 1.2; }
  .patient-medications .patient-medication-card .medication-route,
  .patient-medications .patient-medication-card .medication-dosage {
    line-height: 1.2;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical; }
  .patient-medications .patient-medication-card .medication-source,
  .patient-medications .patient-medication-card .medication-dosage {
    font-size: 0.875rem; }

/* ------------------------
    Patient Diagnostics
------------------------- */
.patient-diagnostics .patient-diagnostic-card {
  text-align: left;
  border-radius: 0.75rem;
  border: 1px solid #4A6784;
  min-height: 135px; }
  .patient-diagnostics .patient-diagnostic-card .diagnostic-title {
    line-height: 1.2; }
  .patient-diagnostics .patient-diagnostic-card .diagnostic-subtitle,
  .patient-diagnostics .patient-diagnostic-card .diagnostic-test {
    line-height: 1.2;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical; }
  .patient-diagnostics .patient-diagnostic-card .diagnostic-date,
  .patient-diagnostics .patient-diagnostic-card .diagnostic-test {
    font-size: 0.875rem; }

/* ------------------------
    Buttons
------------------------- */
.btn {
  box-shadow: none; }
  .btn.focus, .btn:focus {
    box-shadow: none; }
  .btn.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.625rem;
    line-height: 1; }
  .btn.btn-primary {
    color: #FFFFFF; }
  .btn.btn-info-gray {
    color: #000000;
    border-color: #E5E5E5;
    background-color: #F2F2F2; }
    .btn.btn-info-gray.focus, .btn.btn-info-gray:focus {
      color: #74A3E1;
      background-color: #E6EDFE; }
  .btn.btn-light {
    color: #000000;
    border-color: #E5E5E5; }
    .btn.btn-light.focus, .btn.btn-light:focus {
      background-color: #FFFFFF; }
    .btn.btn-light:not(.disabled).active {
      background-color: rgba(116, 163, 225, 0.1);
      border-color: #74A3E1; }
  .btn.btn-outline-primary:hover {
    color: #FFFFFF; }
  .btn.btn-link-dark {
    color: #2C3E50; }
    .btn.btn-link-dark:hover {
      color: #74A3E1; }
    .btn.btn-link-dark:disabled {
      color: #8095B1; }

.button {
  border-radius: 4px;
  border: 1px solid #498FE1;
  color: #498FE1;
  background-color: #FFFFFF;
  cursor: pointer;
  position: relative;
  padding: 8px 12px;
  font-size: 16px;
  text-align: center; }
  .button.small {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .button:hover {
    color: #FFFFFF;
    background-color: #498FE1; }
  .button.action-button {
    border-radius: 0;
    border-color: #E5E5E5;
    color: #273342;
    background-color: #FFFFFF; }
    .button.action-button:hover {
      border-color: #498FE1;
      color: #498FE1;
      background-color: #FFFFFF; }
    .button.action-button:disabled {
      border: 1px solid #8095B1;
      color: #8095B1;
      background-color: #FFFFFF;
      cursor: not-allowed; }
      .button.action-button:disabled:hover {
        border-color: #8095B1;
        color: #8095B1;
        background-color: #FFFFFF; }
  .button.active-button {
    color: #FFFFFF;
    background-color: #498FE1; }
    .button.active-button:hover {
      color: #498FE1;
      background-color: #FFFFFF; }
    .button.active-button:disabled {
      border: 1px solid #8095B1;
      color: #8095B1;
      background-color: #FFFFFF;
      cursor: not-allowed; }
      .button.active-button:disabled:hover {
        border-color: #8095B1;
        color: #8095B1;
        background-color: #FFFFFF; }
  .button.delete-button {
    margin: 10px; }
    .button.delete-button:hover {
      border-color: #FF2400;
      color: #FC8F8F; }
  .button.disabled-button {
    border: 1px solid #8095B1;
    color: #8095B1;
    background-color: #FFFFFF;
    cursor: auto; }
    .button.disabled-button:hover {
      border-color: #8095B1;
      color: #8095B1;
      background-color: #FFFFFF; }
  .button.date-choose {
    border-radius: 0;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #FFFFFF #FFFFFF #8095B1;
    padding: 0 6px 4px;
    margin: 0;
    height: 34px;
    text-align: left;
    color: #273342; }

.delete-link {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  color: #8095B1;
  margin-left: 9px; }
  .delete-link:hover, .delete-link:focus {
    color: #498FE1; }
  .delete-link.is-disabled {
    cursor: default;
    opacity: .5; }

.edit-button {
  margin: 10px; }
  .edit-button:hover {
    border-color: #498FE1;
    color: #498FE1; }

.long-button {
  width: auto;
  padding: 0 15px;
  margin: 20px 0 0;
  height: 40px;
  line-height: 40px;
  border-color: transparent; }
  .long-button:hover {
    border-color: #498FE1;
    color: #498FE1; }

.alert-button {
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #E74C3C;
  border-color: transparent; }
  .alert-button:hover {
    background-color: #ef8b80; }

.accept-button {
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #00AB84;
  border-color: transparent; }
  .accept-button:hover {
    background-color: #00deab; }

.select-unselect-button {
  text-transform: uppercase;
  color: #273342;
  border: 1px solid #E5E5E5; }
  .select-unselect-button:hover {
    color: #273342;
    background-color: #E5E5E5; }

.warning-button {
  text-transform: uppercase;
  color: #273342;
  background-color: #E1CD00;
  border-color: transparent; }
  .warning-button:hover {
    color: #273342;
    background-color: #ffea15; }

.form-button {
  padding: 10px; }
  .form-button.left {
    display: flex;
    justify-content: flex-start; }
  .form-button .button {
    margin-right: 20px;
    min-width: 50px;
    text-transform: uppercase; }

.download-button {
  cursor: pointer;
  width: 32px;
  height: 22px;
  background-image: url("./images/download.svg");
  background-size: cover; }
  .download-button:hover {
    background-image: url("./images/download-active.svg"); }

.drop-down-button {
  padding-right: 34px; }
  .drop-down-button:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    right: 24px;
    border-left: 1px solid #FFFFFF; }
  .drop-down-button:after {
    content: '';
    display: block;
    position: absolute;
    top: 17px;
    right: 8px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #FFFFFF; }
  .drop-down-button.warning-button:before {
    border-left: 1px solid #273342; }
  .drop-down-button.warning-button:after {
    border-top: 5px solid #273342; }

.drop-down-button-list {
  position: absolute;
  top: 0;
  right: 24px;
  z-index: 2;
  min-width: calc(100% - 24px);
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  text-align: left;
  box-shadow: 2px 2px 2px rgba(229, 229, 229, 0.5); }

.drop-down-button-list-option {
  padding: 6px 14px;
  color: #498FE1;
  cursor: pointer; }
  .drop-down-button-list-option:hover {
    color: #273342; }

.drop-down-button-list-option-status {
  white-space: nowrap; }

.blue-link {
  cursor: pointer;
  color: #498FE1;
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400; }
  .blue-link:hover {
    color: #8095B1; }
  .blue-link.is-disabled {
    cursor: default;
    opacity: .5; }

.time-tracking-section {
  font-size: 0.875rem; }
  .time-tracking-section .auditing-content {
    padding-top: 20px; }
  .time-tracking-section .activity-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #74A3E1;
    background-color: #DAE9F6;
    padding: 12px 15px;
    flex: 1 1 auto; }
    .time-tracking-section .activity-total.issues-exist {
      border: 1px solid #FF2400;
      justify-content: center;
      background-color: #F1A3A3; }
      .time-tracking-section .activity-total.issues-exist .billing-cycle {
        text-transform: lowercase; }
    .time-tracking-section .activity-total > div {
      margin-right: 2%; }
      .time-tracking-section .activity-total > div:last-child {
        margin-right: 0; }

.auditing-date-range {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .auditing-date-range .label {
    min-width: 0;
    padding-left: 0; }
  .auditing-date-range .editable-input {
    width: auto; }
  .auditing-date-range > div {
    margin: 0 10px 10px 0; }
    .auditing-date-range > div:last-child {
      margin-right: 0; }
  .auditing-date-range .button {
    align-self: center;
    height: auto; }

.time-tracking-modal .dialog-content {
  padding: 20px 30px 0 30px; }

.time-tracking-modal .auditing-date-range {
  flex-wrap: nowrap;
  margin: 0; }

.activity {
  margin-bottom: 0.5rem;
  border: 1px solid #E5E5E5; }

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 1023px) {
    .activity-header {
      flex-wrap: wrap; } }
  .activity-header > div {
    margin-right: 7px; }
    .activity-header > div:last-child {
      margin-right: 0; }

.activity-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%; }
  .activity-title.editable-input {
    overflow: visible; }
  @media only screen and (max-width: 1023px) {
    .activity-title {
      width: 100%;
      max-width: 100%;
      margin-right: 0; } }
  .activity-title .patients-list-select-container.invalid {
    border-bottom: 2px solid #FF2400; }
  .activity-title .patients-list-select {
    padding-right: 10px;
    position: relative;
    min-height: 22px; }
    .activity-title .patients-list-select:after {
      position: absolute;
      top: 50%;
      right: 2px; }
  .activity-title .patients-list-select-other-input--fwidth {
    width: 100%;
    flex: 0 1 auto; }
  .activity-title .Select, .activity-title .Select-placeholder {
    font-size: 0.875rem;
    color: #1D2633 !important; }
    .activity-title .Select::-webkit-input-placeholder, .activity-title .Select-placeholder::-webkit-input-placeholder {
      color: #1D2633; }
    .activity-title .Select::-moz-placeholder, .activity-title .Select-placeholder::-moz-placeholder {
      color: #1D2633;
      opacity: 1; }
    .activity-title .Select::-moz-placeholder, .activity-title .Select-placeholder::-moz-placeholder {
      color: #1D2633;
      opacity: 1; }
    .activity-title .Select:-ms-input-placeholder, .activity-title .Select-placeholder:-ms-input-placeholder {
      color: #1D2633; }
  .activity-title .Select-placeholder {
    padding-right: 25px; }
  .activity-title .Select {
    width: 100%; }
    .activity-title .Select.error {
      border-color: #FF2400; }
  .activity-title .Select-input {
    max-width: 100%;
    overflow-x: hidden;
    width: 100%; }
  .activity-title .Select-input > input {
    max-width: 300px;
    width: 100% !important; }

.activity-cn-name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 17%; }
  @media only screen and (max-width: 1023px) {
    .activity-cn-name {
      width: 100%;
      padding: 0;
      margin-right: 5px; } }

.activity-date {
  flex: 0 0 auto;
  text-align: left; }

.activity-label {
  text-align: center;
  width: 65px; }

.activity-label-info,
.activity-label-success {
  font-size: 0.6875rem;
  border-radius: 4px;
  color: #FFFFFF;
  min-width: 0;
  padding: 3px 0;
  text-transform: uppercase; }

.activity-label-info {
  background-color: #E67E22; }

.activity-label-success {
  background-color: #00AB84; }

.activity-download-button {
  flex: 0 0 auto; }

.direct-contact {
  font-size: 11px;
  cursor: pointer; }

.direct-contact-toggle {
  border-radius: 50px;
  height: 20px;
  background-color: #8095B1;
  width: 41px;
  transition: background-color .4s;
  position: relative;
  margin: 0 auto 2px; }
  .direct-contact-toggle:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #FFFFFF;
    position: absolute;
    left: 5px;
    right: auto;
    top: 50%;
    transform: translateY(-50%); }
  .direct-contact-toggle.active {
    background-color: #318FFD; }
    .direct-contact-toggle.active:before {
      left: auto;
      right: 5px; }

.direct-contact-title {
  width: 56px; }

.activity-ccm-container {
  font-weight: 700; }
  .activity-ccm-container > div {
    white-space: nowrap; }
    .activity-ccm-container > div > div {
      display: inline-block;
      vertical-align: middle; }
      @media only screen and (max-width: 1023px) {
        .activity-ccm-container > div > div.minutes {
          margin-right: 0; } }
      .activity-ccm-container > div > div.line {
        display: block;
        width: 45px; }
        @media only screen and (max-width: 1023px) {
          .activity-ccm-container > div > div.line {
            display: none; } }
  .activity-ccm-container .editable-input.duration {
    width: 33px;
    text-align: right;
    padding-right: 4px; }
    .activity-ccm-container .editable-input.duration input {
      padding: 2px; }
  .activity-ccm-container .editable-input.phone {
    text-align: center;
    padding-right: 4px;
    border: none; }
    .activity-ccm-container .editable-input.phone input {
      padding: 2px;
      cursor: pointer; }
      .activity-ccm-container .editable-input.phone input:not(:focus) {
        border: none; }
  .activity-ccm-container .editable-input.phone.time-tracking-history .validation-label {
    top: initial;
    bottom: -19px; }
    .activity-ccm-container .editable-input.phone.time-tracking-history .validation-label div::before {
      padding-right: 5px; }

@media only screen and (max-width: 1023px) {
  .activity-notes textarea {
    width: 90%; } }

/* ------------------------
    Patients Summary
------------------------- */
.patient-summary .patient-summary-title {
  font-weight: 500; }

.patient-summary .patient-summary-status .summary-status-info span:first-child {
  font-weight: 300; }

.patient-summary .patient-summary-status .summary-status-info span:last-child {
  font-weight: 500; }

.patient-summary .patient-summary-status .summary-status-info .call-attempts {
  font-size: 0.875rem; }

.patient-summary .patient-summary-status .summary-status-buttons .btn {
  margin: 0 0.25rem; }

.patient-summary .patient-summary-status .summary-status-buttons .dropdown .avatar .avatar-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center; }

.patient-summary .patient-summary-status .summary-status-buttons .dropdown .avatar .avatar-label {
  flex: 1 1 0;
  line-height: 1.2;
  font-size: 0.875rem; }

.patient-summary .patient-summary-status .summary-status-buttons .dropdown .dropdown-menu.show {
  left: auto !important;
  right: 0.5rem !important; }

.patient-summary .patient-summary-general .patient-pcp-info .next-apt-visit:hover,
.patient-summary .patient-summary-general .patient-pcp-info .future-insurance:hover,
.patient-summary .patient-summary-general .patient-awv-info .next-apt-visit:hover,
.patient-summary .patient-summary-general .patient-awv-info .future-insurance:hover,
.patient-summary .patient-summary-general .patient-ins-info .next-apt-visit:hover,
.patient-summary .patient-summary-general .patient-ins-info .future-insurance:hover,
.patient-summary .patient-summary-general .patient-fut-ins-info .next-apt-visit:hover,
.patient-summary .patient-summary-general .patient-fut-ins-info .future-insurance:hover {
  color: #74A3E1;
  cursor: pointer; }

.patient-summary .patient-summary-general .patient-pcp-info .future-insurance,
.patient-summary .patient-summary-general .patient-awv-info .future-insurance,
.patient-summary .patient-summary-general .patient-ins-info .future-insurance,
.patient-summary .patient-summary-general .patient-fut-ins-info .future-insurance {
  word-wrap: break-word; }

.patient-summary .patient-summary-general .note-body {
  font-size: 0.875rem; }
  .patient-summary .patient-summary-general .note-body p {
    margin-bottom: 0.75rem; }

.patient-summary nav a.nav-item.nav-link {
  color: #8095B1; }

.patient-summary nav a.nav-item.nav-link.active {
  border-width: 0 0 2px 0;
  border-bottom: solid #74A3E1;
  color: #2C3E50; }

.patient-summary nav .nav-link:hover, .patient-summary nav .nav-link:focus {
  border-color: transparent; }

.insurance {
  display: flex;
  margin-right: 10px;
  padding: 0 0 3px; }

.insurance-title {
  color: #8095B1; }

.margin-between-child-10 > * {
  margin-right: 10px; }

.patient-home-healthcare {
  margin-top: 10px;
  display: flex; }
  .patient-home-healthcare > div {
    margin-bottom: 0; }

.patient-tcm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 38px; }

.patient-eligibility-toggle {
  cursor: pointer; }
  .patient-eligibility-toggle .direct-contact-toggle {
    margin: 0 10px 0 0; }
  .patient-eligibility-toggle .active {
    background-color: #19AA64; }

.deactivate {
  pointer-events: none; }

.inactive-reason-select {
  margin-top: 10px;
  margin-bottom: 20px; }

.ineligible-reason-select {
  margin-top: 10px;
  margin-bottom: 20px; }

.other-reason-detail {
  margin-top: 20px;
  width: 312px; }

.patient-enrollment {
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-bottom: none;
  height: 100%;
  padding: 10px; }
  .patient-enrollment .input-label {
    font-size: 16px;
    font-weight: 400; }
  .patient-enrollment .title {
    padding: 24px;
    border-bottom: 1px solid #E5E5E5;
    text-align: left;
    font-weight: 700; }
  .patient-enrollment label {
    width: 100%; }
  .patient-enrollment table {
    border-collapse: separate;
    border-spacing: 1em; }
  .patient-enrollment th {
    font-size: 16px;
    font-weight: 400; }
    .patient-enrollment th:first-of-type {
      width: 60%; }
  .patient-enrollment td {
    text-align: center; }
    .patient-enrollment td:first-of-type {
      text-align: left; }
    .patient-enrollment td .custom-checkbox {
      justify-content: center; }
      .patient-enrollment td .custom-checkbox .label {
        display: none; }
  .patient-enrollment .form-button {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .patient-enrollment .form-button .button {
      min-width: 150px;
      margin: 20px auto auto;
      min-height: 45px; }
      .patient-enrollment .form-button .button:first-of-type {
        margin-top: auto; }
      .patient-enrollment .form-button .button.confirm-button {
        padding: 5px 12px; }
  .patient-enrollment .list-pointer {
    margin-right: 5px; }
  .patient-enrollment .confirm-button {
    width: 150px; }
  .patient-enrollment .question-row td:first-child {
    padding-left: 1rem; }

.title .number.blue {
  border-radius: 4px;
  background-color: #498FE1;
  color: #FFFFFF;
  padding: 0 10px;
  margin: 0 20px; }

.enrollment-list-number {
  margin-right: 3px; }

.patient-subsection {
  background-color: #FFFFFF;
  margin-bottom: 20px; }
  .patient-subsection:last-child {
    margin-bottom: 0; }

.patient-subsection-title {
  padding: 24px;
  border: 1px solid #E5E5E5;
  text-align: left;
  font-weight: 700; }

.patient-subsection-content {
  padding-top: 1px; }
  .patient-subsection-content.history-page {
    border: 1px solid #E5E5E5;
    border-top: none; }
    .patient-subsection-content.history-page .vitals {
      padding: 20px; }

.billing-history-table-wrapper {
  border: 1px solid #E5E5E5;
  border-bottom: none;
  margin: 20px 10px; }
  .billing-history-table-wrapper .billing-history-table {
    margin-bottom: 0; }

.physician {
  border-top: 2px solid #E5E5E5; }
  .physician:first-child {
    border-top: none; }

.physician-section-title {
  padding: 10px 20px;
  text-align: left;
  font-weight: 700; }

.physician-section-line {
  padding: 10px 20px;
  text-align: left; }

.physician-label {
  color: #8095B1; }

.physician-meetings.container {
  margin-bottom: 0; }
  .physician-meetings.container .title {
    border: none;
    border-top: 1px solid #E5E5E5; }
  .physician-meetings.container.opened .title {
    border-bottom: 1px solid #E5E5E5; }
  .physician-meetings.container .content {
    border: none; }

.empty-value {
  box-sizing: border-box;
  padding: 20px;
  color: #8095B1;
  text-align: center; }

.visit {
  margin: 20px;
  text-align: left;
  border: 1px solid #E5E5E5;
  padding: 10px; }

.visit-title {
  padding: 10px 0;
  font-weight: 700; }

.visit-line {
  padding: 10px 0; }

.visit-purpose {
  font-weight: 700; }

.procedures-section {
  margin: 20px;
  border: 1px solid #E5E5E5; }

.procedure-title {
  padding: 10px;
  text-align: left;
  font-weight: 700; }

.procedures {
  width: 100%;
  text-align: left;
  padding: 10px;
  border-collapse: collapse; }
  .procedures thead {
    color: #8095B1;
    text-transform: uppercase;
    font-weight: 700; }
  .procedures tr {
    height: 40px;
    border-bottom: 1px solid #E5E5E5; }
  .procedures td {
    padding: 10px; }

.procedure-title-name {
  width: 400px; }

.procedure-name {
  font-weight: 700; }

/* ------------------------
    Administration
------------------------- */
.ccm-admin-main {
  position: relative;
  flex: 1;
  overflow: hidden;
  color: #2C3E50; }
  .ccm-admin-main .ccm-admin-panel-menu {
    width: 300px;
    min-width: 300px; }
    .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper {
      position: relative; }
      .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-general-info .avatar-icon {
        font-size: 1.875rem;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        position: relative;
        text-transform: uppercase; }
        @media (max-width: 1200px) {
          .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-general-info .avatar-icon {
            font-size: calc(1.3125rem + 0.75vw) ; } }
      .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-general-info .admin__name {
        font-size: 1.25rem;
        padding-right: 3rem;
        line-height: 1.2; }
      .ccm-admin-main .ccm-admin-panel-menu .ccm-admin-menu-wrapper .admin-general-info .admin_tenant {
        font-size: 0.875rem; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__date, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__date {
    width: 15%;
    max-width: 15%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__recent-date, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__type, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__user, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__recent-date, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__type, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__user {
    width: 16%;
    max-width: 16%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__status, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__status {
    width: 13%;
    max-width: 13%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__id, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__id {
    width: 8%;
    max-width: 8%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__method, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__method {
    width: 10%;
    max-width: 10%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__action, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__action {
    text-align: right;
    width: 6%;
    max-width: 6%; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__comment, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__comment {
    width: 40%;
    max-width: 40%;
    font-style: italic;
    font-weight: 300; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table thead tr .batch__status, .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr .batch__status {
    font-weight: 500; }
  .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr td .card-body {
    color: #2C3E50; }
    .ccm-admin-main .ccm-admin-main-content .patient-outreach .patient-outreach-history .card-body table tbody tr td .card-body .batch__activity {
      font-weight: 300; }
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table thead tr .attribute__date,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table tbody tr .attribute__date {
    width: 150px;
    max-width: 150px; }
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table thead tr .attribute__deleted,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table thead tr .attribute__name,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table tbody tr .attribute__deleted,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table tbody tr .attribute__name {
    width: 20%;
    max-width: 20%; }
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table thead tr .attribute__action,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table tbody tr .attribute__action {
    text-align: right;
    width: 90px;
    max-width: 90px; }
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table thead tr .attribute__description,
  .ccm-admin-main .ccm-admin-main-content .patient-attributes .patient-attributes-list .card-body table tbody tr .attribute__description {
    width: 40%;
    max-width: 40%;
    font-style: italic;
    font-weight: 300; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__name,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__name {
    width: 15%;
    max-width: 15%; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__category,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__category {
    width: 100px;
    max-width: 100px; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__date,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__user,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__prompt-type,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__date,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__user,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__prompt-type {
    width: 150px;
    max-width: 150px; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__confirmation,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__confirmation {
    width: 20%;
    max-width: 20%; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__status,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__status {
    width: 115px;
    max-width: 115px;
    font-weight: 500; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table thead tr .prompt__action,
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr .prompt__action {
    text-align: right;
    width: 85px;
    max-width: 85px; }
  .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr td .card-body {
    color: #2C3E50; }
    .ccm-admin-main .ccm-admin-main-content .prompts-configuration .prompts .card-body table tbody tr td .card-body .prompt __activity {
      font-weight: 300; }
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table thead tr .factor__date,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table thead tr .factor__added,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table thead tr .factor__z-code,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr .factor__date,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr .factor__added,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr .factor__z-code {
    width: 150px;
    max-width: 150px; }
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table thead tr .factor__display-name,
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr .factor__display-name {
    width: 250px;
    max-width: 250px; }
  .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr td .card-body {
    color: #2C3E50; }
    .ccm-admin-main .ccm-admin-main-content .health-factor-configuration .factors .card-body table tbody tr td .card-body .prompt __activity {
      font-weight: 300; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .resource-btn {
    width: 50px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .resource-url {
    cursor: pointer; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .note {
    width: 137px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table thead tr .resource__link,
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr .resource__link {
    width: 200px;
    max-width: 200px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table thead tr .resource__phone,
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr .resource__phone {
    width: 100px;
    max-width: 100px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table thead tr .resource__name,
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr .resource__name {
    width: 250px;
    max-width: 250px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table thead tr .resource__actions,
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr .resource__actions {
    width: 25px;
    max-width: 25px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table thead tr .resource__status,
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr .resource__status {
    width: 50px;
    max-width: 50px; }
  .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr td .card-body {
    color: #2C3E50; }
    .ccm-admin-main .ccm-admin-main-content .resource-configuration .resources .card-body table tbody tr td .card-body .prompt __activity {
      font-weight: 300; }

.admin-section {
  border: 1px solid #E5E5E5; }

.team-list-title {
  border-bottom: 1px solid #E5E5E5;
  color: #8095B1;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700; }
  .team-list-title .team-member-status {
    text-transform: uppercase; }
  @media only screen and (max-width: 1023px) {
    .team-list-title {
      font-size: 9px; } }
  .team-list-title .sortable {
    position: relative;
    cursor: pointer; }
    .team-list-title .sortable:before, .team-list-title .sortable:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 105px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; }
      @media only screen and (max-width: 1023px) {
        .team-list-title .sortable:before, .team-list-title .sortable:after {
          left: 70px; } }
    .team-list-title .sortable:before {
      top: calc(56% - 10px);
      border-bottom: 5px solid #8095B1; }
    .team-list-title .sortable:after {
      top: 53%;
      border-top: 5px solid #8095B1; }
    .team-list-title .sortable.active.reverse:after {
      border-top: 5px solid #273342; }
    .team-list-title .sortable.active:not(.reverse):before {
      border-bottom: 5px solid #273342; }

.team-member {
  border-bottom: 1px solid #E5E5E5; }

.team-list-title,
.team-member-data {
  display: flex;
  justify-content: space-around;
  align-items: center; }
  .team-list-title .arrow-placeholder,
  .team-list-title .arrow,
  .team-member-data .arrow-placeholder,
  .team-member-data .arrow {
    width: 15px;
    min-width: 15px; }

.team-member-cell {
  max-width: 72px;
  width: 72px; }

.team-member-icon-cell {
  max-width: 30px;
  width: 30px; }

.team-member-icon {
  background-color: #1d4999;
  border: 1px solid #4472C4;
  color: #FFFFFF;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 17px;
  display: inline-block; }
  .team-member-icon::before {
    content: 'I'; }
  .team-member-icon:hover, .team-member-icon:focus {
    background-color: #498FE1; }
  .team-member-icon.external {
    background-color: #4A8522;
    border-color: #4A8522; }
    .team-member-icon.external::before {
      content: 'E'; }
    .team-member-icon.external:hover, .team-member-icon.external:focus {
      background-color: #5DC54F; }

.team-member-username {
  font-size: 14px;
  font-weight: 400; }

.team-member-email {
  font-size: 14px;
  color: #8095B1;
  font-weight: 400; }

.team-member-status {
  text-transform: lowercase;
  width: 60px;
  max-width: 60px; }

.last-login {
  text-align: center;
  max-width: 140px;
  width: 140px; }

.team-member-name {
  width: 200px;
  max-width: 200px;
  font-weight: 700;
  text-align: left;
  cursor: pointer; }
  @media only screen and (max-width: 1023px) {
    .team-member-name {
      width: 110px;
      max-width: 110px;
      min-width: 110px; } }
  .team-member-name > div {
    overflow: hidden;
    text-overflow: ellipsis; }

.arrow {
  position: relative; }
  .arrow:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    left: 3px;
    top: -4px; }
  .arrow.opened:after {
    border-bottom: 5px solid #273342; }
  .arrow.closed:after {
    border-top: 5px solid #273342; }

.team-member-data {
  cursor: pointer; }
  @media only screen and (max-width: 1023px) {
    .team-member-data {
      font-size: .9rem; } }
  .team-member-data:hover {
    background-color: #E5E5E5; }

.workload-box .button {
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 40px; }

.workload {
  justify-content: center;
  padding: 30px 0; }

.workload-content {
  position: relative;
  min-height: 100px; }

.workload-box {
  margin: 0 60px;
  min-width: 240px; }

.workload-row {
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700; }

.edit-user-column {
  width: 250px; }

.edit-user-select {
  width: 250px !important; }

.administration-care-plan {
  position: relative;
  min-height: 100px;
  padding: 20px 35px 25px;
  text-align: left; }

.administration-care-plan-row {
  display: flex;
  margin-bottom: 20px; }
  @media only screen and (max-width: 1023px) {
    .administration-care-plan-row {
      flex-wrap: wrap; } }

.administration-care-plan-label {
  flex: 0 0 auto;
  width: 31%;
  font-weight: 700; }
  @media only screen and (max-width: 1023px) {
    .administration-care-plan-label {
      width: 100%; } }

.administration-care-plan-row--rules .administration-care-plan-label {
  width: 20%; }

.administration-care-plan-row--rules .administration-care-plan-cn-title {
  width: 360px; }

.administration-care-plan-row--rules .editable-input:not(:only-child) {
  margin-top: 20px; }

.administration-care-plan-row--rules .administration-care-plan-cn-title-tip {
  display: flex;
  align-items: flex-end; }

.administration-care-plan-cn-title {
  flex: 0 0 auto;
  width: 240px; }
  @media only screen and (max-width: 1023px) {
    .administration-care-plan-cn-title {
      width: 100%; } }
  .administration-care-plan-cn-title .editable-input {
    width: 100%; }

.administration-care-plan-cn-title-tip {
  flex: 1 1 auto;
  color: #8095B1;
  margin-left: 7%; }
  @media only screen and (max-width: 1023px) {
    .administration-care-plan-cn-title-tip {
      margin-left: 0; } }

.require-review-control {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 38px; }

.require-review-toggle {
  cursor: pointer;
  display: flex;
  margin: 5px 0px 0px 20px; }
  .require-review-toggle .direct-contact-toggle {
    margin: 0 10px 0 0; }
  .require-review-toggle .active {
    background-color: #19AA64; }

.reassign-select-container {
  margin: 0px 0px 0px 35px; }

.reassign-option {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 5px 10px; }

.eligibility-section-label {
  font-weight: 700;
  width: 100%; }

.selection-count-label {
  font-weight: 700;
  width: 100%;
  margin: 10px 0px; }

.invalid-value {
  color: #FF2400; }

.elibility-section-subtitle {
  width: 100%;
  margin: 10px 0px; }

.eligibility-rule-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 15px 0px 25px 0px; }
  .eligibility-rule-container .radio-group {
    display: flex;
    flex-direction: row;
    width: 100%; }
    @media only screen and (max-width: 1023px) {
      .eligibility-rule-container .radio-group {
        flex-direction: column; } }
  .eligibility-rule-container .radio-option {
    margin-right: 20px; }
    @media only screen and (max-width: 1023px) {
      .eligibility-rule-container .radio-option {
        margin: 10px 0px; } }
  .eligibility-rule-container .custom-patients-age-input {
    margin: 0px 10px 0px 10px;
    width: 40px; }
  .eligibility-rule-container .provider-list {
    flex: 0 0 100%;
    margin: 0px 25px;
    padding: 20px; }
  .eligibility-rule-container .edit-providers-link {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: #498FE1;
    padding: 10px 0px;
    font-size: 12px; }
    .eligibility-rule-container .edit-providers-link:hover, .eligibility-rule-container .edit-providers-link:focus {
      color: #1976D2; }
  .eligibility-rule-container .insurance-input-wrapper {
    margin: 30px 0px 10px 0px;
    width: 500px; }

.eligibility-provider-modal {
  min-width: 900px;
  display: flex;
  flex-direction: row; }
  .eligibility-provider-modal .provider-search-container {
    height: 450px;
    max-height: 500px;
    width: 50%;
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #E5E5E5;
    overflow: hidden;
    display: flex;
    flex-direction: column; }
  .eligibility-provider-modal .provider-selection-container {
    height: 450px;
    max-height: 500px;
    width: 50%;
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #E5E5E5;
    overflow: scroll; }
  .eligibility-provider-modal .provider-search-list {
    height: 100%;
    overflow: scroll;
    padding: 10px; }
  .eligibility-provider-modal .selected-provider {
    display: flex;
    padding: 4px; }
    .eligibility-provider-modal .selected-provider .remove-provider-icon {
      margin: 5px;
      font-size: 12px;
      cursor: pointer; }
      .eligibility-provider-modal .selected-provider .remove-provider-icon:hover, .eligibility-provider-modal .selected-provider .remove-provider-icon:focus {
        color: #1976D2; }

.assign-container {
  justify-content: space-between;
  align-items: center;
  height: 80px; }
  .assign-container .assign-title {
    font-weight: 700; }

.user-account .user-account-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .user-account .user-account-row .column {
    padding: 10px;
    box-sizing: border-box; }
    .user-account .user-account-row .column:first-of-type {
      width: 250px; }
      @media only screen and (max-width: 1023px) {
        .user-account .user-account-row .column:first-of-type {
          width: 37%; } }
    .user-account .user-account-row .column:last-child {
      margin-right: 10px; }

.user-account .container {
  border: 1px solid #E5E5E5; }
  .user-account .container .title {
    border: none;
    border-bottom: 1px solid #E5E5E5; }

.user-account .content {
  border-top: 1px solid #E5E5E5;
  margin: 20px; }

.user-account .button {
  margin: 10px 0 20px 20px; }

/* ------------------------
    Dialogs
------------------------- */
.ReactModal__Overlay.ccm-modal-wrapper.animated-modal {
  opacity: 0;
  transition: opacity 2000ms ease-in-out; }

.ReactModal__Overlay--after-open.ccm-modal-wrapper.animated-modal {
  opacity: 1; }

.ReactModal__Overlay--before-close.ccm-modal-wrapper.animated-modal {
  opacity: 0; }

.ccm-modal-wrapper {
  position: fixed;
  inset: 0px;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5); }
  .ccm-modal-wrapper .ccm-modal {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 0;
    border-color: #DCE3ED;
    overflow: initial;
    border: none; }

.simple-dialog {
  width: 600px; }
  .simple-dialog.small-dialog {
    width: 400px; }
    .simple-dialog.small-dialog.large-height {
      height: 380px; }
    .simple-dialog.small-dialog.extralarge-height {
      height: 450px; }
  .simple-dialog.medium-dialog {
    width: 650px; }
  .simple-dialog.big-dialog {
    min-width: 768px;
    max-width: 900px;
    width: auto; }
  .simple-dialog.xl-dialog {
    min-width: 768px;
    max-width: 1140px;
    width: 1140px; }
  .simple-dialog.xxl-dialog {
    min-width: 768px;
    max-width: 1440px;
    width: 1440px; }

.dialog-title {
  font-size: 1.375rem;
  text-align: left;
  border: none;
  border-bottom: 1px solid #E5E5E5;
  padding: 0.5rem 2rem 0.5rem 1rem;
  position: relative;
  min-height: 37px; }
  @media (max-width: 1200px) {
    .dialog-title {
      font-size: calc(1.2625rem + 0.15vw) ; } }
  .dialog-title .dialog-icon-alert {
    color: #FF2400;
    margin-right: 8px; }
  .dialog-title .dialog-subtitle {
    font-size: 1rem;
    color: #8095B1; }
  .dialog-title .dialog-text-center {
    text-align: center; }
  .dialog-title .close-icon {
    font-size: 1.25rem;
    background: none;
    border: none;
    position: absolute;
    right: 5px;
    top: 50%;
    cursor: pointer;
    color: #8095B1;
    opacity: .8;
    transform: translateY(-50%); }
    .dialog-title .close-icon:hover {
      opacity: 1; }

.ccm-modal-wrapper.animated-modal {
  background-color: rgba(196, 196, 196, 0.3); }

.nextOutreachDate-modal .call-schedule .call-schedule-heading {
  font-size: 1.25rem;
  line-height: 1.2; }

.nextOutreachDate-modal .call-schedule .call-schedule-content {
  max-height: 200px; }
  .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category {
    border: 1px solid #E5E5E5; }
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category:not(:last-child) {
      border-right: 0px; }
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__category-name {
      background-color: #74A3E1;
      color: #FFFFFF; }
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__subcategory-name {
      font-weight: 500; }
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__subcategory-name,
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__patient-name,
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__empty {
      font-size: 0.875rem; }
    .nextOutreachDate-modal .call-schedule .call-schedule-content .call-schedule-category .call-schedule__patient-name.active {
      color: #C6017D; }

.no-border {
  border: none; }

.dialog-align-center {
  display: flex;
  align-items: center; }

.dialog-content {
  padding: 1rem 2rem 0.5rem 2rem; }
  .dialog-content .warning-note {
    font-size: 0.875rem; }
  .dialog-content.dialog-edit-phone {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-top: 35px; }
    .dialog-content.dialog-edit-phone .radio-container {
      margin-left: 0;
      margin-bottom: 15px; }
    .dialog-content.dialog-edit-phone .custom-radio-container {
      order: 1; }
      .dialog-content.dialog-edit-phone .custom-radio-container .editable-input {
        margin-left: 5px; }
        .dialog-content.dialog-edit-phone .custom-radio-container .editable-input .validation-label {
          top: -11px; }

.dialog-list {
  text-align: left; }
  .dialog-list .red {
    color: #FF2400; }

.dialog-buttons {
  display: flex;
  justify-content: center;
  margin: 30px 0; }
  .dialog-buttons .button {
    margin: 0 5px; }
  .dialog-buttons .disabled {
    cursor: not-allowed; }

.error-hint {
  font-size: 14px;
  color: #FF2400;
  border-bottom: 2px solid #FF2400; }

.history-radios {
  font-size: 20px;
  padding-top: 4px; }
  .history-radios label {
    margin-right: 20px; }

.history-dialog-content {
  max-height: 600px;
  overflow-y: auto; }

.dialog-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 15px;
  align-items: center; }
  .dialog-footer .direct-contact-toggle {
    margin: 0 10px 0 0; }
    .dialog-footer .direct-contact-toggle.active {
      background-color: #19AA64; }
  .dialog-footer.buttons-row-no-border {
    justify-content: flex-end;
    padding: 21px 20px 20px;
    margin-top: 10px; }
    .dialog-footer.buttons-row-no-border .button:first-child {
      margin-right: 15px; }

.evidence-dialog-body {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 1rem 2rem; }
  .evidence-dialog-body div:last-child {
    align-items: flex-end; }
  .evidence-dialog-body .evidence-dialog-content {
    min-height: 10rem;
    align-self: flex-start; }
  .evidence-dialog-body .evidence-dialog-footer {
    display: flex;
    flex-direction: column; }

.simple-dialog-body {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem 1rem; }
  .simple-dialog-body .simple-dialog-content {
    padding: 0 1.8rem; }
    .simple-dialog-body .simple-dialog-content .radio-container {
      margin-left: 0; }
      .simple-dialog-body .simple-dialog-content .radio-container:nth-child(2) .option-text {
        margin-bottom: 0; }
      .simple-dialog-body .simple-dialog-content .radio-container .option-text {
        text-align: justify;
        margin-left: 1rem; }

.screening-select .Select-menu {
  max-height: 100px; }

.audit-entry-field {
  min-height: 115px; }
  .audit-entry-field input {
    min-height: 35px; }
  .audit-entry-field .Select-input input {
    min-height: initial; }
  .audit-entry-field .has-value {
    border-bottom-color: #00AB84; }
  .audit-entry-field .is-invalid {
    border-bottom-color: #FF2400; }
  .audit-entry-field .is-valid, .audit-entry-field .is-invalid {
    background-image: none;
    padding-right: initial; }
  .audit-entry-field .form-group {
    margin-bottom: 0; }
  .audit-entry-field .Select-menu {
    max-height: 125px; }
  .audit-entry-field .Select-menu-outer {
    min-width: 160px; }
  .audit-entry-field textarea {
    min-height: 38px;
    resize: vertical; }
  .audit-entry-field .invalid-feedback::before {
    padding-right: 5px; }

.audit-entries {
  max-height: 905px;
  overflow-y: auto; }
  @media (max-height: 1215px) {
    .audit-entries {
      max-height: calc(-320px + 100vh); } }
  .audit-entries .dialog-content:not(:has(div.border-top)) {
    padding-top: 0; }

.remove-activity-button {
  margin-top: 1.3rem !important; }

.audit-info-banner {
  background-color: #DCE8F5;
  border: 1px solid #74A3E1;
  margin-bottom: 10px; }

.duration-warning-border {
  border-color: #C67000 !important; }

.duration-warning {
  color: #C67000;
  display: block; }

.terms-of-agreements {
  color: #273342;
  text-align: justify;
  padding-right: 5px; }
  .terms-of-agreements .title-section {
    align-items: center; }
    .terms-of-agreements .title-section .title {
      font-weight: 700;
      font-size: 20px; }
    .terms-of-agreements .title-section .subtitle {
      font-style: italic; }
  .terms-of-agreements p {
    margin: 0 0 10px; }
  .terms-of-agreements address {
    font-style: normal;
    margin-left: 40px;
    margin-bottom: 10px; }
  .terms-of-agreements u {
    margin-right: 7px; }
  .terms-of-agreements h3 {
    margin: 20px 0 10px 0;
    font-size: 16px; }
    .terms-of-agreements h3:first-child {
      margin-top: 0; }
  .terms-of-agreements ol {
    padding-left: 35px; }
    .terms-of-agreements ol li {
      margin: 10px 0;
      padding-left: 5px; }

.licence-agreement p {
  text-indent: 40px; }

.terms-alpha-list {
  list-style-type: lower-alpha; }

.terms-roman-list {
  list-style-type: lower-roman; }

.terms-link {
  margin: 0 7px;
  color: #498FE1;
  text-decoration: none; }
  .terms-link.policy {
    margin-right: 0; }
  .terms-link:hover, .terms-link:focus, .terms-link:active {
    color: #1976D2; }

.terms-contact-table {
  margin-bottom: 12px; }
  .terms-contact-table a {
    color: #8095B1; }
    .terms-contact-table a:hover, .terms-contact-table a:focus, .terms-contact-table a:active {
      color: #1D2633;
      text-decoration: none; }
  .terms-contact-table .field {
    padding-right: 10px;
    vertical-align: top; }

.status-icon {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 17px;
  display: inline-block;
  margin-right: 4px;
  text-align: center;
  flex: 0 0 auto; }
  .status-icon.green {
    color: #FFFFFF;
    background-color: #14CE45; }
  .status-icon.yellow {
    color: #000000;
    background-color: #E1CD00; }
  .status-icon.red {
    color: #FFFFFF;
    background-color: #FF5000; }
  .status-icon.blue {
    color: #FFFFFF;
    background-color: #1976D2; }
  .status-icon.navy {
    color: #FFFFFF;
    background-color: #2980B9; }
  .status-icon.orange {
    color: #FFFFFF;
    background-color: #E67E22; }
  .status-icon.oslo {
    color: #FFFFFF;
    background-color: #8D89A5; }
  .status-icon.bismark {
    color: #FFFFFF;
    background-color: #4A6784; }

.status-icon-for-logo {
  width: 33px;
  height: 33px;
  line-height: 34px;
  position: absolute;
  bottom: -8px;
  right: -8px;
  font-size: 16px;
  margin-right: 0; }

.status-icon-for-search {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  margin-right: 3px; }

.billing-section .card-header .Select.Select--single {
  border-bottom: none; }
  .billing-section .card-header .Select.Select--single > .Select-control .Select-value {
    background-color: #F2F2F2;
    font-weight: bold; }

.billing-section .card-body tbody td.billing__source {
  width: 10%; }

.billing-section .card-body tbody td.billing__problem {
  width: 50%; }

.billing-section .card-body tbody td.billing__ccm {
  width: 13%; }

.billing-section .card-body tbody td.billing__actions {
  width: 10%; }

.billing-section .card-body tbody td.billing__ehr-problem {
  width: 35%;
  max-width: 35%; }

.billing-section .card-body tbody td.billing__ehr-code {
  width: 100px;
  max-width: 100px; }

.billing-section .card-body tbody td.billing__ehr-ccm {
  width: 100px;
  max-width: 100px; }

.billing-section .card-body tbody td.billing__ehr-actions {
  width: 50px;
  max-width: 50px; }

.billing-section .new-problem td {
  border-bottom: 1px solid #19AA64;
  border-top: 1px solid #19AA64;
  vertical-align: middle; }
  .billing-section .new-problem td:first-child {
    border-left: 1px solid #19AA64; }
  .billing-section .new-problem td:last-child {
    border-right: 1px solid #19AA64; }

.billing-section .edited-problem td {
  border-bottom: 1px solid #E67E22;
  border-top: 1px solid #E67E22;
  vertical-align: middle; }
  .billing-section .edited-problem td:first-child {
    border-left: 1px solid #E67E22; }
  .billing-section .edited-problem td:last-child {
    border-right: 1px solid #E67E22; }

.settings .container .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 26px;
  flex-wrap: wrap; }
  .settings .container .title:after {
    visibility: hidden;
    content: '';
    display: none; }

.billable-providers-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  table-layout: fixed;
  overflow-wrap: break-word; }
  .billable-providers-table td {
    padding: 15px 10px;
    width: 19%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    @media only screen and (max-width: 1023px) {
      .billable-providers-table td {
        font-size: 13px; } }
  .billable-providers-table .physician-billable {
    cursor: pointer; }
  .billable-providers-table .physician-active {
    cursor: pointer; }
  .billable-providers-table .physician-input:hover {
    overflow: visible;
    white-space: normal;
    width: auto; }
  @media only screen and (max-width: 1023px) {
    .billable-providers-table .physician-input {
      width: 75px; } }
  .billable-providers-table .physician-input .editable-input {
    width: 100%; }
  .billable-providers-table .physician-input .validation-label {
    top: -12px; }
  .billable-providers-table .physician-input .text {
    text-align: center; }
  .billable-providers-table .sortable {
    position: relative;
    cursor: pointer; }
    .billable-providers-table .sortable:before, .billable-providers-table .sortable:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 105px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; }
      @media only screen and (max-width: 1023px) {
        .billable-providers-table .sortable:before, .billable-providers-table .sortable:after {
          left: 70px; } }
    .billable-providers-table .sortable:before {
      top: calc(56% - 10px);
      border-bottom: 5px solid #8095B1; }
    .billable-providers-table .sortable:after {
      top: 53%;
      border-top: 5px solid #8095B1; }
    .billable-providers-table .sortable.active.reverse:after {
      border-top: 5px solid #273342; }
    .billable-providers-table .sortable.active:not(.reverse):before {
      border-bottom: 5px solid #273342; }

.billable-providers-table-title {
  border-bottom: 1px solid #E5E5E5;
  color: #8095B1;
  text-transform: uppercase;
  font-weight: 700; }
  @media only screen and (max-width: 1023px) {
    .billable-providers-table-title td {
      font-size: 9px; } }

.billable-providers-table-row {
  border-bottom: 1px solid #E5E5E5; }
  .billable-providers-table-row .physician-name:hover {
    overflow: visible;
    white-space: normal;
    width: auto; }

.billable-providers-table-title .physician-name, .billable-providers-table-row .physician-name {
  text-align: left; }
  .billable-providers-table-title .physician-name:first-child, .billable-providers-table-row .physician-name:first-child {
    padding-left: 25px; }
    .billable-providers-table-title .physician-name:first-child:before, .billable-providers-table-title .physician-name:first-child:after, .billable-providers-table-row .physician-name:first-child:before, .billable-providers-table-row .physician-name:first-child:after {
      left: 125px; }
      @media only screen and (max-width: 1023px) {
        .billable-providers-table-title .physician-name:first-child:before, .billable-providers-table-title .physician-name:first-child:after, .billable-providers-table-row .physician-name:first-child:before, .billable-providers-table-row .physician-name:first-child:after {
          left: 90px; } }

.container .content > .billing-providers-controls {
  padding: 15px 30px;
  display: flex;
  align-items: center; }

.billing-providers-radios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  font-weight: 400; }
  .billing-providers-radios > span {
    margin-right: 20px; }

.active-providers-radios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  font-weight: 400; }
  .active-providers-radios > span {
    margin-right: 20px; }

.select-billable-provider {
  margin-left: auto; }

.search-field-container {
  width: 100%;
  text-align: right;
  margin-top: 0.3rem; }
  .search-field-container .search-icon {
    display: inline-block;
    color: #8095B1;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px; }
  .search-field-container input {
    width: 30%;
    box-sizing: border-box;
    padding: 2px 3px;
    border-width: 1px;
    border-style: solid;
    border-color: #000000; }
    .search-field-container input.applied {
      background-color: #DDDDDD; }
    .search-field-container input.invalid {
      border-bottom-color: #FF2400; }

.intervention-priorities {
  position: relative;
  text-align: center;
  width: auto;
  font-size: 14px;
  height: 42px; }

.intervention-priority-dropdown {
  position: absolute;
  z-index: 1; }
  .intervention-priority-dropdown .intervention-priority {
    border-radius: 0; }
    .intervention-priority-dropdown .intervention-priority:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px; }
    .intervention-priority-dropdown .intervention-priority:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px; }

.intervention-priority {
  border-radius: 6px;
  width: auto;
  padding: 10px;
  cursor: pointer; }
  .intervention-priority:hover {
    color: #498FE1; }
  .intervention-priority.normal {
    background-color: #19AA64;
    color: #000000; }
  .intervention-priority.info {
    background-color: #E1CD00;
    color: #000000; }
  .intervention-priority.urgent {
    background-color: #F5A623;
    color: #000000; }
  .intervention-priority.emergent {
    background-color: #FF2400;
    color: #000000; }

.resolved-date-selection-container {
  display: flex;
  justify-content: center; }
  .resolved-date-selection-container .icon {
    font-size: 24px; }
  .resolved-date-selection-container .one-field-date-picker {
    width: 150px;
    text-align: left;
    font-weight: normal; }

.add-intervention-form {
  display: flex;
  flex-direction: column; }
  .add-intervention-form .add-intervention-group {
    display: flex;
    flex-direction: row;
    margin: 10px;
    width: 100%; }
    .add-intervention-form .add-intervention-group .Select, .add-intervention-form .add-intervention-group .editable-input {
      width: 200px; }
  .add-intervention-form label {
    width: 130px;
    margin: 10px; }
  .add-intervention-form .add-intervention-note {
    width: 100%;
    height: 36px; }
  .add-intervention-form .add-intervention-column {
    display: flex;
    flex-direction: column; }
    .add-intervention-form .add-intervention-column.left {
      width: 50%;
      padding-right: 20px; }
    .add-intervention-form .add-intervention-column.right {
      border-left: 1px solid #DCE3ED;
      margin-left: 20px;
      padding-left: 20px; }
  .add-intervention-form .add-intervention-row {
    display: flex;
    flex-direction: row;
    width: 100%; }

.resolution-toggle {
  cursor: pointer;
  display: flex;
  margin-top: 10px; }
  .resolution-toggle .direct-contact-toggle {
    margin: 0 10px 0 0; }
  .resolution-toggle .active {
    background-color: #498FE1; }

.intervention-modal .label-space {
  margin-bottom: 13px; }

.intervention-modal .dropdown-items-text {
  font-size: 0.75rem;
  text-transform: lowercase; }

.intervention-modal .dropdown-items-text:first-letter {
  text-transform: uppercase; }

.intervention-modal .dropdown-items {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0; }

/* ------------------------
    QA Notes
------------------------- */
.qa-note-radios .radio-container {
  display: block;
  margin: 10px 0; }
  .qa-note-radios .radio-container + .radio-container {
    margin-left: 0; }

.qa-note-info.qa-resubmitted_review {
  color: #00AB84; }

.qa-note-info.qa-change_requested {
  color: #E74C3C; }

.qa-note-info.qa-accepted_with_feedback {
  color: #BBAA03; }

.bulk-change-status {
  display: flex;
  flex-direction: column; }
  .bulk-change-status .change-status-form-group {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0.5rem auto;
    width: 80%; }
    .bulk-change-status .change-status-form-group .change-status-select {
      font-size: 14px; }
      .bulk-change-status .change-status-form-group .change-status-select .Select-placeholder {
        font-size: 14px !important; }
      .bulk-change-status .change-status-form-group .change-status-select .Select-value-label {
        font-size: 14px; }
  .bulk-change-status .change-status-text-container .change-status-text-area {
    width: 97%;
    min-height: 8rem;
    margin: 0.5rem 0rem; }
  .bulk-change-status .change-status-footer {
    display: flex;
    align-self: flex-end;
    margin-bottom: 1rem; }
    .bulk-change-status .change-status-footer button {
      margin-left: 1rem; }

/* ------------------------
    Patient History
------------------------- */
.patient-history .history-qaNotes tbody tr.qa-edit-note {
  cursor: pointer; }
  .patient-history .history-qaNotes tbody tr.qa-edit-note:hover {
    color: #498FE1; }

.patient-history .history-qaNotes tbody td.qa-accepted {
  color: #00AB84; }

.patient-history .history-qaNotes tbody td.qa-change_requested {
  color: #E74C3C; }

.patient-history .history-qaNotes tbody td.qa__status {
  font-weight: 500; }

.patient-history .history-qaNotes tbody td.qa__comments {
  width: 40%; }
  .patient-history .history-qaNotes tbody td.qa__comments p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical; }
    .patient-history .history-qaNotes tbody td.qa__comments p:hover {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 20;
      line-clamp: 20;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical; }

.patient-history .history-immunizations .card-body table thead tr .immunization__name,
.patient-history .history-immunizations .card-body table thead tr .immunization__manufacturer,
.patient-history .history-immunizations .card-body table tbody tr .immunization__name,
.patient-history .history-immunizations .card-body table tbody tr .immunization__manufacturer {
  width: 20%;
  max-width: 20%; }

.patient-history .history-immunizations .card-body table thead tr .immunization__date,
.patient-history .history-immunizations .card-body table thead tr .immunization__status,
.patient-history .history-immunizations .card-body table thead tr .immunization__source,
.patient-history .history-immunizations .card-body table tbody tr .immunization__date,
.patient-history .history-immunizations .card-body table tbody tr .immunization__status,
.patient-history .history-immunizations .card-body table tbody tr .immunization__source {
  width: 100px;
  max-width: 100px; }

.patient-history .history-immunizations .card-body table thead tr .immunization__action,
.patient-history .history-immunizations .card-body table tbody tr .immunization__action {
  width: 40px;
  max-width: 40px; }

.patient-history .history-immunizations .card-body table tbody tr td .immunization__status {
  font-weight: 500; }

.patient-history .history-medical-events .card-body table thead tr .medical-events__type,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__type {
  width: 180px;
  max-width: 180px; }

.patient-history .history-medical-events .card-body table thead tr .medical-events__reason,
.patient-history .history-medical-events .card-body table thead tr .medical-events__location,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__reason,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__location {
  width: 15%;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .patient-history .history-medical-events .card-body table thead tr .medical-events__reason:hover,
  .patient-history .history-medical-events .card-body table thead tr .medical-events__location:hover,
  .patient-history .history-medical-events .card-body table tbody tr .medical-events__reason:hover,
  .patient-history .history-medical-events .card-body table tbody tr .medical-events__location:hover {
    overflow: visible;
    white-space: normal;
    width: 15%;
    max-width: 150px; }

.patient-history .history-medical-events .card-body table thead tr .medical-events__date,
.patient-history .history-medical-events .card-body table thead tr .medical-events__source,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__date,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__source {
  width: 105px;
  max-width: 105px; }

.patient-history .history-medical-events .card-body table thead tr .medical-events__action,
.patient-history .history-medical-events .card-body table tbody tr .medical-events__action {
  width: 70px;
  max-width: 70px; }

.patient-history .history-medical-events .card-body table tbody tr td .immunization__status {
  font-weight: 500; }

/* ------------------------
    Reports
------------------------- */
.ccm-reports-main {
  position: relative;
  flex: 1;
  overflow: hidden;
  color: #2C3E50; }
  .ccm-reports-main .ccm-reports-panel-menu {
    width: 300px;
    min-width: 300px; }
    .ccm-reports-main .ccm-reports-panel-menu .ccm-reports-menu-wrapper {
      position: relative; }
  .ccm-reports-main .reports-form-group {
    width: 300px; }
    .ccm-reports-main .reports-form-group .form-label {
      flex: 1 0 10%; }
    .ccm-reports-main .reports-form-group .react-datepicker-wrapper, .ccm-reports-main .reports-form-group .Select {
      width: 50%; }

.ccm-table-container {
  overflow-y: auto;
  overflow-x: hidden; }
  .ccm-table-container table {
    border-collapse: separate;
    border-spacing: 0 0.5rem; }
    .ccm-table-container table thead {
      background-color: #FFFFFF; }
      .ccm-table-container table thead th {
        font-size: 0.875rem;
        line-height: 1.2;
        vertical-align: top;
        white-space: nowrap;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
        border: none; }
    .ccm-table-container table tbody {
      font-size: 0.875rem;
      font-weight: 400; }
      .ccm-table-container table tbody p {
        margin-bottom: 0; }
      .ccm-table-container table tbody td {
        border-bottom: 1px solid #E5E5E5;
        border-top: 1px solid #E5E5E5;
        vertical-align: middle; }
        .ccm-table-container table tbody td:first-child {
          border-left: 1px solid #E5E5E5; }
        .ccm-table-container table tbody td:last-child {
          border-right: 1px solid #E5E5E5; }
        .ccm-table-container table tbody td .accordion .card-header table {
          border-collapse: collapse; }
          .ccm-table-container table tbody td .accordion .card-header table td {
            border: none; }
        .ccm-table-container table tbody td .accordion .card-body {
          padding: 0.75rem; }
    .ccm-table-container table.table-hover tbody tr:not(.no-editable) td {
      cursor: pointer; }
    .ccm-table-container table.table-hover tbody tr:not(.no-editable):hover {
      color: #498FE1; }

.ccm-table-pager .btn-group .btn {
  transition: all 0.5s ease; }

/* ------------------------
    Flight Plan
------------------------- */
.ccm-flight-plan-main {
  position: relative;
  flex: 1;
  overflow: hidden;
  color: #2C3E50; }
  .ccm-flight-plan-main .ccm-flight-plan-panel-menu {
    width: 300px;
    min-width: 300px; }
    .ccm-flight-plan-main .ccm-flight-plan-panel-menu .ccm-flight-plan-menu-wrapper {
      position: relative; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .select-filter {
    min-width: 100px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .toggle, .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .item {
    font-size: 0.75rem; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .toggle:hover, .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .toggle:active {
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .menu-filter {
    min-width: 100px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .menu-status {
    min-width: 50px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .filter .input {
    width: 75px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info.patientInfo-closed .avatar-icon {
    font-size: 0.75rem;
    width: 24px;
    height: 24px;
    line-height: 24px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info.patientInfo-closed .avatar-badge {
    font-size: 0.5rem;
    right: -0.5rem;
    width: 14px;
    height: 14px; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .patient-info.patientInfo-closed .patient__name {
    font-size: 1.125rem;
    line-height: 1.4; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-reminders .reminders-content .reminders-info .reminders__assignedCn {
    color: #929292;
    font-weight: 300;
    line-height: 1.6; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-reminders .reminders-content .reminders-info .reminders__dateTime {
    font-size: 0.875rem;
    white-space: nowrap; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-recent-work .recent-work-content .recent-work-info .recentWork__workDate,
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-recent-work .recent-work-content .recent-work-info .recentWork__activityDate {
    color: #929292;
    font-weight: 300;
    line-height: 1.6; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-alerts .alert-content .alert-list-info .alert__alertDate {
    color: #929292;
    font-weight: 300;
    line-height: 1.6; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule nav a.nav-item.nav-link {
    color: #8095B1; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule nav a.nav-item.nav-link.active {
    border-width: 0 0 2px 0;
    border-bottom: solid #74A3E1;
    color: #2C3E50; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule nav .nav-link:hover, .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule nav .nav-link:focus {
    border-color: transparent; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .title h1 {
    font-weight: 500; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .bi {
    font-size: 1.125rem;
    font-weight: 500; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .table .pa__name {
    width: 19%; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .table .pa__sent {
    width: 15%; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .table .pa__response {
    width: 44%; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .table .pa__column {
    width: 10%; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .table .pa__actions {
    width: 12%; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .schedule-content-header .bi {
    font-size: 1.5rem;
    font-weight: 500; }
    @media (max-width: 1200px) {
      .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .schedule-content-header .bi {
        font-size: calc(1.275rem + 0.3vw) ; } }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .call-schedule__category-name {
    background-color: #74A3E1;
    color: #FFFFFF; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .call-schedule__subcategory-name,
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .call-schedule__patient-name,
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-call-schedule .call-schedule-content .call-schedule__empty {
    font-size: 1rem; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .filter {
    font-size: 0.8125rem; }
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .filter .item {
      font-size: 0.75rem; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .medium-header-text {
    font-size: 0.8125rem; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .title-filter {
    font-size: 0.75rem; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select {
    border-bottom: none; }
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select .Select-control .Select-value .Select-value-label,
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select .Select-control .Select-clear-zone .Select-clear,
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select .Select-control .Select-multi-value-wrapper .Select-input input {
      color: #74A3E1; }
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select .Select-control .Select-arrow-zone .Select-arrow {
      border-color: #74A3E1 transparent transparent; }
    .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select .Select-menu-outer .Select-menu .Select-option {
      color: #74A3E1; }
  .ccm-flight-plan-main .ccm-flight-plan-main-content .flight-plan-status-change .assigned-filter .Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent #74A3E1; }

.flight-plan-metrics nav a.nav-item.nav-link {
  color: #8095B1; }

.flight-plan-metrics nav a.nav-item.nav-link.active {
  border-width: 0 0 2px 0;
  border-bottom: solid #74A3E1;
  color: #2C3E50; }

.flight-plan-metrics nav .nav-link:hover, .flight-plan-metrics nav .nav-link:focus {
  border-color: transparent; }

.flight-plan-metrics .title h1 {
  font-weight: 500; }

.flight-plan-metrics .card {
  border-radius: 10px; }
  .flight-plan-metrics .card .card-body {
    font-size: 1.125rem; }
    .flight-plan-metrics .card .card-body .card-chart {
      height: 230px;
      transform: rotate(90deg);
      background-color: #E5E5E5;
      clip-path: circle(); }
    .flight-plan-metrics .card .card-body .percentage-text {
      font-size: 2.125rem; }
      @media (max-width: 1200px) {
        .flight-plan-metrics .card .card-body .percentage-text {
          font-size: calc(1.3375rem + 1.05vw) ; } }
    .flight-plan-metrics .card .card-body .chart-text {
      font-size: 1.5rem; }
      @media (max-width: 1200px) {
        .flight-plan-metrics .card .card-body .chart-text {
          font-size: calc(1.275rem + 0.3vw) ; } }
  .flight-plan-metrics .card .card-footer {
    background-color: transparent;
    border-top: 1.5px solid #C4C4C4; }

/* ------------------------
    Simple PES View
------------------------- */
.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment nav a.nav-item.nav-link, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history nav a.nav-item.nav-link {
  color: #8095B1; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment nav a.nav-item.nav-link.active, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history nav a.nav-item.nav-link.active {
  border-width: 0 0 2px 0;
  border-bottom: solid #74A3E1;
  color: #2C3E50; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment nav .nav-link:hover, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment nav .nav-link:focus, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history nav .nav-link:hover, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history nav .nav-link:focus {
  border-color: transparent; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment .enroll-options .title-content span, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history .enroll-options .title-content span {
  font-size: 1.125rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .enrollment .enroll-options .detail-content p, .ccm-patient-view-main .ccm-patient-view-main-content .sub-content .patient-history .enroll-options .detail-content p {
  font-size: 0.875rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .error-text {
  font-size: 0.8rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .pesView-outreachDate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .timeTraking-textarea ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px; }

.ccm-patient-view-main .ccm-patient-view-main-content .sub-content .timeTraking-textarea ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.ccm-patient-view-main .ccm-patient-view-main-content .separator {
  border-left: 2px solid #E5E5E5; }

.ccm-patient-view-main .ccm-patient-view-main-content .section-reference-information .title {
  font-size: 1.4375rem; }
  @media (max-width: 1200px) {
    .ccm-patient-view-main .ccm-patient-view-main-content .section-reference-information .title {
      font-size: calc(1.26875rem + 0.225vw) ; } }

.ccm-patient-view-main .ccm-patient-view-main-content .section-reference-information .sub-title {
  font-size: 1.1875rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .section-reference-information .text-content {
  font-size: 0.9375rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .section-reference-information .text-last-emr-update {
  font-size: 0.875rem; }

.ccm-patient-view-main .ccm-patient-view-main-content .form-check input, .ccm-patient-view-main .ccm-patient-view-main-content .form-check label {
  cursor: pointer; }

.ccm-patient-view-main .ccm-patient-view-main-content .reminders-list input[type=checkbox]:checked +
.reminder__content .form-check-label, .ccm-patient-view-main .ccm-patient-view-main-content .reminders-list input[type=checkbox]:checked +
.reminder__content span {
  text-decoration: line-through; }

.ccm-patient-view-main .ccm-patient-view-main-content .reminders-list input[type=checkbox]:checked +
.reminder__content span {
  color: #929292; }

.ccm-patient-view-main .ccm-patient-view-main-content .note-body {
  text-align: left; }

.patient-prompts .prompts-options .title span {
  font-size: 1.25rem; }

.patient-prompts .prompts-options .content span {
  font-size: 1.0625rem; }

.patient-prompts .prompts-options .content p {
  font-size: 0.9375rem; }

.patient-prompts .prompts-options .form-check input, .patient-prompts .prompts-options .form-check label {
  cursor: pointer; }

.patient-health-factors .health-factor p, .patient-health-factors .health-factor li {
  line-height: 1.2; }

.patient-health-factors .health-factor .resources-text li {
  cursor: pointer; }

.health-factor-modal .form-check input, .health-factor-modal .form-check label {
  cursor: pointer; }

.resource-modal .resource-url {
  cursor: pointer; }

/* ------------------------
    Banner
------------------------- */
.ccm-banner-container {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center; }
  .ccm-banner-container + .d-flex {
    padding-top: 50px; }

/* ------------------------
    Snook
------------------------- */
.ccm-snook-list {
  background-image: url("./images/amelia-splash.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .ccm-snook-list .wrapper-list {
    background-color: #FFFFFF;
    border-radius: 30px; }
